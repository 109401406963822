import OficialiaAdscripcion from "../../pages/materias/oficialias-adscripcion";
import Oficialia from "../../pages/materias/oficialias"
import ModalidadesJuicios from "../../pages/materias/modalidad-juicios";


const rutasMaterias = [
    { route: "/oficialias", component: Oficialia },
    { route: "/oficialia-adscripcion", component: OficialiaAdscripcion },
    { route: "/modalidad-juicio", component: ModalidadesJuicios },

]

export default rutasMaterias