import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "@mantine/core";
import peticionBack from "../../libs/peticiones";
import { Card, Group } from "@mantine/core";
import { useDispatch } from "react-redux";
import {
  loginFailure,
  loginSuccess,
} from "../../core/slicers/auth/LoginSlicer";
import { loadProfiles } from "../../core/slicers/perfil/profileSlicer";
import InputTextMan from "../../components/inputs/inputTextMan";
import { InputPassword } from "../../components/inputs/inputPassword";

const defaultForm: { username: string; password: string } = {
  username: "",
  password: "",
};

const formSchema = Yup.object().shape({
  username: Yup.string().required("Ingrese un nombre de usuario"),
  password: Yup.string().required("Ingrese una contraseña"),
});

export default function Login() {
  const dispatch = useDispatch();

  const { handleSubmit, handleBlur, setFieldValue, values, errors, touched } =
    useFormik({
      initialValues: defaultForm,
      validationSchema: formSchema,
      onSubmit: async (values) => await Login(values),
      onReset: () => {},
    });

  async function Login(credentials: { username: string; password: string }) {
    const res = await peticionBack(credentials, "/auth/login", "POST", false);
    if (res) {
      //LOGIN SUCCESS
      dispatch(
        loginSuccess({
          fullName: res.name,
          token: res.token,
          username: res.username,
          email: res.email,
        })
      );
      dispatch(loadProfiles({ profiles: res.perfiles }));
    } else {
      //LOGIN ERROR
      dispatch(loginFailure());
    }
  }

  function onChange({ target }: { target: any }) {
    setFieldValue(target.name, target.value);
  }

  return (
    <Card shadow="sm" padding="md" radius="md" withBorder>
      <div className="container text-center mb-4 mt-4">
        <h3>Iniciar Sesión</h3>
      </div>

      <Group align="center" justify="space-around" mt="md" mb="xs">
        <form onSubmit={handleSubmit}>
          <InputTextMan
            label="Usuario"
            name="username"
            type="text"
            value={values.username}
            touched={touched.username}
            error={errors.username}
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            required
            rightSection
          />
          <InputPassword
            label="Contraseña"
            name="password"
            value={values.password}
            touched={touched.password}
            error={errors.password}
            onChangeMethod={({ target }: any) =>
              setFieldValue("password", target.value)
            }
            onBlurMethod={handleBlur}
            required
          />
          <div className="mt-4 container text-center mb-2">
            <Button color="redColor" variant="filled" type="submit">
              Ingresar
            </Button>
          </div>
        </form>
      </Group>
    </Card>
  );
}
