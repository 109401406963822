import { configureStore } from "@reduxjs/toolkit";
import LoginSlicer from "../../core/slicers/auth/LoginSlicer";
import profileSlicer from "../../core/slicers/perfil/profileSlicer";
import storage from 'redux-persist/lib/storage'
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import moduleSlicer from "../../core/slicers/permisos/permissionsSlicer";
import loadingSlicer from "../../core/slicers/loading/LoadingSlicer";


const persistConfig = {
  key: 'auth',
  storage
}

const persistedAuthReducer = persistReducer(persistConfig, LoginSlicer)

export const store = configureStore({
  reducer: {
    login: persistedAuthReducer,
    profile: profileSlicer,
    modules: moduleSlicer,
    loading: loadingSlicer
  },
  middleware: (getDefaultMiddleware : any) => getDefaultMiddleware({serializableCheck: false}),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const persistor = persistStore(store)