import { StyleSheet } from "@react-pdf/renderer";

export const stylePDF = StyleSheet.create({
    page: { paddingTop: "30px", paddingBottom: "40px", paddingHorizontal: "20px", fontSize: "10px" },

    title: { fontSize: "13px", textAlign: "center", fontFamily: "Helvetica-Bold", marginBottom: "6px" },
    bTitle: { borderBottomColor: "gray", borderBottomWidth: "2px", marginBottom: "5px" },

    footer: { position: "absolute", bottom: "20px", width: "100%", marginHorizontal: "20px"},
    bfooter: { borderBottomColor: "AliceBlue", borderTopWidth: "1px", marginTop: "5px", paddingTop: "3px" },

    tHeader: { backgroundColor: "#730A29", textAlign: "center", color: "#FFFFFF" },

    pCell: { paddingVertical: "1px", paddingHorizontal: "2px" },
    pxCell: { paddingHorizontal: "1px" },
    pyCell: { paddingVertical: "1px" },

    /*Centrar verticalmente */
    vCenter: { alignItems: "center", justifyContent: "center" },
    vCenterL: { alignItems: "flex-start", justifyContent: "center" },
    vCenterR: { alignItems: "flex-end", justifyContent: "center" },
    vCenterBtw: { alignItems: "stretch", justifyContent: "center" },

    dflexBtw: { display: "flex", flexDirection: "row", justifyContent: "space-between" },
    dflexEnd: { display: "flex", flexDirection: "row", justifyContent: "flex-end" },
    dflexCenter: { display: "flex", flexDirection: "row", justifyContent: "center" },

    alignEnd: { alignContent: "flex-end" },

    row: { flexDirection: "row" },
    flexWrap: { display: "flex", flexWrap: "wrap", flexDirection: "row" },

    w5: { width: "5%" },
    w10: { width: "10%" },
    w15: { width: "15%" },
    w20: { width: "20%" },
    w25: { width: "25%" },
    w30: { width: "30%" },
    w35: { width: "35%" },
    w40: { width: "40%" },
    w45: { width: "45%" },
    w50: { width: "50%" },
    w55: { width: "55%" },
    w60: { width: "60%" },
    w65: { width: "65%" },
    w70: { width: "70%" },
    w75: { width: "75%" },
    w80: { width: "80%" },
    w90: { width: "90%" },
    w100: { width: "100%" },
    wAuto: { width: "auto" },

    border: { border: "1px solid #000" },
    bTop: { borderTop: "1px solid #000" },
    bBottom: { borderBottom: "1px solid #000" },
    bStart: { borderLeft: "1px solid #000" },
    bEnd: { borderRight: "1px solid #000" },
    bDown: {border: "1px solid #000", borderTop: "0px"},
    bUp: { border: "1px solid #000", borderBottom: "0px" },

    fs7: { fontSize: "7px" },
    fs8: { fontSize: "8px" },
    fs9: { fontSize: "9px" },
    fs10: { fontSize: "10px" },
    fs11: { fontSize: "11px" },
    fs12: { fontSize: "12px" },
    fs13: { fontSize: "13px" },

    tEnd: { textAlign: "right", paddingRight: "2px" },
    tStart: { textAlign: "left" },
    tCenter: { textAlign: "center" },
    tBold: { fontFamily: "Helvetica-Bold" },


    imageSm: {height: "auto", width:"25px"},
    imageMd: {height: "auto", width:"35px"},
    imageLg: {height: "auto", width:"55px"}

});