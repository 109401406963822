import { permissionState } from "../core/slicers/permisos/interfaces/permisosInterface";

export function checkPermission(
  modules: permissionState,
  key: "c" | "r" | "u" | "d"
) {
  if (!modules.modules) {
    return false;
  }
  const find = modules.modules.find((modulo) => modulo._id === modules.actual);
  if (!find) {
    return false;
  }
  return find[key];
}
