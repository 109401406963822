import { faFile, faFileImage, faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function FileTypeIcon({ name }: { name: string }){
    const split = name.split(".");
    const type = split.at(-1);
    function get(): [any, string]{
        switch(type){
            case "pdf": return [faFilePdf, "var(--mantine-color-red-filled)"];
            case "png":
            case "jpeg":
            case "gif":
            case "jpg": return [faFileImage, "var(--mantine-color-blue-filled)"];
            default: return [faFile, "text-info"];
        }
    }

    const [icon, color] = get();
    
    return(
        <FontAwesomeIcon icon={icon} color={color} className="me-1 p-1 fs-5" />
    )
}