/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import InputSearch from "../../../components/inputs/InputSelectSearch";
import { swalAction } from "../../../components/modals/ModalConfirm";
import peticionBack from "../../../libs/peticiones";
import { Button, Collapse, Divider, Grid } from "@mantine/core";
import { JuiciosDTO } from "./dto/juicios";
import UnoAMuchos from "../../../components/Forms/Uno-a-muchos";
import InputTextMan from "../../../components/inputs/inputTextMan";

const defaultForm: JuiciosDTO = {
  name: "",
  modalidad_id: "",
};

const formSchema = Yup.object().shape({
  name: Yup.string().required("Ingrese un nombre"),
  modalidad_id: Yup.string().required("Seleccione una modalidad"),
});

export default function Form({
  setOpen,
  selected,
  modalidadJuicios,
  materias
}: {
  setOpen: any;
  selected?: any;
  modalidadJuicios:any;
  materias:any;
}) {
  const [formRendered, setFormRendered] = useState<any>(false);
  const [openAd, setOpenAd] = useState<boolean>(false);
  const [closeForm, setCloseForm] = useState<boolean>(true);
  const [id, setId] = useState<any>();

  const endpointValues ={
    ruta:"/juicios-materias",
    filtro:"juicio_id",
    populate:"materia_id",
    rows:"_id,materia_id,juicio_id", //complementar
    mapAditivos_id:"materia_juicios", // cambia si esta populado
    dataTabla:"materia.name" //hay que popular la adscripcion por el emomento se muestra su id dela adscripcion
  }

  const objBase = {
    base_id: "juicio_id",
    aditivo_id: "materia_id",
    title:"Juicios - Materias"
  };


  const objAditivos = {
    label: "Materia",
    name: "materia",
    base:"juicio"
  };

  const {
    handleSubmit,
    handleReset,
    handleBlur,
    setFieldValue,
    setValues,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: formSchema,
    onSubmit: (values) => {
      swalAction(
        save,
        `${selected ? "Editar" : "Guardar"} Secretario`,
        `¿Desea ${selected ? "editar" : "guardar"} este Secretario?`,
        "question"
      );
    },
    onReset: () => {
      setOpen(false);
    },
  });

  function onChange({ target }: { target: any }) {
    setFieldValue(target.name, target.value);
  }

  useEffect(() => {
    if (selected){
      setId(selected._id);
      setOpenAd(true);
      setValues(
        {
          name: selected.name,
          modalidad_id: selected.modalidad_id,
        },
        true
      );
      setTimeout(() => {
        setFormRendered(true);
      }, 0);
    }
  }, []);
  async function save() {
    const method = selected ? "PUT" : "POST";
    const rute = selected ? `/${selected._id}` : "";
    const res = await peticionBack(
      { ...values },
      "/juicios" + rute,
      method,
      true
    );
    if (res){
      setId(res._id);
      setOpenAd(true);
      setCloseForm(false);
      setTimeout(() => {
        setFormRendered(true);
      }, 0);
    }
  }

  return (
    <>
        <Collapse
        in={closeForm}
        transitionDuration={400}
        transitionTimingFunction="linear"
      >
    <form onSubmit={handleSubmit} onReset={handleReset}>
      <Grid justify="space-between" grow>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
        <InputTextMan
              label="Nombre"
              name="name"
              type="text"
              value={values.name}
              touched={touched.name}
              error={errors.name}
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              required
              rightSection
            />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <InputSearch
            label="Modalidad"
            name="modalidad_id"
            value={values.modalidad_id}
            touched={touched.modalidad_id}
            error={errors.modalidad_id}
            values={modalidadJuicios}
            labelVal="name"
            valueVal="_id"
            onChangeMethod={({ target }: any) =>
              setFieldValue("modalidad_id", target.value)
            }
            onBlurMethod={handleBlur}
            required
          />
        </Grid.Col>
      </Grid>
      <div className="d-grid gap-2 d-md-flex justify-content-md-around mt-2">
        { !selected && <Button variant="filled" color="redColor" type="reset" className="mt-3">
          Cancelar
        </Button> }
        <Button
          variant="filled"
          color="greenColor"
          type="submit"
          className="mt-2 mt-sm-3"
        >
          Guardar
        </Button>
      </div>
    </form>
    </Collapse>
    <Divider size="md" my="md" />
      {formRendered && (
      <Collapse
        in={openAd}
        transitionDuration={400}
        transitionTimingFunction="linear"
      >
        <UnoAMuchos
          id={id}
          endpointValues={endpointValues}
          objForm={objBase}
          objAditivos={objAditivos}
          selectValores={materias}
          setOpen={setOpen}
        />
      </Collapse>
      )}
    </>
  );
}
