/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Select } from "@mantine/core";
import { useEffect, useState } from "react";

export default function InputSearch({
  value,
  label,
  name,
  error,
  touched,
  onChangeMethod,
  onBlurMethod,
  values,
  required,
  labelVal,
  valueVal,
  getLabel, // Cambiado de labelVal
  getValue,
  disabled,
  readOnly,
  createLink,
  createText,
  changeValuesToStrings,
  creatable,
  onCreate,
  renderMenu,
  zIndex,
}: {
  value: string | null;
  label: string;
  name: string;
  error?: string;
  touched?: boolean;
  onChangeMethod: any;
  onBlurMethod?: any;
  values: Array<any>;
  required?: boolean;
  labelVal?: string;
  valueVal?: string;
  getLabel?: (item: any) => string;
  getValue?: (item: any) => string;
  disabled?: boolean;
  readOnly?: boolean;
  createLink?: string;
  createText?: string;
  changeValuesToStrings?: boolean;
  creatable?: boolean;
  onCreate?: any;
  renderMenu?: any;
  zIndex?: number;
}) {
  const [processValue, setProcessValue]: Array<any> = useState([]);

  useEffect(() => {
    setProcessValue(transformArray());
  }, [values]);

  /*  const transformArray = () => {
    return values.map((el) =>
      changeValuesToStrings
        ? {
            value: el[valueVal || "value"].toString(),
            label: el[labelVal || "label"].toString(),
          }
        : {
            value: el[valueVal || "value"],
            label: el[labelVal || "label"],
          }
    );
  }; */

  // const transformArray = () => {
  //   return values.map((el) => {
  //     const label =
  //       labelVal === "partes.nombre_busqueda"
  //         ? el.partes.nombre_busqueda
  //         : el[labelVal || "label"];
  //     return {
  //       //  value: el[valueVal || "value"].toString(),
  //       value: valueVal || "value" ? el[valueVal || "value"].toString() : "",
  //       label: labelVal === "partes.nombre_busqueda" ? label : label.toString(),
  //     };
  //   });
  // };

  const transformArray = () => {
    return values.map((el) => {
      const label = labelVal ? getNestedValue(el, labelVal) : "";
      const value = valueVal ? getNestedValue(el, valueVal) : "";

      return {
        value: value.toString(),
        label: label.toString(),
      };
    });
  };

  const getNestedValue = (obj: any, path: any) => {
    const keys = path.split(".");
    return keys.reduce((acc: any, key: any) => acc[key], obj);
  };

  return (
    <div className="mb-3">
      <Input.Label className="d-flex justify-content-between">
        <p className="m-0">
          {label} {required && <span className="text-danger">*</span>}
        </p>
        <a href={createLink}>{createText}</a>
      </Input.Label>
      <Select
        data={processValue}
        style={{ width: "100%", padding: 0, zIndex: zIndex }}
        value={value !== "" ? value : null}
        id={name}
        name={name}
        onChange={(value) => onChangeMethod({ target: { value, name } })}
        onBlur={onBlurMethod}
        error={touched && !!error}
        className={
          "form-control" +
          (error !== undefined && touched !== undefined ? " is-invalid" : "")
        }
        placeholder="Seleccionar"
        disabled={disabled}
        readOnly={readOnly}
        searchable
        clearable
      />
      {touched && !!error && <Input.Error>{error}</Input.Error>}
    </div>
  );
}
