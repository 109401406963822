import rutasAdmunusuarios from "./admin-usuarios"
import rutasDistritos from "./distritos"
import rutasEdificios from "./edificios"
import rutasEstados from "./estados"
import rutasEJudicial from "./estructura-judicial"
import rutasMunicipios from "./municipios"
import rutasOtros from "./otros"
import rutasPersonal from "./personal"
import rutasRegiones from "./regiones"
import rutasMaterias from "./materias"

const global = [
   ...rutasDistritos,
   ...rutasEdificios,
   ...rutasEstados,
   ...rutasMunicipios,
   ...rutasRegiones,
   ...rutasEJudicial,
   ...rutasAdmunusuarios,
   ...rutasPersonal,
   ...rutasMaterias,
   ...rutasOtros
]
export default global