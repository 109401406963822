import Adscripciones from "../../pages/estructura-judicial/adscripciones";
import Materias from "../../pages/estructura-judicial/materias";
import Tipos from "../../pages/estructura-judicial/tipos-adscripcion";

const rutasEJudicial = [
    { route: "/materias", component: Materias },
    { route: "/adscripciones", component: Adscripciones },
    { route: "/tipos-adscripciones", component: Tipos },
]

export default rutasEJudicial