import { Input, Textarea, rem } from "@mantine/core";
import { IconPencilExclamation } from "@tabler/icons-react";
import { FocusEventHandler } from "react";

export default function InputAreaForm({
  minRows,
  maxRows,
  value,
  label,
  placeholder,
  name,
  type,
  error,
  touched,
  onChangeMethod,
  onBlurMethod,
  maxLength,
  disabled,
  readonly,
  className,
  required,
  autocomplete,
  autosave,
  autoFocus,
  handleFocus,
  rightSection,
}: {
  minRows?: number;
  maxRows?: number;
  value: string | string[] | number;
  label?: string;
  placeholder?: string;
  name: string;
  type: string;
  error?: string;
  touched?: boolean;
  onChangeMethod?: any;
  onBlurMethod?: any;
  maxLength?: number;
  disabled?: boolean;
  readonly?: boolean;
  className?: string;
  required?: boolean;
  autocomplete?: string;
  autosave?: string;
  autoFocus?: boolean;
  handleFocus?: FocusEventHandler;
  rightSection: any;
}) {
  return (
    <>
      <Input.Label htmlFor={name}>
        {label} {required && <span className="text-danger">*</span>}
      </Input.Label>
      <>
        <Textarea
          resize="vertical"
          minRows={minRows}
          maxRows={maxRows}
          id={name}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChangeMethod}
          onBlur={onBlurMethod}
          className={className}
          disabled={disabled}
          readOnly={readonly}
          maxLength={maxLength}
          autoComplete={autocomplete}
          autoSave={autosave}
          autoFocus={autoFocus}
          onFocus={handleFocus}
          error={touched && !!error}
          rightSectionPointerEvents="none"
          rightSection={
            touched && !!error ? (
              <IconPencilExclamation
                style={{ width: rem(20), height: rem(20) }}
                color="var(--mantine-color-error)"
              />
            ) : null
          }
        />{" "}
        {touched && !!error && <Input.Error>{error}</Input.Error>}
      </>
    </>
  );
}
