/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { swalAction } from "../../../components/modals/ModalConfirm";
import { Button, Grid } from "@mantine/core";
import peticionBack from "../../../libs/peticiones";
import { Modulos } from "./dto/modulo.dto";
import InputSearch from "../../../components/inputs/InputSelectSearch";
import { Sistema } from "../sistemas/dto/sistema.dto";
import { InputNumber } from "../../../components/inputs/InputNumber";
import { useDispatch } from "react-redux";
import { loading, loaded } from "../../../core/slicers/loading/LoadingSlicer";
import InputTextMan from "../../../components/inputs/inputTextMan";

const defaultForm: Modulos = {
  name: "",
  system_id: "",
  parent_id: "",
  route: "",
  level: 0,
};

export default function Form({
  setOpen,
  selected,
}: {
  setOpen: any;
  selected?: any;
}) {
  const [sistemas, setSistema] = useState<Sistema[]>([]);
  const [modulos, setModulos] = useState<Modulos[]>([]);
  const dispatch = useDispatch();

  const formSchema = Yup.object().shape({
    name: Yup.string().required("Ingrese el Módulo"),
    system_id: Yup.string().required("Seleccione un sistema"),
    parent_id: Yup.string(),
    route: Yup.string().required("Ingrese una ruta"),
  });

  const {
    handleSubmit,
    handleReset,
    handleBlur,
    setFieldValue,
    setValues,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: formSchema,
    onSubmit: (values) =>
      swalAction(
        save,
        `${selected ? "Editar" : "Guardar"} Módulo`,
        `¿Desea ${selected ? "editar" : "guardar"} este Módulo?`,
        "question"
      ),
    onReset: () => {
      setOpen(false);
    },
  });

  function onChange({ target }: { target: any }) {
    setFieldValue(target.name, target.value);
  }

  useEffect(() => {
    getSistemas();
    getModulos();
    if (selected) {
      setValues(
        {
          name: selected.name,
          system_id: selected.system_id,
          route: selected.route,
          parent_id: selected.parent_id,
          level: selected.level,
        },
        true
      );
    }
  }, []);

  async function getSistemas() {
    dispatch(loading());
    const res = await peticionBack({}, "/sistemas", "GET", false);
    if (res) setSistema(res.data);
    dispatch(loaded());
  }
  async function getModulos() {
    dispatch(loading());
    const res = await peticionBack({}, "/modulos", "GET", false);
    if (res) setModulos(res.data);
    dispatch(loaded());
  }

  async function save() {
    const method = selected ? "PUT" : "POST";
    const rute = selected ? `/${selected._id}` : "";
    dispatch(loading());
    const res = await peticionBack(
      { ...values },
      "/modulos" + rute,
      method,
      true
    );
    if (res) {
      handleReset(null);
    }
    dispatch(loaded());
  }
  return (
    <form onSubmit={handleSubmit} onReset={handleReset}>
      <Grid justify="space-around" grow>
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <InputTextMan
            label="Nombre del Módulo"
            name="name"
            type="text"
            value={values.name}
            touched={touched.name}
            error={errors.name}
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            required
            rightSection
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <InputTextMan
            label="Ruta"
            name="route"
            type="text"
            value={values.route}
            touched={touched.route}
            error={errors.route}
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            required
            rightSection
          />
        </Grid.Col>
      </Grid>

      <Grid grow justify="space-around">
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <InputSearch
            label="Sistemas"
            name="system_id"
            value={values.system_id}
            error={errors.system_id}
            touched={touched.system_id}
            values={sistemas}
            labelVal="name"
            valueVal="_id"
            onChangeMethod={({ target }: any) =>
              setFieldValue("system_id", target.value)
            }
            required
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          {/* <InputNumber
            label="Nivel"
            name="level"
            type="text"
            value={values.level}
            touched={touched.level}
            error={errors.level}
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            required
            rightSection
          /> */}

          <InputNumber
            label="Nivel"
            name="level"
            value={values.level}
            touched={touched.level}
            error={errors.level}
            onChangeMethod={onchange}
            onBlurMethod={handleBlur}
            maxLength={10}
            required
            rightSection
            type="text"
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <InputSearch
            label="Módulo padre"
            name="parent_id"
            value={values.parent_id}
            error={errors.parent_id}
            touched={touched.parent_id}
            values={modulos}
            labelVal="name"
            valueVal="_id"
            onChangeMethod={({ target }: any) =>
              setFieldValue("parent_id", target.value)
            }
          />
        </Grid.Col>
      </Grid>

      <div className="d-grid gap-2 d-md-flex justify-content-md-around mt-2">
        <Button variant="filled" color="redColor" type="reset" className="mt-3">
          Cancelar
        </Button>
        <Button
          variant="filled"
          color="greenColor"
          type="submit"
          className="mt-2 mt-sm-3"
        >
          Guardar
        </Button>
      </div>
    </form>
  );
}
