import {
  Menu,
  Group,
  Text,
  Avatar,
  ActionIcon,
  rem,
  Badge,
  NavLink,
} from "@mantine/core";
import {
  IconLogout,
  IconUser,
  IconUserSquareRounded,
} from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { clear, loginData } from "../../core/slicers/auth/LoginSlicer";
import {
  changeProfile,
  profileData,
} from "../../core/slicers/perfil/profileSlicer";
import { useEffect, useState } from "react";

export function UserMenu() {
  const dispatch = useDispatch();
  const login = useSelector(loginData);
  const profile = useSelector(profileData);
  const [actual, setActual] = useState<any>({});
  const [perfiles, setPerfiles] = useState<any>([]);

  useEffect(() => {
    if (profile.profiles && profile.profiles.length > 0) {
      setPerfiles(
        profile.profiles.map((prof) => {
          return {
            name: prof.profile.name,
            value: prof.profile_id,
            changeProfile,
          };
        })
      );
    }
    if (profile.selected && profile.profiles && profile.profiles.length > 0) {
      const find = profile.profiles.find(
        (prof) => prof.profile_id === profile.selected
      );
      setActual(find ? find.profile : {});
    } else {
      setActual({});
    }
  }, [profile]);

  return (
    <Group className="m-4">
      <Badge
        size="lg"
        color="dark"
        variant="outline"
        leftSection={
          <IconUserSquareRounded
            style={{ width: rem(16), height: rem(16) }}
            stroke={1.5}
          />
        }
      >
        {actual.name}
      </Badge>
      <Menu
        withArrow
        width={300}
        position="bottom-end"
        transitionProps={{ transition: "pop" }}
        withinPortal
        closeOnEscape
      >
        <Menu.Target>
          <ActionIcon variant="outline" size="lg" radius="md" color="white">
            <IconUser
              style={{ width: rem(16), height: rem(16) }}
              stroke={1.5}
            />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item>
            <Group>
              <Avatar radius="xl" />

              <div>
                <Text fw={500}>{login.fullName}</Text>
                <Text size="xs" c="dimmed">
                  {login.username}
                </Text>
              </div>
            </Group>
          </Menu.Item>
          <NavLink
            label="Perfiles"
            leftSection={
              <IconUserSquareRounded
                style={{ width: rem(16), height: rem(16) }}
                stroke={1.5}
              />
            }
          >
            {perfiles.map((p: any) => (
              <NavLink
                onClick={() => dispatch(changeProfile(p.value))}
                key={p.value}
                label={p.name}
              />
            ))}
          </NavLink>
          <Menu.Divider />

          <Menu.Item
            leftSection={
              <IconLogout
                style={{ width: rem(16), height: rem(16) }}
                stroke={1.5}
              />
            }
            onClick={() => dispatch(clear())}
          >
            Salir
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
}
