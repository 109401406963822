import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Divider, Grid, Switch } from "@mantine/core";
import InputSearch from "../../../../../components/inputs/InputSelectSearch";
import { useDispatch } from "react-redux";
import {
    loaded,
    loading,
} from "../../../../../core/slicers/loading/LoadingSlicer";
import { Telefonos } from "./dto/telefono.dto";
import { swalAction } from "../../../../../components/modals/ModalConfirm";
import { InputNumber } from "../../../../../components/inputs/InputNumber";
import Datatable from "../../../../../components/datatable/Datatable";
import { faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import createURL from "../../../../../libs/url";
import peticionBack from "../../../../../libs/peticiones";
import { Tipos_Numero } from "../../../../../core/enums/enums";
import FormButtons from "../../../../../components/Forms/FormButtons";
import Alert from "../../../../../components/notification/Alert";
import { IconEye } from "@tabler/icons-react";

const defaultForm: Telefonos = {
    numero: "",
    tipo_numero: "",
    notificacion: false,
    partes_id: "",
};

export default function Telefono({
    actorSeleccionado,
    tipo,
    canEdit,
}: {
    actorSeleccionado?: any;
    tipo?: string;
    canEdit: boolean;
}) {
    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState<boolean>(false);
    const formSchema = Yup.object().shape({
        numero: Yup.string().max(13).required("Ingrese un número telefónico"),
        tipo_numero: Yup.mixed<Tipos_Numero>()
            .oneOf(Object.values(Tipos_Numero))
            .required("Seleccione un tipo de número"),
    });

    useEffect(() => {
        setRefresh(true);
        if (actorSeleccionado === null) {
            handleReset(null);
        }
    }, [actorSeleccionado]);

    const {
        handleSubmit,
        handleReset,
        handleBlur,
        setFieldValue,
        setValues,
        values,
        errors,
        touched,
    } = useFormik({
        initialValues: {
            ...defaultForm,
            partes_id: actorSeleccionado as string,
        },
        validationSchema: formSchema,
        onSubmit: (values) => {
            if (actorSeleccionado === null) {
                Alert("info", `Seleccione un ${tipo}`);
                return;
            }
            swalAction(
                save,
                `${values._id ? "Editar" : "Guardar"} Teléfono`,
                `¿Desea ${values._id ? "editar" : "guardar"} este Teléfono?`,
                "question"
            );
        },
        onReset: () => {},
    });

    function openToEdit(row: Telefonos) {
        if (
            JSON.stringify({ ...values, partes_id: "" }) !==
            JSON.stringify(defaultForm)
        ) {
            Alert("info", "Guarde sus cambios o limpie el formulario");
            return;
        }
        if (row.numero.startsWith("+52") && !canEdit) {
            row.numero = row.numero.substring(3);
        }
        setValues(row);
    }

    async function deleteRow(id: string) {
        const res = await peticionBack(
            {},
            `/preregistro-partes/telefonos-partes/${id}`,
            "DELETE",
            true
        );
        if (res) {
            if (values._id) {
                handleReset(null);
                setRefresh(true);
            } else setRefresh(true);
        }
    }

    async function getData(
        order: string,
        records: number,
        page: number,
        search: string
    ) {
        if (!actorSeleccionado) return null;
        const params: any = {
            pathname: `/preregistro-partes/telefonos-partes`,
            searchParams: [
                { name: "order", value: order },
                { name: "records", value: records },
                { name: "page", value: page },
                { name: "search", value: search },
                { name: "partes_id", value: actorSeleccionado },
                {
                    name: "rows",
                    value: "numero,tipo_numero,notificacion,partes_id,_id",
                },
            ],
        };
        const url = createURL(params);
        const res = await peticionBack({}, url, "GET", false);
        if (res) return res;
        setRefresh(true);
    }

    function onChange({ target }: { target: any }) {
        if (target.name === "notificacion")
            setFieldValue("notificacion", target.checked);
        else {
            setFieldValue(target.name, target.value);
        }
    }

    async function save() {
        dispatch(loading("backdrop"));
        const method = values._id ? "PUT" : "POST";
        const rute = values._id ? `/${values._id}` : "";
        const params = {
            ...values,
            numero: `+52${values.numero.toString()}`,
            id: undefined,
            partes_id: actorSeleccionado,
        };

        const res = await peticionBack(
            params,
            "/preregistro-partes/telefonos-partes" + rute,
            method,
            true
        );
        if (res) {
            handleReset(null);
            setRefresh(true);
        }
        dispatch(loaded());
    }

    function handleTelefonoBlur() {
        handleBlur({ target: { name: "telefono" } });
    }

    /* TABLA */
    const columns = [
        { data: "tipo_numero" },
        { data: "numero" },
        { data: "notificacion", render: (n: boolean) => (n ? "Sí" : "No") },
        {
            data: null,
            render: (row: any) => (
                <div className="d-flex justify-content-evenly">
                    {canEdit ? (
                        <></>
                    ) : (
                        <>
                            <FontAwesomeIcon
                                icon={faPencil}
                                className="text-warning cursor-pointer"
                                onClick={() => openToEdit(row)}
                            />
                            <FontAwesomeIcon
                                icon={faTrashCan}
                                className="text-danger cursor-pointer"
                                onClick={() =>
                                    swalAction(
                                        deleteRow,
                                        "Eliminar teléfono",
                                        `¿Desea eliminar el teléfono ${row.numero}?`,
                                        "question",
                                        row._id,
                                        "Eliminar"
                                    )
                                }
                            />
                        </>
                    )}
                </div>
            ),
        },
    ];

    const headers = ["Tipo número", "Número", "Notificación", "Acciones"];
    const columnsDef = [
        { orderable: false, targets: [3] },
        { className: "text-center", targets: [0, 1, 2] },
    ];

    return (
        <section>
            {canEdit ? (
                <></>
            ) : (
                <Grid justify="space-between" grow>
                    <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                        <InputSearch
                            label="Tipo Número"
                            name="tipo_numero"
                            value={values.tipo_numero}
                            error={errors.tipo_numero}
                            touched={touched.tipo_numero}
                            values={Object.entries(Tipos_Numero).map(
                                (value) => {
                                    return { value: value[0], key: value[1] };
                                }
                            )}
                            labelVal="value"
                            valueVal="key"
                            onChangeMethod={({ target }: any) =>
                                setFieldValue("tipo_numero", target.value)
                            }
                            required
                        />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                        <InputNumber
                            label="Teléfono"
                            name="numero"
                            value={values.numero}
                            touched={touched.numero}
                            error={errors.numero}
                            onChangeMethod={({ target }: any) =>
                                setFieldValue("numero", target.value)
                            }
                            onBlurMethod={handleTelefonoBlur}
                            maxLength={10}
                            required
                            rightSection
                            type="text"
                        />
                    </Grid.Col>
                    <Grid.Col
                        className="py-auto"
                        span={{ base: 12, md: 6, lg: 3 }}
                    >
                        <Switch
                            className="mt-3 mt-md-4 mt-lg-4"
                            label="Notificación"
                            name="notificacion"
                            checked={values.notificacion}
                            onChange={onChange}
                        />
                    </Grid.Col>
                </Grid>
            )}
            <FormButtons
                cancel={{
                    msg: "Limpiar",
                    type: "button",
                    color: "red",
                    variant: "light",
                    click: handleReset,
                    hidden: canEdit,
                }}
                save={{
                    msg: "Guardar teléfono",
                    type: "button",
                    color: "teal",
                    variant: "light",
                    click: handleSubmit,
                    hidden: canEdit,
                }}
            />

            <Divider my="md" />

            <Datatable
                columns={columns}
                columnDefs={columnsDef}
                headers={headers}
                control="back"
                petition={getData}
                stateRefresh={[refresh, setRefresh]}
            />
        </section>
    );
}
