import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Usuario } from "./dto/usuario.dt";
import { swalAction } from "../../../components/modals/ModalConfirm";
import peticionBack from "../../../libs/peticiones";
import InputSearch from "../../../components/inputs/InputSelectSearch";
import { Sistemas } from "../sistemas/dto/sistemas.dto";
import InputTextMan from "../../../components/inputs/inputTextMan";
import { InputNumber } from "../../../components/inputs/InputNumber";
import { Button, Grid } from "@mantine/core";
import { useDispatch } from "react-redux";
import { loaded, loading } from "../../../core/slicers/loading/LoadingSlicer";
import { InputPassword } from "../../../components/inputs/inputPassword";

const defaultForm: Usuario = {
  name: "",
  username: "",
  email: "",
  phone: "",
  pwd: "",
  system_id: "",
};

export default function Form({
  setOpen,
  selected,
}: {
  setOpen: any;
  selected?: Usuario;
}) {
  const [sistemas, setSistemas] = useState<Sistemas[]>([]);
  const dispatch = useDispatch();

  const formSchema = Yup.object().shape({
    name: Yup.string().required("Ingrese el nombre del usuario"),
    username: Yup.string().required("Ingrese un usurname"),
    email: Yup.string()
      .required("Ingrese un correo electrónico")
      .email("Correo electrónico invalido"),
    phone: Yup.string().required("Ingrese un número telefónico"),
    pwd: selected
      ? Yup.string().min(6, "La longitud minima es 6 caracteres")
      : Yup.string()
          .required("Ingrese una contraseña")
          .min(6, "La longitud minima es 6 caracteres"),
    system_id: Yup.string().required("Seleccione un sistema"),
  });

  const {
    handleSubmit,
    handleReset,
    handleBlur,
    setFieldValue,
    setValues,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: formSchema,
    onSubmit: (values) => {
      swalAction(
        save,
        `${selected ? "Editar" : "Guardar"} Usuario`,
        `¿Desea ${selected ? "editar" : "guardar"} este usuario?`,
        "question"
      );
    },
    onReset: () => {
      setOpen(false);
    },
  });

  function onChange({ target }: { target: any }) {
    setFieldValue(target.name, target.value);
  }

  useEffect(() => {
    getSistemas();
    if (selected)
      setValues(
        {
          name: selected.name,
          username: selected.username,
          email: selected.email,
          phone: selected.phone,
          system_id: selected.system_id,
        },
        true
      );
    // eslint-disable-next-line
  }, []);

  async function save() {
    const method = selected ? "PUT" : "POST";
    const rute = selected ? `/${selected._id}` : "";

    const params = selected
      ? {
          name: values.name,
          username: values.username,
          email: values.email,
          phone: `+52${values.phone.toString()}`,
          // pwd: values.pwd,
          system_id: values.system_id,
        }
      : {
          name: values.name,
          username: values.username,
          email: values.email,
          phone: `+52${values.phone.toString()}`,
          system_id: values.system_id,
          pwd: values.pwd,
        };
    const res = await peticionBack(params, `/usuarios` + rute, method, true);
    if (res) {
      if (selected && values.pwd) {
        const params = {
          pwd: values.pwd,
        };
        const resPwd = await peticionBack(
          params,
          `/usuarios/changePassword` + rute,
          method,
          true
        );
        if (resPwd) handleReset(null);
      } else handleReset(null);
    }
  }

  async function getSistemas() {
    dispatch(loading());
    const res = await peticionBack({}, "/sistemas", "GET", false);
    dispatch(loaded());
    if (res) setSistemas(res.data);
  }

  function handleTelefonoBlur() {
    handleBlur({ target: { name: "phone" } });
  }

  return (
    <form onSubmit={handleSubmit} onReset={handleReset}>
      <Grid justify="space-between" grow>
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <InputTextMan
            label="Nombre completo"
            name="name"
            type="text"
            value={values.name}
            touched={touched.name}
            error={errors.name}
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            required
            rightSection
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <InputTextMan
            label="Nombre de usuario"
            name="username"
            type="text"
            value={values.username}
            touched={touched.username}
            error={errors.username}
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            required
            rightSection
          />
        </Grid.Col>
      </Grid>
      <Grid justify="space-between" grow>
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <InputTextMan
            label="Correo electónico"
            name="email"
            type="text"
            value={values.email}
            touched={touched.email}
            error={errors.email}
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            required
            rightSection
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <InputNumber
            label="Teléfono"
            name="phone"
            value={values.phone}
            touched={touched.phone}
            error={errors.phone}
            onChangeMethod={({ target }: any) =>
              setFieldValue("phone", target.value)
            }
            onBlurMethod={handleTelefonoBlur}
            maxLength={10}
            required
            rightSection
            type="text"
          />
        </Grid.Col>
      </Grid>
      <Grid justify="space-between" grow>
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <InputSearch
            label="Sistema"
            name="system_id"
            value={values.system_id}
            touched={touched.system_id}
            error={errors.system_id}
            values={sistemas}
            labelVal="name"
            valueVal="_id"
            onChangeMethod={({ target }: any) =>
              setFieldValue("system_id", target.value)
            }
            onBlurMethod={handleBlur}
            required
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <InputPassword
            label="Contraseña"
            name="pwd"
            value={values.pwd as string}
            touched={touched.pwd}
            error={errors.pwd}
            onChangeMethod={({ target }: any) =>
              setFieldValue("pwd", target.value)
            }
            onBlurMethod={handleBlur}
            required={!selected}
          />
        </Grid.Col>
      </Grid>
      <div className="d-grid gap-2 d-md-flex justify-content-md-around mt-2">
        <Button variant="filled" color="redColor" type="reset" className="mt-3">
          Cancelar
        </Button>
        <Button
          variant="filled"
          color="greenColor"
          type="submit"
          className="mt-2 mt-sm-3"
        >
          Guardar
        </Button>
      </div>
    </form>
  );
}
