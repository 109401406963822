export enum Sexo {
    MASCULINO = 'MASCULINO',
    FEMENINO = 'FEMENINO',
    LGBT = 'LGBT',
}

export enum Tipo_identificacion{
    INE = 'INE',
    CÉDULA = 'CÉDULA',
    PASAPORTE = 'PASAPORTE',
    LICENCIA = 'LICENCIA'
}

export enum Tipos_actuacion {
    INDIVIDUAL = 'INDIVIDUAL',
    COLECTIVA = 'COLECTIVA',
}


export enum Tipos_Numero {
    FIJO = 'FIJO',
    CELULAR = 'CELULAR',
}

export enum Tipos_Defensa {
    PRIVADA = 'PRIVADA',
    PUBLICA = 'PUBLICA',
}

export enum Tipos_partes {
    ACTOR = 'ACTOR',
    DEMANDADO = 'DEMANDO',
}

export enum Tipo_Persona {
    FISICA = 'FISICA',
    MORAL = 'MORAL',
}

export enum Instancia {
    PRIMERA_INSTANCIA = 'PRIMERA INSTANCIA',
    SEGUNDA_INSTANCIA = 'SEGUNDA INSTANCIA',
    CENTROS_DE_CONVIVENCIA = 'CENTROS DE CONVIVENCIA',
    CENTROS_DE_MEDIACION = 'CENTROS DE MEDIACIÓN',
    JUNTAS_DE_CONCILIACION = 'JUNTAS DE CONCILIACIÓN'
  }

// export enum TiposDemanda {
//     PRIVADA = 'PRIVADA',
//     PUBLICA = 'PUBLICA',
// }