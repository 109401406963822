import axios, { AxiosResponse } from "axios";
import Alert from "../components/notification/Alert";

interface PetitionType {
  query?: any,
  rows?: string,
  page?: number,
  records?: number,
  order?: string,
  search?: string,
  join?: any
}

interface SaveFilePetition {
  endpoint?: string,
  show: boolean;
  files: {
    ref: string;
    type: string;
    notes?: string;
    files: Blob[];
  }
}

const isValidUrl = (urlString: string) => {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
    "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

function midAuth(headers?: any): object {
  if (Object.hasOwn(headers || {}, 'Authorization')) { //Si tiene Autorizacion y aun no esta precargado el localstorage
    return { headers }
  }
  const auth = localStorage.getItem("persist:auth");
  const fHeaders = { ...headers };
  if (auth) {
    const jsonAuth = JSON.parse(auth)
    if (jsonAuth.token) {
      const token: string = jsonAuth.token;
      fHeaders.headers = {
        ...fHeaders.headers,
        Authorization: `bearer ${token.substring(1, token.length - 1)}`
      }
    }
  }
  return fHeaders;
}

export async function getTypePetition(endpoint: string, finalData: any, tipo: string, headers?: any): Promise<AxiosResponse<any, any>> {
  const url = isValidUrl(endpoint) ? endpoint : process.env.REACT_APP_BACK_URI + endpoint;
  const fHeaders = midAuth(headers);
  if (tipo === "GET") {
    const res = await axios.get(url, fHeaders);
    return res;
  } else if (tipo === "PUT") {
    const res = await axios.put(url, finalData, fHeaders);
    return res;
  } else if (tipo === "DELETE") {
    const res = await axios.delete(url, fHeaders);
    return res;
  } else {
    const res = await axios.post(url, finalData, fHeaders);
    return res;
  }
}

export function getType(cause: any): "success" | "info" | "warning" | "error" {
  if (typeof cause === "number") {
    switch (cause) {
      case 200:
      case 201:
        return "success";
      case 204:
      case 208:
        return "info";
      case 400:
      case 404:
      case 405:
      case 412:
        return "warning";
      case 401:
      case 409:
      case 500:
      case 501:
      case 502:
      case 503:
      case 504:
      case 505:
      case 506:
      case 507:
      case 508:
      case 509:
      case 510:
      case 511:
      case 512:
        return "error";
      default:
        return "error";
    }
  } else {
    return "error";
  }
}

export default async function peticionBack(params: PetitionType | any, endpoint: string, tipo: string, show: boolean, headers?: any, full?: boolean) {
  let status = 400;
  try {
    const finalData = params ? params : "";
    const res = await getTypePetition(endpoint, finalData, tipo, headers);
    if (full) return res;
    const data = res.data;
    if (res.status >= 200 && res.status <= 299) {
      if (show) {
        Alert("success", getTypeMessage(data, tipo));
      }
      if (res.status === 200 || res.status === 201) {
        return data;
      }
      return null;
    }
    status = res.status;
    throw new Error(data.message);
  } catch (err: any) {
    const message = err.response ? (err.response.data.message || err.response.data) : err.message;
    if (show) {
      Alert(getType(status), message)
    }
    if (full) return err;
    return null;
  }
}

function getTypeMessage(data: any, tipo: string) {
  if (data.message) return data.message;
  if (typeof data === "string") return data;
  switch (tipo) {
    case "GET":
      return "Información consultada correctamente";
    case "PUT":
      return "Registro actualizado";
    case "DELETE":
      return "Registro eliminado";
    default:
      return "Registro guardado";
  }
}

export async function uploadFiles(petition: SaveFilePetition){
  try {
    const url =  process.env.REACT_APP_BACK_URI + (petition.endpoint || "/archivos/upload");
    const headers = midAuth();
    const formData = new FormData();
    formData.append("referencia_id", petition.files.ref);
    formData.append("tipoDocumento_id", petition.files.type);
    formData.append("observaciones", petition.files.notes || "");
    petition.files.files.forEach((f: any) =>{
      console.log(f)
      formData.append("files", new File([f], f.name));
    });
    const res = await axios.post(url, formData, headers);
    if(res.status >= 200 && res.status <= 299){
      if (petition.show) Alert("success", getTypeMessage(res.data, "POST"));
      if([200, 201].includes(res.status)) return res.data;
    }
    throw new Error(res.data);
  } catch (error: any) {
    if (petition.show) {
      Alert(getType(error.code || 400), error.message);
    }
  }
  return null;
}