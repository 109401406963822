/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import createURL from "../../../libs/url";
import peticionBack from "../../../libs/peticiones";
import { swalAction } from "../../../components/modals/ModalConfirm";
import CardMan from "../../../components/card/Card";
import { Button } from "@mantine/core";
import Datatable from "../../../components/datatable/Datatable";
import { colorRed } from "../../../core/constants";
import { ExpedientePersonal } from "./dto/expedienete-personal.dto";
import NavTop from "./navbarTop";
import { useSelector } from "react-redux";
import { moduleData } from "../../../core/slicers/permisos/permissionsSlicer";
import { checkPermission } from "../../../libs/permisosModulo";
import { convertirFecha } from "../../../libs/formats";

export default function Personal() {
  const [refresh, setRefresh] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<ExpedientePersonal | undefined>();
  const modulos = useSelector(moduleData);

  async function getData(
    order: string,
    records: number,
    page: number,
    search: string
  ) {
    const params: any = {
      pathname: `/personal`,
      searchParams: [
        { name: "order", value: order },
        { name: "records", value: records },
        { name: "page", value: page },
        { name: "search", value: search },
        {
          name: "rows",
          value:
            "nombre,aPaterno,aMaterno,fechaNacimiento,sexo,curp,rfc,claveIdentificacion,_id",
        },
      ],
    };
    const url = createURL(params);
    const res = await peticionBack({}, url, "GET", false);

    return res;
  }

  /* TABLA */
  const columns = [
    { data: "nombre" },
    { data: "aPaterno" },
    { data: "aMaterno" },
    { data: "fechaNacimiento" },
    { data: "sexo" },
    { data: "curp" },
    { data: "rfc" },
    { data: "claveIdentificacion" },
    {
      data: null,
      render: (row: ExpedientePersonal) => (
        <div className="d-flex justify-content-evenly">
          {checkPermission(modulos, "u") && (
            <FontAwesomeIcon
              icon={faPencil}
              className="text-warning cursor-pointer"
              onClick={() => openToEdit(row)}
            />
          )}
          {checkPermission(modulos, "d") && (
            <FontAwesomeIcon
              icon={faTrashCan}
              className="text-danger cursor-pointer"
              onClick={() =>
                swalAction(
                  deleteRow,
                  "Eliminar Empleado",
                  `¿Desea eliminar el empleado ${row.nombre}?`,
                  "question",
                  row._id,
                  "Eliminar"
                )
              }
            />
          )}
        </div>
      ),
    },
  ];

  const columnDefs = [
    { orderable: false, targets: [9] },
    { className: "text-center", targets: [0, 1] },
  ];

  async function deleteRow(id: number) {
    const res = await peticionBack({}, `/personal/${id}`, "DELETE", true);
    if (res) setRefresh(true); //
  }

  function openToEdit(row: ExpedientePersonal) {
    row.fechaNacimiento = convertirFecha(row.fechaNacimiento as string)
    setSelected(row);
  }

  useEffect(() => {
    if (selected) setOpen(true);
  }, [selected]);

  useEffect(() => {
    if (!open) {
      if (selected) setSelected(undefined);
    }
  }, [open]);

  return (
    <CardMan title="Personal" showMenu={false}>
      <div className="d-flex flex-column flex-sm-row mb-2 d-flex justify-content-end">
        {!open && checkPermission(modulos, "c") && (
          <Button
            color={colorRed}
            onClick={() => setOpen(true)}
            className="ml-2"
            style={{ marginRight: "13px", marginLeft: "13px" }}
          >
            Agregar
          </Button>
        )}
      </div>
      {open ? (
        // <Form setOpen={setOpen} selected={selected} />
        <NavTop setOpen={setOpen} selected={selected} />
      ) : (
        checkPermission(modulos, "r") && (
          <Datatable
            columns={columns}
            headers={[
              "Nombre",
              "Apellido Paterno",
              "Apellido Materno",
              "Fecha de Nacimiento",
              "Sexo",
              "Curp",
              "Rfc",
              "Clave de Identificación",
              "Acciones",
            ]}
            columnDefs={columnDefs}
            petition={getData}
            control="back"
            stateRefresh={[refresh, setRefresh]}
            className="p-0"
          />
        )
      )}
    </CardMan>
  );
}
