import "dayjs/locale/es-mx";
import dayjs from "dayjs";
import { Input, rem } from "@mantine/core";
import { DateInput, DatesProvider } from "@mantine/dates";
import { FocusEventHandler } from "react";
import { IconCalendar } from "@tabler/icons-react";

export default function InputDateMan({
  label,
  maxDate,
  value,
  placeholder,
  name,
  error,
  touched,
  onChangeMethod,
  onBlurMethod,
  disabled,
  readonly,
  className,
  required,
  autosave,
  autoFocus,
  handleFocus,
  clearable
}: {
  label?: string;
  maxDate?: any;
  value: any;
  placeholder?: string;
  name: string;
  error?: string;
  touched?: boolean;
  onChangeMethod?: any;
  onBlurMethod?: any;
  disabled?: boolean;
  readonly?: boolean;
  className?: string;
  required?: boolean;
  autosave?: string;
  autoFocus?: boolean;
  handleFocus?: FocusEventHandler;
  clearable?:boolean;
}) {
  const icon = (
    <IconCalendar style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
  );
  return (
    <>
      <Input.Label htmlFor={name}>
        {label} {required && <span className="text-danger">*</span>}
      </Input.Label>
      <>
        <DatesProvider
          settings={{
            locale: "es-mx",
            firstDayOfWeek: 0,
            weekendDays: [0],
            timezone: "GMT",
          }}
        >
          <DateInput
            id={name}
            //valueFormat="DD-MMM-YYYY"
            valueFormat="DD/MM/YYYY" // Formato dd/mm/YYYY
       
            maxDate={dayjs().toDate()}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={(value) => onChangeMethod({ target: { value, name } })}
            onBlur={onBlurMethod}
            className={className}
            disabled={disabled}
            readOnly={readonly}
            autoSave={autosave}
            autoFocus={autoFocus}
            onFocus={handleFocus}
            error={touched && !!error}
            clearable={clearable}
            leftSection={icon}
          />{" "}
        </DatesProvider>
        {touched && !!error && <Input.Error>{error}</Input.Error>}
      </>
    </>
  );
}
