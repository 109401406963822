//import { StoreProvider } from "./context/AppContext";
import Router from "./router";
import "./styles/App.css";
import "./styles/app.scss";
import "@mantine/core/styles.css";


function App() {
  return (
    <Router />
  );
}

export default App;