/* eslint-disable react-hooks/exhaustive-deps */
import { useDisclosure } from "@mantine/hooks";
import { Drawer, Box, Burger, Badge, rem } from "@mantine/core";
import { NavLink } from "@mantine/core";
import {
  IconAlertOctagon,
  IconHome,
  IconPlayerRecord,
} from "@tabler/icons-react";
import { useState, useEffect } from "react";
import { IconSquareRoundedArrowRight } from "@tabler/icons-react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  moduleData,
  setActualModule,
} from "../../core/slicers/permisos/permissionsSlicer";
import toRecursive from "../../libs/permissions";
import { Modulo } from "../../core/slicers/permisos/interfaces/permisosInterface";

export default function Sidebartest() {
  const [opened, { open, close }] = useDisclosure(false);
  const [active, setActive] = useState<string>("");
  const [fullruta, setFullruta] = useState<string>("");
  const modulos = useSelector(moduleData);
  const [resursiveModules, setRecursiveModules] = useState<Modulo[]>([]);
  let location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    setActive(location.pathname);
    close();
  }, [location]);

  useEffect(() => {
    if (modulos.modules) {
      const cleanModules = modulos.modules.filter(
        (a: any) => a.c || a.r || a.u || a.d
      );
      setRecursiveModules(toRecursive(cleanModules));
    }
  }, [modulos]);

  useEffect(() => {
    if (modulos.modules) {
      const finded = modulos.modules.find(
        (module) => module.route === location.pathname
      );
      if (finded) {
        dispatch(setActualModule(finded._id));
      }
    }
  }, [modulos, location.pathname]);
  return (
    <>
      <Drawer
        offset={8}
        size="xs"
        radius="md"
        opened={opened}
        onClose={close}
        title="Sistema PAJE"
        overlayProps={{ backgroundOpacity: 0.3, blur: 1.5 }}
        transitionProps={{
          transition: "slide-right",
          duration: 150,
          timingFunction: "linear",
        }}
        scrollAreaComponent={Box}
      >
        <NavLink
          component={Link}
          active={location.pathname === "/"}
          leftSection={<IconHome />}
          color="pink"
          key={"homeRoute"}
          to={"/"}
          label={"Inicio"}
          onClick={() => setFullruta("/")}
        />
        {resursiveModules.length > 0 ? (
          resursiveModules.map((item: any) => (
            <RecursiveMenu
              active={active}
              module={item}
              key={item.route}
              fullruta={[fullruta, setFullruta]}
            />
          ))
        ) : (
          <>
            <Badge className="mt-lg-4" color="orange" variant="outline">
              No hay modulos asignados a este perfil
            </Badge>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                marginTop: "10%",
              }}
            >
              <IconAlertOctagon
                color="orange"
                style={{ width: rem(50), height: rem(50) }}
              />
            </div>
          </>
        )}
      </Drawer>

      <Burger color="white" opened={opened} onClick={open} size="sm" />
    </>
  );
}

function RecursiveMenu({
  module,
  active,
  rutaAscendente,
  fullruta,
}: {
  module: ModuloPermiso;
  active?: string;
  rutaAscendente?: string;
  fullruta: [string, any];
}) {
  let location = useLocation();
  useEffect(() => {
    if (location.pathname === module.route) fullruta[1](rutaAscendente);
  }, [location]);

  function getIcon() {
    return module.children ? (
      <IconSquareRoundedArrowRight size="1rem" stroke={1.5} />
    ) : (
      <IconPlayerRecord size="1rem" stroke={1.5} />
    );
  }

  return module.children ? (
    <NavLink
      leftSection={getIcon()}
      active={fullruta.includes(module.route as string)}
      color="pink"
      label={module.name}
      childrenOffset={28}
      variant="subtle"
      defaultOpened={fullruta.includes(module.route as string)}
    >
      {module.children.map((mc: any, ix: number) => (
        <RecursiveMenu
          module={mc}
          active={active}
          rutaAscendente={(rutaAscendente || "") + module.route}
          key={mc.route + ix + ""}
          fullruta={fullruta}
        />
      ))}
    </NavLink>
  ) : (
    <NavLink
      component={Link}
      active={module.route === active}
      leftSection={getIcon()}
      color="pink"
      key={module.name}
      to={module.route}
      label={module.name}
    />
  );
}

interface ModuloPermiso {
  id?: string;
  name: string;
  route: string;
  r: boolean;
  c: boolean;
  u: boolean;
  d: boolean;
  parent?: string;
  children?: Array<ModuloPermiso>;
  level?: number;
}
