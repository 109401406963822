import Sistemas from "../../pages/admin-usuarios/sistemas/Index";
import Perfiles from "../../pages/admin-usuarios/perfiles/index"
import PerfilesModulos from "../../pages/admin-usuarios/perfiles-modulos/index"
import Usuarios from "../../pages/admin-usuarios/usuarios/Index" 
import UsuarioPerfiles from "../../pages/admin-usuarios/usuarios-perfiles"
import Modulo from "../../pages/admin-usuarios/modulos/Index";

const rutasAdmunusuarios = [
    { route: "/perfiles", component: Perfiles },
    { route: "/perfiles-modulos", component: PerfilesModulos },
    { route: "/usuarios", component: Usuarios },
    { route: "/usuarios-perfiles", component: UsuarioPerfiles },
    { route: "/sistemas", component: Sistemas },
    {route: "/modulos", component: Modulo}

]

export default rutasAdmunusuarios
