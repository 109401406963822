import { Card, Text, Menu, ActionIcon, rem, Skeleton, LoadingOverlay } from "@mantine/core";
import { IconScale, IconDots, IconTrash } from "@tabler/icons-react";
import { loadingData } from "../../core/slicers/loading/LoadingSlicer";
import { useSelector } from "react-redux";

export default function CardMan({
  children,
  title,
  showMenu,
  icon
}: {
  children: React.ReactNode;
  title: string;
  showMenu?: boolean;
  icon?: JSX.Element
  }) {
  
  const loading = useSelector(loadingData);

  return (
    <Card withBorder shadow="sm" radius="md">
      <Card.Section withBorder inheritPadding py="xs">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            {
              icon ? icon : 
              <IconScale
                style={{ width: rem(40), height: rem(40) }}
                stroke={1.5}
                color="var(--mantine-color-blue-filled)"
              />
            }
          </div>
          <div>
            <Text fw={500} fs={{md:"md"}}>
              {title}
            </Text>
          </div>
          <div>
            {showMenu ? (
              <Menu withinPortal position="bottom-end" shadow="sm">
                <Menu.Target>
                  <ActionIcon variant="subtle" color="gray">
                    <IconDots style={{ width: rem(16), height: rem(16) }} />
                  </ActionIcon>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item
                    leftSection={
                      <IconTrash style={{ width: rem(14), height: rem(14) }} />
                    }
                    color="red"
                  >
                    Delete all
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ) : null}
          </div>
        </div>
      </Card.Section>
      <Card.Section inheritPadding className="pt-3 pb-4">
        {loading.loadingStack.length > 0 && (
          loading.loadingStack.at(-1) === 'backdrop' ?
          <LoadingOverlay visible color="primary" loaderProps={{ type: "dots", size: 40 }} /> :
          <>
            <Skeleton height={20} radius="xl" />
            <Skeleton height={20} mt={10} radius="xl" />
            <Skeleton height={20} mt={10} radius="xl" />
            <Skeleton height={20} mt={10} radius="xl" />
            <Skeleton height={20} mt={10} radius="xl" />
            <Skeleton height={20} mt={10} radius="xl" />
            <Skeleton height={20} mt={10} radius="xl" />
            <Skeleton height={20} mt={10} radius="xl" />
            <Skeleton height={20} mt={10} width="70%" radius="xl" />
          </>
        )}
        <div className={'position-relative ' + (loading.loadingStack.length > 0 && loading.loadingStack.at(-1) !== 'backdrop' ? 'd-none': '')}>
            {children}
        </div>
      </Card.Section>
    </Card>
  );
}
