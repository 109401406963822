import { TypeFileRef } from "../../../../core/types/TypeFile";
import peticionBack from "../../../../libs/peticiones";
import createURL from "../../../../libs/url";
import { JuiciosDTO } from "../../../materias/juicios/dto/juicios";
import { Preregistro } from "../../dto/preregistro";
import { datosGenerales as ParteDTO } from "../actores-demandados/datos-generales/dto/general.dto";
import { PromoventeDto } from "../demanda/dto/promovente-dto";

export async function getPromoventes(idActuacion: string): Promise<null | PromoventeDto[]>{
    const params: any = {
        pathname: `/promoventes`,
        searchParams: [
            { name: "actuaciones_id", value: idActuacion },
        ],
    }
    const url = createURL(params);
    const res = await peticionBack({}, url, "GET", false);
    if (res.data && res.data.length > 0) {
        res.data.forEach((d: PromoventeDto) => {
            if (!d.nombre_busqueda)
                d.nombre_busqueda = `${d.paterno} ${d.materno} ${d.nombre}`;
        });
        return res.data;
    }
    return null;
}

export async function getPartes(idCarpeta: string, type: 'ACTOR' | 'DEMANDO'): Promise<null|ParteDTO[]>{
    const params: any = {
        pathname: `/preregistro-partes/general`,
        searchParams: [
          { name: "carpetas_judiciales_id", value: idCarpeta },
          { name: "tipos_parte", value: type },

        ],
      };
    const route = `/preregistro-partes/general?carpetas_judiciales_id=${idCarpeta}&tipos_parte=${type}`;
    const res = await peticionBack(params, route, "GET", false);
    if (res && res.data.length > 0) {
        return res.data.map((d: any) => d.partes);
    }
    return null;
}

export async function getDocumentos(doc: boolean, idActuacion: string, type: string): Promise<null | TypeFileRef | true>{
    if(!doc) return true;
    const res: TypeFileRef = await peticionBack({}, `/archivos/${idActuacion}/${type}`, "GET", false);
    if (res && res.files && res.files.length > 0) {
        return res;
    }
    return null;
}

export async function getJuicio(idJuMa: string): Promise<null | JuiciosDTO>{
    const res = await peticionBack({}, `/juicios-materias?populate=juicio_id&id=${idJuMa}`, "GET", false);
    if (res && res.data.length > 0){
        return res.data[0].juicio as JuiciosDTO;
    }
    return null;
}

export async function getPreregistro(idPrer: string): Promise<null | Preregistro>{
    const res = await peticionBack({}, "/preregistro-inicial/" + idPrer, "GET", false);
    if(res && res.enviado) return res as Preregistro;
    return null;
}