import { Image } from "@mantine/core";
import { Container, Group } from "@mantine/core";
import classes from "../../styles/components/topBar/HeaderSimple.module.css";
import { UserMenu } from "./UserMenu";
import Sidebartest from "./Sidemenu";

export function HeaderSimple() {
  return (
    <header className={classes.header}>
      <Container size="responsive" className={classes.inner}>
        <Group>
          <Image
            h={50}
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGNsYXNzPSJpY29uIGljb24tdGFibGVyIGljb24tdGFibGVyLWRldmljZS1kZXNrdG9wIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgZmlsbD0ibm9uZSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cGF0aCBzdHJva2U9Im5vbmUiIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNMyA1YTEgMSAwIDAgMSAxIC0xaDE2YTEgMSAwIDAgMSAxIDF2MTBhMSAxIDAgMCAxIC0xIDFoLTE2YTEgMSAwIDAgMSAtMSAtMXYtMTB6IiAvPjxwYXRoIGQ9Ik03IDIwaDEwIiAvPjxwYXRoIGQ9Ik05IDE2djQiIC8+PHBhdGggZD0iTTE1IDE2djQiIC8+PC9zdmc+"
          />
          <Sidebartest />
        </Group>
        <Group>
          <UserMenu />
        </Group>
      </Container>
    </header>
  );
}
