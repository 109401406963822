import { Input, NumberInput, rem } from "@mantine/core";
import { IconPencilExclamation } from "@tabler/icons-react";

export function InputNumber({
  label,
  name,
  onChangeMethod,
  onBlurMethod,
  value,
  error,
  touched,
  disabled,
  prefix,
  suffix,
  maxLength,
  required,
  rightSection,
  type,
}: {
  label: string;
  name: string;
  onChangeMethod: any;
  onBlurMethod: any;
  value: string | number;
  error?: string;
  touched?: boolean;
  disabled?: boolean;
  prefix?: string;
  suffix?: string;
  maxLength?: number;
  required?: boolean;
  rightSection: any;
  type: any;
}) {
  return (
    <>
      {label && (
        <Input.Label htmlFor={name}>
          {label} {required && <span className="text-danger">*</span>}
        </Input.Label>
      )}
      <NumberInput
        onBlur={onBlurMethod}
        value={value}
        prefix={prefix ? prefix : undefined}
        suffix={suffix ? suffix : undefined}
        disabled={disabled ? true : false}
        maxLength={maxLength || 18}
        error={touched && !!error}
        onChange={(value) => onChangeMethod({ target: { value, name } })}
        hideControls
        type={type}
        rightSection={
          touched && !!error ? (
            <IconPencilExclamation
              style={{ width: rem(20), height: rem(20) }}
              color="var(--mantine-color-error)"
            />
          ) : null
        }
      />
      {touched && !!error && <Input.Error>{error}</Input.Error>}
    </>
  );
}
