import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../components/redux/store";
import { loadingState } from "./interfaces/loadingInterface";

const initialState: loadingState = {
  loadingStack: []
}

export const loadingSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<string|undefined>) => {
      state.loadingStack.push(action.payload || 'default');
    },
    loaded: (state) => {
      state.loadingStack.pop()
    },
  },

});
export const { loading, loaded } = loadingSlice.actions
export default loadingSlice.reducer

export const loadingData = (state: RootState) => state.loading