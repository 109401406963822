/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { swalAction } from "../../../components/modals/ModalConfirm";
import { Button, Grid } from "@mantine/core";
import peticionBack from "../../../libs/peticiones";
import { ModalidadJuicios } from "./dto/modalidad-juicios.dto";
import { useDispatch } from "react-redux";
import { loaded, loading } from "../../../core/slicers/loading/LoadingSlicer";
import InputTextMan from "../../../components/inputs/inputTextMan";

const defaultForm: ModalidadJuicios = {
  name: "",
};

export default function Form({
  setOpen,
  selected,
}: {
  setOpen: any;
  selected?: any;
}) {
  const dispatch = useDispatch();

  const formSchema = Yup.object().shape({
    name: Yup.string().required("Ingrese la modalidad del juicio"),
  });

  const {
    handleSubmit,
    handleReset,
    handleBlur,
    setFieldValue,
    setValues,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: formSchema,
    onSubmit: (values) =>
      swalAction(
        save,
        `${selected ? "Editar" : "Guardar"} Modalidad de Juicio`,
        `¿Desea ${selected ? "editar" : "guardar"} esta Modalidad de Juicio?`,
        "question"
      ),
    onReset: () => {
      setOpen(false);
    },
  });

  function onChange({ target }: { target: any }) {
    setFieldValue(target.name, target.value);
  }

  useEffect(() => {
    if (selected) {
      setValues(
        {
          name: selected.name,
        },
        true
      );
    }
  }, []);

  async function save() {
    const method = selected ? "PUT" : "POST";
    const rute = selected ? `/${selected._id}` : "";
    dispatch(loading());
    const res = await peticionBack(
      { ...values },
      "/modalidad-juicio" + rute,
      method,
      true
    );
    if (res) {
      handleReset(null);
    }
    dispatch(loaded());
  }
  return (
    <form onSubmit={handleSubmit} onReset={handleReset}>
      <Grid justify="space-around" grow>
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <InputTextMan
            label="Modalidad de Juicio"
            name="name"
            type="text"
            value={values.name}
            touched={touched.name}
            error={errors.name}
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            required
            rightSection
          />
        </Grid.Col>
      </Grid>

      <div className="d-grid gap-2 d-md-flex justify-content-md-around mt-2">
        <Button variant="filled" color="redColor" type="reset" className="mt-3">
          Cancelar
        </Button>
        <Button
          variant="filled"
          color="greenColor"
          type="submit"
          className="mt-2 mt-sm-3"
        >
          Guardar
        </Button>
      </div>
    </form>
  );
}
