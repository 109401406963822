import { Document, Page, Text, View } from "@react-pdf/renderer";
import { Preregistro } from "../../../../pages/pre-registro/dto/preregistro";
import { stylePDF as sg } from "../styles";
import HeaderPDF from "../Header";
import { PromoventeDto } from "../../../../pages/pre-registro/tabs/demanda/dto/promovente-dto";
import { datosGenerales as ParteDTO } from "../../../../pages/pre-registro/tabs/actores-demandados/datos-generales/dto/general.dto";
import { TypeFileRef } from "../../../../core/types/TypeFile";
import { fDatetime } from "../../../../libs/formats";

export default function AcusePreregistro(data: Preregistro ){
    function gI(key: FieldType) {
        switch (key) {
            case "numero":
                return data?.numero || "_";
            case "anio":
                return data?.anio || "_";
            case "carpeta":
                return data?.tipo_carpetas?.name || "_";
            case "oficialia":
                return data?.oficialia.label || "_";
            case "demanda":
                return data?.tipo_demanda || "";
            case "juicio":
                return data?.juicio?.name || "_";
            case "promoventes":
                return data?.promoventes || null;
            case "actores":
                return data?.actores || null;
            case "demandados":
                return data?.demandados || null;
            case "documentos":
                return data?.documentos || null;
            case "created":
                return data?.createdAt || "_";
            default:
                return "Dato desconocido";
        }
    }

    
    return(
        <Document>
            <Page style={[sg.page]} orientation="portrait">
                <HeaderPDF />
                <View style={[]}>
                    <Text style={[sg.title, sg.tCenter]}>
                        Poder Judicial Del Estado De México
                    </Text>
                    <Text style={[sg.title, sg.tCenter, { marginTop: "20px" }]}>
                        {gI("oficialia") as string}
                    </Text>
                    <Text style={[sg.title, sg.tCenter, { marginVertical: "20px" }]}>
                        Expendiente: {gI("numero") as string}/{gI("anio") as string}
                    </Text>
                </View>
                <View>
                    <View style={[sg.row, { marginBottom: "10px" }]}>
                        <View style={[sg.w30]}>
                            <Text>Carpeta:</Text>
                        </View>
                        <View style={[sg.w70, sg.tBold]}>
                            <Text>{gI("carpeta") as string}</Text>
                        </View>
                    </View>
                    <View style={[sg.row, { marginBottom: "10px" }]}>
                        <View style={[sg.w30]}>
                            <Text>Demanda:</Text>
                        </View>
                        <View style={[sg.w70, sg.tBold]}>
                            <Text>{gI("demanda") as string}</Text>
                        </View>
                    </View>
                    <View style={[sg.row, { marginBottom: "10px" }]}>
                        <View style={[sg.w30]}>
                            <Text>Juicio:</Text>
                        </View>
                        <View style={[sg.w70, sg.tBold]}>
                            <Text>{gI("juicio") as string}</Text>
                        </View>
                    </View>
                    <View style={[sg.row, { marginBottom: "10px" }]}>
                        <View style={[sg.w30]}>
                            <Text>Promoventes:</Text>
                        </View>
                        <View style={[sg.w70, sg.tBold]}>
                            <View>
                            {
                                gI("promoventes") &&
                                (gI("promoventes") as PromoventeDto[]).length > 0 &&
                                (gI("promoventes") as PromoventeDto[]).map((p, ix) => (
                                    <Text key={"pr"+ix}>
                                        • {p.nombre_busqueda}
                                    </Text>
                                ))
                            }
                            </View>
                        </View>
                    </View>
                    <View style={[sg.row, { marginBottom: "10px" }]}>
                        <View style={[sg.w30]}>
                            <Text>Actor:</Text>
                        </View>
                        <View style={[sg.w70, sg.tBold]}>
                            <View>
                                {
                                    gI("actores") &&
                                    (gI("actores") as ParteDTO[]).length > 0 &&
                                    (gI("actores") as ParteDTO[]).map((p, ix) => (
                                        <Text key={"ac"+ix}>
                                            • {p.nombre_busqueda}
                                        </Text>
                                    ))
                                }
                            </View>
                        </View>
                    </View>
                    <View style={[sg.row, { marginBottom: "10px" }]}>
                        <View style={[sg.w30]}>
                            <Text>Demandado:</Text>
                        </View>
                        <View style={[sg.w70, sg.tBold]}>
                            <View>
                                {
                                    gI("demandados") &&
                                    (gI("demandados") as ParteDTO[]).length > 0 &&
                                    (gI("demandados") as ParteDTO[]).map((p, ix) => (
                                        <Text key={"de"+ix}>
                                            • {p.nombre_busqueda}
                                        </Text>
                                    ))
                                }
                            </View>
                        </View>
                    </View>
                    <View style={[sg.row, { marginBottom: "10px" }]}>
                        <View style={[sg.w30]}>
                            <Text>Descripción de los documetos:</Text>
                        </View>
                        <View style={[sg.w70, sg.tBold]}>
                            <Text>{gI("documentos") && <Text>{(gI("documentos") as TypeFileRef).observaciones}</Text>}</Text>
                        </View>
                    </View>
                    <View style={[sg.row, { marginBottom: "10px" }]}>
                        <View style={[sg.w30]}>
                            <Text>Iniciado en:</Text>
                        </View>
                        <View style={[sg.w70, sg.tBold]}>
                            <Text>{fDatetime(data?.createdAt + "")}</Text>
                        </View>
                    </View>
                </View>
			</Page>
        </Document>
    )
}

const fields = ["numero", "carpeta", "oficialia", "demanda", "anio", "juicio", "promoventes", "actores", "demandados", "documentos", "created"] as const;

type FieldType = (typeof fields)[number];