/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { swalAction } from "../../../components/modals/ModalConfirm";
import peticionBack from "../../../libs/peticiones";
import { Municipios } from "./dto/municipio.dto";
import { Button, Grid } from "@mantine/core";
import InputSearch from "../../../components/inputs/InputSelectSearch";
import { useDispatch, useSelector } from "react-redux";
import {
  loaded,
  loading,
  loadingData,
} from "../../../core/slicers/loading/LoadingSlicer";
import InputTextMan from "../../../components/inputs/inputTextMan";

const defaultForm: Municipios = {
  name: "",
  inegiKey: "",
  estado_id: "",
  distrito_id: "",
};

export default function Form({
  setOpen,
  selected,
}: {
  setOpen: any;
  selected?: any;
}) {
  const lData = useSelector(loadingData);
  const [estados, setEstados] = useState<Municipios[]>([]);
  const [distritos, setDistritos] = useState<Municipios[]>([]);
  const dispatch = useDispatch();

  const formSchema = Yup.object().shape({
    name: Yup.string().required("Ingrese el Municipio"),
    inegiKey: Yup.string().required("Ingrese la clave INEGI"),
    estado_id: Yup.string().required("Seleccione el Estado"),
    distrito_id: Yup.string().required("Seleccione el Distrito"),
  });

  const {
    handleSubmit,
    handleReset,
    handleBlur,
    setFieldValue,
    setValues,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: formSchema,
    onSubmit: (values) =>
      swalAction(
        save,
        `${selected ? "Editar" : "Guardar"} Municipio`,
        `¿Desea ${selected ? "editar" : "guardar"} este Municipio?`,
        "question"
      ),
    onReset: () => {
      setOpen(false);
    },
  });

  function onChange({ target }: { target: any }) {
    setFieldValue(target.name, target.value);
  }

  useEffect(() => {
    getEstados();
    getDistritos();
    if (selected) {
      setValues(
        {
          name: selected.name,
          inegiKey: selected.inegiKey,
          estado_id: selected.estado_id,
          distrito_id: selected.distrito_id,
        },
        true
      );
    }
  }, []);

  useEffect(() => {
  }, [lData]);

  async function getEstados() {
    dispatch(loading());
    const res = await peticionBack({}, "/estados", "GET", false);
    dispatch(loaded());
    if (res) setEstados(res.data);
  }
  async function getDistritos() {
    dispatch(loading());
    const res = await peticionBack({}, "/distrito", "GET", false);
    dispatch(loaded());
    if (res) setDistritos(res.data);
  }

  async function save() {
    const method = selected ? "PUT" : "POST";
    const rute = selected ? `/${selected._id}` : "";
    dispatch(loading());
    const res = await peticionBack(
      { ...values },
      "/municipios" + rute,
      method,
      true
    );
    if (res) {
      handleReset(null);
    }
    dispatch(loaded());
  }
  return (
    <form onSubmit={handleSubmit} onReset={handleReset}>
      <Grid justify="space-around" grow>
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <InputTextMan
            label="Nombre del Municipio"
            name="name"
            type="text"
            value={values.name}
            touched={touched.name}
            error={errors.name}
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            required
            rightSection
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <InputTextMan
            label="Clave INEGI"
            name="inegiKey"
            type="text"
            value={values.inegiKey}
            touched={touched.inegiKey}
            error={errors.inegiKey}
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            required
            rightSection
          />
        </Grid.Col>
      </Grid>

      <Grid grow justify="space-around">
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <InputSearch
            label="Estados"
            name="estado_id"
            value={values.estado_id}
            error={errors.estado_id}
            touched={touched.estado_id}
            values={estados}
            labelVal="name"
            valueVal="_id"
            onChangeMethod={({ target }: any) =>
              setFieldValue("estado_id", target.value)
            }
            required
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <InputSearch
            label="Distritos"
            name="distrito_id"
            value={values.distrito_id}
            error={errors.distrito_id}
            touched={touched.distrito_id}
            values={distritos}
            labelVal="name"
            valueVal="_id"
            onChangeMethod={({ target }: any) =>
              setFieldValue("distrito_id", target.value)
            }
            required
          />
        </Grid.Col>
      </Grid>

      <div className="d-grid gap-2 d-md-flex justify-content-md-around mt-2">
        <Button variant="filled" color="redColor" type="reset" className="mt-3">
          Cancelar
        </Button>
        <Button
          variant="filled"
          color="greenColor"
          type="submit"
          className="mt-2 mt-sm-3"
        >
          Guardar
        </Button>
      </div>
    </form>
  );
}
