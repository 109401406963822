import { Button, Flex } from "@mantine/core";
import { ButtonProps } from "./ButtonProps";

export default function FormButtons({ cancel, save, reverse }: { cancel?: ButtonProps, save?: ButtonProps, reverse?: boolean }){
    const row: any = 'row' + (reverse ? "-reverse" : "");
    return(
        <Flex
            direction={{ base: 'column-reverse', md: row }}
            gap={{ base: 'sm', sm: 'md' }}
            justify={{ base: "center", sm: 'space-around' }}
            className="my-3"
        >
            {
                cancel?.hidden !== true && 
                <Button variant={cancel?.variant} color={cancel?.color || "red"} type={cancel?.type || "reset"} onClick={()=>cancel?.click ? cancel.click() : {}}>
                    { cancel?.msg || "Cancelar" }
                </Button>
            }
            {
                save?.hidden !== true && 
                <Button variant={save?.variant} color={save?.color || "teal"} type={save?.type || "submit"} onClick={()=>save?.click ? save.click() : {}}>
                    { save?.msg || "Guardar" }
                </Button>
            }
        </Flex>
    )
}