/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Grid } from "@mantine/core";
import peticionBack from "../../libs/peticiones";
import createURL from "../../libs/url";
import { swalAction } from "../modals/ModalConfirm";
import Datatable from "../datatable/Datatable";
import InputSearch from "../inputs/InputSelectSearch";
import { SecretarioAdscripcion } from "../../pages/personal/secretarios/dto/secretario-adscripcion.dto";
import { JuzgadorAdscripcion } from "../../pages/personal/juzgadores/dto/juzgadores-adscripcione.dto";

export default function UnoAMuchos({
  id,
  endpointValues,
  objForm,
  selectValores,
  setOpen,
}: {
  id: string;
  endpointValues: any;
  objForm: any;
  objAditivos: any;
  selectValores: any[];
  setOpen?: any;
}) {
  //registros de l atabla
  const [relaciones, setRelaciones] = useState<any[]>([]); // tabla
  //resgitrso del select
  const [cleanAditivos, setCleanAditivos] = useState<any[]>([]); // inputSearch

  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    getRelaciones();
  }, [id]);

  async function getRelaciones() {
    const params: any = {
      pathname: endpointValues.ruta,
      searchParams: [
        { name: endpointValues.filtro, value: id as string },
        ...(endpointValues.enabled ? [{ name: "enabled", value: true }] : []),
        { name: "rows", value: endpointValues.rows },
        ...(endpointValues.populate
          ? [{ name: "populate", value: endpointValues.populate }]
          : []),
      ],
    };
    const url = createURL(params);
    const res = await peticionBack({}, url, "GET", false);
    if (res) {
      let mapAditivos: any = [];
      if (endpointValues.mapAditivos_id === "discapacidad_id") {
        mapAditivos = res.data; //si no esta populado
      }
      if (endpointValues.mapAditivos_id === "materia") {
        mapAditivos = res.data.map((p: any) => p.materia); //si esta populado
        setCleanAditivos(
          selectValores.filter(
            (obj1: any) =>
              !mapAditivos.some((obj2: any) => obj1._id === obj2._id)
          )
        );
      }
      //scretarios
      if (endpointValues.mapAditivos_id === "materia_adscripcion_id") {
        res.data.forEach((a: SecretarioAdscripcion) => {
          a.nameAdscripcion = selectValores.find(
            (e) => e._id === a.materia_adscripcion_id
          ) || {
            name: "Sin Resultados",
          };
        });
        mapAditivos = res.data;
        setCleanAditivos(
          selectValores.filter(
            (obj1: any) =>
              !mapAditivos.some(
                (obj2: any) => obj1._id === obj2.materia_adscripcion_id
              )
          )
        );
      }
      //juzgadores
      if (endpointValues.mapAditivos_id === "materia_adcripcion_idJ") {
        res.data.forEach((a: JuzgadorAdscripcion) => {
          a.nameAdscripcion = selectValores.find(
            (e) => e._id === a.materia_adscripcion_id
          ) || {
            name: "Sin Resultados",
          };
        });
        mapAditivos = res.data;
        setCleanAditivos(
          selectValores.filter(
            (obj1: any) =>
              !mapAditivos.some(
                (obj2: any) => obj1._id === obj2.materia_adscripcion_id
              )
          )
        );
      }
      if (endpointValues.mapAditivos_id === "materia_juicios") {
        mapAditivos = res.data.map((p: any) => p.materia); //si esta populado
        setCleanAditivos(
          selectValores.filter(
            (obj1: any) =>
              !mapAditivos.some((obj2: any) => obj1._id === obj2._id)
          )
        );
      }
      setRelaciones(res.data);
    }
    setRefresh(true);
  }

  /* TABLA */
  const columns = [
    { data: endpointValues.dataTabla },
    {
      data: null,
      render: (row: any) => (
        <div className="d-flex justify-content-evenly">
          <FontAwesomeIcon
            icon={faTrashCan}
            className="text-danger cursor-pointer"
            onClick={() =>
              swalAction(
                deleteRow,
                `Remover `,
                `¿Desea remover la Registro?`,
                "question",
                row._id,
                "Eliminar"
              )
            }
          />
        </div>
      ),
    },
  ];

  const columnDefs = [
    { orderable: false, targets: [1] },
    { className: "text-center", targets: [0] },
  ];

  async function deleteRow(a: string) {
    const res = await peticionBack(
      {},
      `${endpointValues.ruta}/${encodeURIComponent(a)}`,
      "DELETE",
      true
    );
    if (res) getRelaciones();
  }

  const formSchema = Yup.object().shape({
    aditivo_id: Yup.string().required("Seleccione un registro"),
  });

  const { handleSubmit, handleReset, setFieldValue, values, errors, touched } =
    useFormik({
      initialValues: { aditivo_id: "" },
      validationSchema: formSchema,
      onSubmit: (values) => {
        swalAction(save, `Agregar`, `¿Desea asignar la relación ?`, "question");
      },
      onReset: () => {},
    });

  async function save() {
    const params = {
      [objForm.aditivo_id]: values.aditivo_id,
      [objForm.base_id]: id,
    };
    const res = await peticionBack(
      params,
      `${endpointValues.ruta}`,
      "POST",
      true
    );
    if (res) {
      handleReset(null);
      getRelaciones();
    }
  }

  return (
    <>
      <div className="d-flex flex-column flex-md-row justify-content-md-between mb-2">
        <h4 className="">{objForm.title}</h4>
        <Button
          variant="filled"
          color="redColor"
          onClick={() => setOpen(false)}
          className="mt-3"
        >
          Salir
        </Button>
      </div>
      <div>
        <form onSubmit={handleSubmit} onReset={handleReset}>
          <Grid justify="space-between" grow>
            <Grid.Col span={{ xs: 3 }}>
              <InputSearch
                label="Opciones"
                name="aditivo_id"
                value={values.aditivo_id}
                error={errors.aditivo_id}
                touched={touched.aditivo_id}
                values={cleanAditivos}
                labelVal="name"
                valueVal="_id"
                onChangeMethod={({ target }: any) =>
                  setFieldValue("aditivo_id", target.value)
                }
              />
            </Grid.Col>
            <Grid.Col style={{ marginTop: "6px" }} span={{ base: 12, md: 6 }}>
              <Button
                variant="light"
                color="teal"
                type="submit"
                className="mb-3 mt-3"
              >
                Añadir
              </Button>
            </Grid.Col>
          </Grid>
        </form>
        <Datatable
          columns={columns}
          headers={["Relación", "Eliminar"]}
          columnDefs={columnDefs}
          data={relaciones}
          control="front"
          stateRefresh={[refresh, setRefresh]}
          className="p-0"
        />
      </div>
    </>
  );
}
