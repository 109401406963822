import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import Form from "./Form";
import CardMan from "../../../components/card/Card";
import Datatable from "../../../components/datatable/Datatable";
import peticionBack from "../../../libs/peticiones";
import { swalAction } from "../../../components/modals/ModalConfirm";
import createURL from "../../../libs/url";
import { Usuario } from "./dto/usuario.dt";
import { checkPermission } from "../../../libs/permisosModulo";
import { useSelector } from "react-redux";
import { moduleData } from "../../../core/slicers/permisos/permissionsSlicer";

export default function Usuarios() {
  const [refresh, setRefresh] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<Usuario | undefined>();
  const modulos = useSelector(moduleData);

  async function getData(
    order: string,
    records: number,
    page: number,
    search: string
  ) {
    const params: any = {
      pathname: `/usuarios`,
      searchParams: [
        { name: "order", value: order },
        { name: "records", value: records },
        { name: "page", value: page },
        { name: "search", value: search },
        { name: "populate", value: "system_id" },
        { name: "rows", value: "name,username,email,phone,system_id,_id" },
      ],
    };
    const url = createURL(params);
    const res = await peticionBack({}, url, "GET", false);
    return res;
  }

  /* TABLA */
  const columns = [
    { data: "name" },
    { data: "username" },
    {
      data: "email",
      render: (d: string) => (
        <a href={`mailto:${d}`} type="e">
          {d}
        </a>
      ),
    },
    {
      data: "phone",
      render: (d: string) => (
        <a href={`tel:${d}p000`} type="e">
          {d}
        </a>
      ),
    },
    { data: "sistema.name" },
    {
      data: null,
      render: (row: Usuario) => (
        <div className="d-flex justify-content-evenly">
           {checkPermission(modulos, "u") && (
          <FontAwesomeIcon
            icon={faPencil}
            className="text-warning cursor-pointer"
            onClick={() => openToEdit(row)}
          />)}
           {checkPermission(modulos, "d") && (
          <FontAwesomeIcon
            icon={faTrashCan}
            className="text-danger cursor-pointer"
            onClick={() =>
              swalAction(
                deleteRow,
                "Eliminar usuario",
                `¿Desea eliminar el usuario ${row.name}?`,
                "question",
                row._id,
                "Eliminar"
              )
            }
          />)}
        </div>
      ),
    },
  ];

  const columnDefs = [
    { orderable: false, targets: [5] },
    { className: "text-center", targets: [0, 1, 2, 3] },
  ];

  async function deleteRow(id: string) {
    const res = await peticionBack(
      {},
      `/usuarios/${encodeURIComponent(id)}`,
      "DELETE",
      true
    );
    if (res) setRefresh(true);
  }

  function openToEdit(row: Usuario) {
    if (row.phone.startsWith("+52")) {
      row.phone = row.phone.substring(3);
    }
    setSelected(row);
  }

  useEffect(() => {
    if (selected) setOpen(true);
  }, [selected]);

  useEffect(() => {
    if (!open) {
      setSelected(undefined);
    }
  }, [open]);

  return (
    <CardMan title="Usuarios" showMenu={false}>
      <div className="d-flex flex-column flex-md-row justify-content-md-between mb-2">
        <h4 className="">
          Usuarios {open && (selected ? "- Editar" : "- Agregar")}
        </h4>
        {!open && checkPermission(modulos, "c") && (
          <Button onClick={() => setOpen(true)} className="my-1 my-md-0">
            Agregar
          </Button>
        )}
      </div>
      {open ? (
        <Form setOpen={setOpen} selected={selected} />
      ) : ( checkPermission(modulos, "r") && (
        <Datatable
          columns={columns}
          headers={[
            "Nombre",
            "Usuario",
            "Correo Electrónico",
            "Teléfono",
            "Sistema",
            "Acciones",
          ]}
          columnDefs={columnDefs}
          petition={getData}
          control="back"
          stateRefresh={[refresh, setRefresh]}
          className="p-0"
        />
      )
      )}
    </CardMan>
  );
}
