/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Flex, Grid, Image, List, ListItem, Space, Text } from "@mantine/core";
import { OficialiaSelected, Preregistro } from "../../dto/preregistro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loaded, loading } from "../../../../core/slicers/loading/LoadingSlicer";
import { getDocumentos, getJuicio, getPartes, getPromoventes } from "./consultas";

import Alert from "../../../../components/notification/Alert";
import { PromoventeDto } from "../demanda/dto/promovente-dto";
import { datosGenerales as ParteDTO } from "../actores-demandados/datos-generales/dto/general.dto";
import { TypeFileRef, TypeFileSource } from "../../../../core/types/TypeFile";
import peticionBack, { uploadFiles } from "../../../../libs/peticiones";
import { Tipos_partes } from "../../../../core/enums/enums";
import { fDatetime } from "../../../../libs/formats";
import { Actuacion } from "../../dto/actuacion.dto";
import { saveAs } from "file-saver";
import getReportPDF from "../../../../components/reports/pdf/getReports";
import { DefaultMongoObjectDto } from "../../../../core/dto/default-mongo-object.dto";

export default function Envio({ selected, setSelected, typDoc, oficialia, activeTab, acuse }: { selected: Preregistro | null; setSelected: Function; typDoc: string; oficialia: OficialiaSelected; activeTab: string | null; acuse: string }) {
    const [cleanData, setCleanData] = useState<Preregistro | null>(null);
    const [fileAcuse, setFileAcuse] = useState<string | null>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (activeTab === "Envio" && !!selected) getAllData();
    }, [activeTab]);

    async function getAllData() {
        dispatch(loading("backdrop"));
        if (selected?.enviado) {
            getDocumentos(true, selected?.actuaciones[0]._id as string, acuse).then((res) => {
                if (res !== null && typeof res !== "boolean") {
                    peticionBack({}, `/archivos/${res._id}`, "GET", false).then((res2: DefaultMongoObjectDto & {files: TypeFileSource[]}) => {
                        if(res2 && res2.files && res2.files.length > 0){
                            console.log(res2.files.at(-1))
                            setFileAcuse(res2.files.at(-1)?.src || "");
                        }
                    });
                }
            });
        }
        const [promoventes, actores, demandados, documentos, juicio] = await Promise.all([
            getPromoventes(selected?.actuaciones[0]._id as string),
            getPartes(selected?._id as string, Tipos_partes.ACTOR),
            getPartes(selected?._id as string, Tipos_partes.DEMANDADO),
            getDocumentos(!!selected?.actuaciones[0].con_documentacion, selected?.actuaciones[0]._id as string, typDoc),
            getJuicio(selected?.carpetas_juicios[0].juicio_materia_id as string),
        ]);
        dispatch(loaded());
        if (promoventes && actores && demandados && documentos && juicio) {
            setCleanData({ ...(selected as Preregistro), promoventes, actores, demandados, documentos, juicio, oficialia });
        } else {
            Alert("error", "No se ha reicibido toda la información necesaria");
        }
    }

    function gI(key: FieldType) {
        switch (key) {
            case "numero":
                return cleanData?.numero || "_";
            case "anio":
                return cleanData?.anio || "_";
            case "carpeta":
                return cleanData?.tipo_carpetas?.name || "_";
            case "oficialia":
                return cleanData?.oficialia.label || "_";
            case "demanda":
                return cleanData?.tipo_demanda || "";
            case "juicio":
                return cleanData?.juicio?.name || "_";
            case "promoventes":
                return cleanData?.promoventes || null;
            case "actores":
                return cleanData?.actores || null;
            case "demandados":
                return cleanData?.demandados || null;
            case "documentos":
                return cleanData?.documentos || null;
            case "created":
                return cleanData?.createdAt || "_";
            default:
                return "Dato desconocido";
        }
    }

    async function save() {
        dispatch(loading("backdrop"));
        const res = await peticionBack({ carpeta_judicial_id: selected?._id }, "/registro", "POST", true);
        if (res) {
            setSelected((ps: Preregistro) => ({
                ...ps,
                actuaciones: res.actuaciones,
                anio: res.anio,
                enviado: res.enviado,
                numero: res.numero,
            }));
            setCleanData((cl) => ({
                ...cl as Preregistro,
                actuaciones: res.actuaciones,
                anio: res.anio,
                enviado: res.enviado,
                numero: res.numero,
            }));
            await createAcuse(res.actuaciones[0], res.numero, res.anio);
        }
        dispatch(loaded());
    }

    async function createAcuse(actuacion: Actuacion, numero: number, anio: number) {
        const report = await getReportPDF("acuse demanda", cleanData);
        if (report) {
            const fileProps = {
                ref: actuacion._id || "",
                type: acuse,
                notes: `Acuse de demanda ${numero}/${anio}`,
                files: [new File([report.blob], `Acuse de demanda ${numero}_${anio}.pdf`, { type: "application/pdf" })],
            };
            const res = await uploadFiles({ endpoint: "", files: fileProps, show: false });
            if (res) {
                setFileAcuse(report.url);
                return report.url;
            }
        }
        return null;
    }

    async function printAcuse() {
        dispatch(loading("backdrop"));
        let linkPdf = fileAcuse;
        if (!linkPdf) {
            linkPdf = await createAcuse(cleanData?.actuaciones[0] as Actuacion, cleanData?.numero as number, cleanData?.anio as number);
        }
        dispatch(loaded());
        if(linkPdf){
            try {
                const numac = `${cleanData?.numero}/${cleanData?.anio}`;
                saveAs(linkPdf as string, `Acuse demanda ${numac}.pdf`);
                return;
            } catch (error) {
                console.error(error);
            }
        }
        Alert("error", "No fue posible generar el acuse");
    }

    return (
        <section className="p-3">
            <div className="text-center">
                <Text size="xl" fw={700} tt="uppercase">
                    Poder Judicial Del Estado De México
                </Text>
                <Image h={250} w="auto" fit="initial" src="/image/edomex-logo.png" className="mx-auto" />
                <Text size="xl" tt="uppercase" className="mt-1">
                    {gI("oficialia") as string}
                </Text>
                <Space h="md" />
                <Text size="xl" tt="uppercase" fw={500} className="mt-1">
                    Expendiente: {gI("numero") as string}/{gI("anio") as string}
                </Text>
            </div>
            <Flex gap="xl" direction="column" mt={25}>
                <Grid>
                    <Grid.Col span={3}>
                        <Text tt="uppercase">Carpeta: </Text>
                    </Grid.Col>
                    <Grid.Col span={8} offset={1}>
                        <Text fw={500} tt="uppercase">
                            {gI("carpeta") as string}
                        </Text>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col span={3}>
                        <Text tt="uppercase">Demanda: </Text>
                    </Grid.Col>
                    <Grid.Col span={8} offset={1}>
                        <Text fw={500} tt="uppercase">
                            {gI("demanda") as string}
                        </Text>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col span={3}>
                        <Text tt="uppercase">Juicio: </Text>
                    </Grid.Col>
                    <Grid.Col span={8} offset={1}>
                        <Text fw={500} tt="uppercase">
                            {gI("juicio") as string}
                        </Text>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col span={3}>
                        <Text tt="uppercase">Promoventes: </Text>
                    </Grid.Col>
                    <Grid.Col span={8} offset={1}>
                        <List>
                            {gI("promoventes") &&
                                (gI("promoventes") as PromoventeDto[]).length > 0 &&
                                (gI("promoventes") as PromoventeDto[]).map((p, ix) => (
                                    <ListItem fw={500} tt="uppercase" key={"pr" + ix}>
                                        {p.nombre_busqueda}
                                    </ListItem>
                                ))}
                        </List>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col span={3}>
                        <Text tt="uppercase">Actor: </Text>
                    </Grid.Col>
                    <Grid.Col span={8} offset={1}>
                        <List>
                            {gI("actores") &&
                                (gI("actores") as ParteDTO[]).length > 0 &&
                                (gI("actores") as ParteDTO[]).map((p, ix) => (
                                    <ListItem fw={500} tt="uppercase" key={"ac" + ix}>
                                        {p.nombre_busqueda}
                                    </ListItem>
                                ))}
                        </List>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col span={3}>
                        <Text tt="uppercase">Demandado: </Text>
                    </Grid.Col>
                    <Grid.Col span={8} offset={1}>
                        <List>
                            {gI("demandados") &&
                                (gI("demandados") as ParteDTO[]).length > 0 &&
                                (gI("demandados") as ParteDTO[]).map((p, ix) => (
                                    <ListItem fw={500} tt="uppercase" key={"de" + ix}>
                                        {p.nombre_busqueda}
                                    </ListItem>
                                ))}
                        </List>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col span={3}>
                        <Text tt="uppercase">Descripción de los documetos: </Text>
                    </Grid.Col>
                    <Grid.Col span={8} offset={1}>
                        <Text fw={500} tt="uppercase">
                            {gI("documentos") && (gI("documentos") as TypeFileRef).observaciones}
                        </Text>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col span={3}>
                        <Text tt="uppercase">Iniciado en: </Text>
                    </Grid.Col>
                    <Grid.Col span={8} offset={1}>
                        <Text fw={500} tt="uppercase">
                            {selected && fDatetime(selected?.createdAt || "")}
                        </Text>
                    </Grid.Col>
                </Grid>
            </Flex>
            <div className="text-center mt-2">
                {selected?.enviado ? (
                    <Button color="primary" mt={20} onClick={() => printAcuse()}>
                        <FontAwesomeIcon icon={faPrint} className="me-1" />
                        Imprimir Acuse
                    </Button>
                ) : (
                    <Button color="primary" mt={20} onClick={() => save()}>
                        <FontAwesomeIcon icon={faPaperPlane} className="me-1" />
                        Enviar
                    </Button>
                )}
            </div>
        </section>
    );
}

const fields = ["numero", "carpeta", "oficialia", "demanda", "anio", "juicio", "promoventes", "actores", "demandados", "documentos", "created"] as const;

type FieldType = (typeof fields)[number];
