import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../components/redux/store";
import { profilesState } from "./interfaces/perfilInterface";
import { ProfileStates } from "./states";

const initialState: profilesState = {}

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    loadProfiles: (state, action: PayloadAction<profilesState>) => {
      if (action.payload.profiles && action.payload.profiles.length > 0) {
        state.profiles = action.payload.profiles
        state.selected = action.payload.profiles[0].profile_id
        state.state = ProfileStates.PROFILE_LOADED
      }
    },
    changeProfile: (state, action: PayloadAction<string>) => {
      state.selected = action.payload
    }
  },

});
export const { loadProfiles, changeProfile } = profileSlice.actions
export default profileSlice.reducer

export const profileData = (state: RootState) => state.profile