import { notifications } from "@mantine/notifications";
import {
  IconX,
  IconCheck,
  IconInfoCircle,
  IconAlertCircleFilled,
} from "@tabler/icons-react";
import { rem } from "@mantine/core";

const xIcon = <IconX style={{ width: rem(20), height: rem(20) }} />;
const checkIcon = <IconCheck style={{ width: rem(20), height: rem(20) }} />;
const infoIcon = <IconInfoCircle style={{ width: rem(20), height: rem(20) }} />;
const warningIcon = (
  <IconAlertCircleFilled style={{ width: rem(20), height: rem(20) }} />
);

const diccionario = {
  success: { title: "Éxito", color: "green", icon: checkIcon },
  info: { title: "Información", color: "blue", icon: infoIcon },
  warning: { title: "Precaución", color: "yellow", icon: warningIcon },
  error: { title: "Error", color: "red", icon: xIcon },
};

export default function Alert(
  type: "info" | "success" | "warning" | "error",
  message: string
) {
  notifications.show({
    withCloseButton: true,
    autoClose: 4000,
    title: diccionario[type].title,
    message: message,
    color: diccionario[type].color,
    icon: diccionario[type].icon,
  });
}
