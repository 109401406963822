/* eslint-disable react-hooks/exhaustive-deps */
import { faEye, faSave, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Button, Checkbox, Collapse, Flex, Grid, Input, InputLabel, NumberInput, Select, Text, Textarea } from "@mantine/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loaded, loading } from "../../../../core/slicers/loading/LoadingSlicer";
import peticionBack from "../../../../libs/peticiones";
import FPromoventes from "./FPromoventes";
import ModalFileUpload from "../../../../components/modals/ModalFileUpload";
import { IMAGE_MIME_TYPE, PDF_MIME_TYPE } from "@mantine/dropzone";
import { OficialiaSelected, Preregistro } from "../../dto/preregistro";
import { swalAction } from "../../../../components/modals/ModalConfirm";
import ModalDatatable from "../../../../components/modals/ModalDatatable";
import { CarpetaJudicial } from "../../dto/carpeta-judicial.dto";
import { tiposActuacionArray } from "../../../../libs/dataArrays";
import { Tipos_actuacion } from "../../../../core/enums/enums";
import { FileTypeIcon } from "../../../../components/files/FileTypeIcon";
import ModalFileViewer from "../../../../components/modals/ModalFileViewer";
import { TypeFileRef, TypeFileSource } from "../../../../core/types/TypeFile";

export default function Demanda({ selected, setSelected, oficialia, setComplete, typDoc }: { selected: Preregistro | null; setSelected: Function; oficialia: OficialiaSelected; setComplete: Function; typDoc: string }) {
    const [carpetas, setCarpetas] = useState<any[]>([]);
    const [juicios, setJuicios] = useState<any[]>([]);
    const [modalFind, setModalFind] = useState<boolean>(false);
    const [defCarpeta, setDefCarpeta] = useState<string | null>(null);
    const dispatch = useDispatch();

    const propstabla = {
        columns: [
            {
                data: null,
                render: (row: CarpetaJudicial) => <p>{row.numero && `${row.numero}/${row.anio}`}</p>,
                orderValue: "numero anio",
            },
            { data: "sintesis" },
            {
                data: null,
                orderValue: "enviado",
                render: (row: CarpetaJudicial) => <Badge color={row.enviado ? "teal" : "gray"}>{row.enviado ? "SI" : "NO"}</Badge>,
            },
        ],
        headers: ["No.Expediente", "sintesis", "Enviada"],
        columnDefs: [{ className: "text-center", targets: [0, 2] }],
        control: "back" as "back",
        onClick: true,
        onSelect: selectModalRow,
        endpoint: "/preregistro-inicial",
        order: { col: 1, opt: "asc" as "asc" },
    };

    /* INITIAL */
    useEffect(() => {
        getJuicios();
        getCarpetas();
    }, []);

    async function getCarpetas() {
        dispatch(loading());
        const res = await peticionBack({}, "/tipos-carpetas?order=name%20ASC", "GET", false);
        if (res) {
            setCarpetas(res.data);
            const expediente = res.data.find((c: any) => c.name === "EXPEDIENTE");
            if (expediente) {
                setFieldValue("tipo_carpetas_id", expediente._id);
                setDefCarpeta(expediente._id);
            }
        }
        dispatch(loaded());
    }

    async function getJuicios() {
        dispatch(loading());
        const res = await peticionBack({}, "/juicios-materias?populate=juicio_id%2Cmateria_id&materia_id=" + oficialia.materias.join(" "), "GET", false);
        if (res)
            setJuicios(
                res.data.map((jm: any) => ({
                    id: jm._id,
                    name: `${jm.materia.name} - ${jm.juicio.name}`,
                }))
            );
        dispatch(loaded());
    }

    /* END INITIAL */

    /* FORM */
    const { handleSubmit, handleReset, handleBlur, setFieldValue, setFieldTouched, setValues, values, errors, touched } = useFormik({
        initialValues: {
            ...defaultForm,
            tipo_carpetas_id: defCarpeta,
        },
        validationSchema: formSchema,
        onSubmit: (_values) => {
            swalAction(save, `${selected ? "Editar" : "Guardar"} Demanda`, `¿Desea ${selected ? "editar" : "guardar"} el pre registro de la demanda?`, "question");
        },
        onReset: () => {
            if (!!selected) setSelected(null);
        },
        enableReinitialize: true,
    });

    function onChange({ target }: { target: any }) {
        if (target.name === "con_documentacion") {
            setFieldValue("con_documentacion", target.checked);
            if (!target.checked) setFieldValue("descripcionDocumentacion", "");
        } else setFieldValue(target.name, target.value);
    }

    async function save() {
        dispatch(loading("backdrop"));
        let params: any = { ...values, oficialias_adscripciones_id: oficialia.value };
        let route = "/preregistro-inicial";
        let method = "POST";
        if (values._id) {
            params = {
                ...params,
                oficialias_adscripciones_id: oficialia.value,
                carpeta_judicial_id: values._id,
                caso_juicio_id: selected?.carpetas_juicios[0]._id,
                actuaciones_id: selected?.actuaciones[0]._id,
                carpeta_juicio_id: selected?.carpetas_juicios[0]._id,
            };
            method = "PUT";
            delete params._id;
        }
        if (params.descripcionDocumentacion) delete params.descripcionDocumentacion;
        const res = await peticionBack(params, route, method, true);
        if (res) {
            setSelected(res);
            setFieldValue("id", res._id);
        }
        dispatch(loaded());
    }
    /* END FORM */

    async function selectModalRow(row: CarpetaJudicial) {
        dispatch(loading("backdrop"));
        const res: Preregistro = await peticionBack({}, "/preregistro-inicial/" + row._id, "GET", false);
        if (res) {
            setSelected(res);
            setValues({
                _id: row._id,
                tipo_carpetas_id: row.tipo_carpetas_id as string,
                tipo_demanda: row.tipo_demanda as string,
                juicio_materia_id: res.carpetas_juicios[0].juicio_materia_id || "",
                numero_fojas: res.actuaciones[0].numero_fojas as number,
                sintesis: res.sintesis as string,
                con_documentacion: res.actuaciones[0].con_documentacion || false,
                // idModalidad: "",
            });
            setComplete(!!row.enviado);
        }
        dispatch(loaded());
    }

    return (
        <section className="m-3">
            <form id="mainForm" onSubmit={handleSubmit} onReset={handleReset}>
                <LocalSelect
                    label="Tipo de Carpeta:"
                    name="tipo_carpetas_id"
                    data={carpetas}
                    value={values.tipo_carpetas_id}
                    // onChange={onChange}
                    // handleBlur={setFieldTouched}
                    error={errors.tipo_carpetas_id}
                    touched={touched.tipo_carpetas_id}
                    keys={["id", "name"]}
                    disabled
                />
                <LocalSelect label="Tipo de Demanda:" name="tipo_demanda" data={tiposActuacionArray} value={values.tipo_demanda} onChange={onChange} handleBlur={setFieldTouched} error={errors.tipo_demanda} touched={touched.tipo_demanda} disabled={selected?.enviado} />
                <LocalSelect label="Tipo de Juicio:" name="juicio_materia_id" data={juicios} value={values.juicio_materia_id} onChange={onChange} handleBlur={setFieldTouched} error={errors.juicio_materia_id} touched={touched.juicio_materia_id} keys={["id", "name"]} disabled={selected?.enviado} />
                <LocalInput label="Núm. Hojas:" name="numero_fojas" value={values.numero_fojas} onChange={onChange} handleBlur={handleBlur} error={errors.numero_fojas} touched={touched.numero_fojas} type="number" disabled={selected?.enviado} />
                <LocalInput label="Sintesis:" name="sintesis" value={values.sintesis} onChange={onChange} handleBlur={handleBlur} error={errors.sintesis} touched={touched.sintesis} type="area" disabled={selected?.enviado} />

                <Collapse in={!!selected}>
                    <FPromoventes actuacion={selected?.actuaciones[0]._id as string} canEdit={!selected?.enviado} />
                </Collapse>

                <Grid gutter={{ base: 1, md: "xl" }} className="mt-3 mb-2">
                    <Grid.Col span={{ base: 12, md: 2 }}>
                        <InputLabel>Con Documentación: </InputLabel>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 10 }}>
                        <Checkbox name="con_documentacion" checked={values.con_documentacion} onChange={onChange} disabled={selected?.enviado} />
                    </Grid.Col>
                </Grid>

                <Collapse in={values.con_documentacion && !!values._id}>
                    <LocalInput
                        label="Descripciones:"
                        name="descripcionDocumentacion"
                        value={values.descripcionDocumentacion}
                        onChange={onChange}
                        handleBlur={handleBlur}
                        error={errors.descripcionDocumentacion}
                        touched={touched.descripcionDocumentacion}
                        type="area"
                        required={values.con_documentacion}
                        disabled={!values.con_documentacion || selected?.enviado}
                    />
                </Collapse>
            </form>
            <Flex className="mt-4" direction={{ base: "column", sm: "row" }} gap={{ base: "sm", sm: "lg" }} justify={{ base: "center", sm: "left" }}>
                <Button color="teal" type="submit" form="mainForm">
                    <FontAwesomeIcon icon={faSave} className="me-1" />
                    {!!selected ? "Editar" : "Guardar"}
                </Button>
                {!!selected && (
                    <>
                        <ButtonVisor refer={selected?.actuaciones[0]._id || ""} type={typDoc} />
                        <ModalFileUpload
                            accept={[PDF_MIME_TYPE, ...IMAGE_MIME_TYPE]}
                            button={{
                                color: "violet",
                                msg: "Digitalizar",
                                disabled: !values.con_documentacion || selected?.enviado,
                            }}
                            fileProps={{
                                ref: selected?.actuaciones[0]._id || "",
                                type: typDoc,
                                notes: values.descripcionDocumentacion || "",
                            }}
                        />
                    </>
                )}
                <Button onClick={() => setModalFind(true)}>
                    <FontAwesomeIcon icon={faSearch} className="me-1" />
                    Consultar
                </Button>
                <Button color="red" type="reset" form="mainForm">
                    <FontAwesomeIcon icon={faTrashAlt} className="me-1" />
                    Limpiar
                </Button>
            </Flex>

            <ModalDatatable state={[modalFind, setModalFind]} tableProps={propstabla} title="Preregistros demandas" oficialia={oficialia.value} />
        </section>
    );
}

function ButtonVisor({ refer, type }: { refer: string; type: string }) {
    const [modalTableFiles, setModalTableFiles] = useState<boolean>(false);
    const [modalFile, setModalFile] = useState<boolean>(false);
    const [FileData, setFileData] = useState<string[] | null>(null);
    const [data, setData] = useState<TypeFileSource[]>([]);
    const dispatch = useDispatch();
    const propstabla = {
        columns: [
            {
                data: "name",
                render: (d: string) => (
                    <Flex direction="row">
                        <FileTypeIcon name={d} />
                        <Text size="lg">{d}</Text>
                    </Flex>
                ),
            },
        ],
        headers: ["Documento"],
        columnDefs: [{ className: "text-start", targets: [0] }],
        control: "front" as "front",
        onClick: true,
        onSelect: selectModalRow,
        data: data,
        autoClose: false,
    };

    async function getData() {
        const res: TypeFileRef = await peticionBack({}, `/archivos/${refer}/${type}`, "GET", false);
        if (res && res.files && res.files.length > 0) {
            const res2: any = await peticionBack({}, `/archivos/${res._id}`, "GET", false);
            if (res2) {
                setData(
                    res.files.map((f1: TypeFileSource) => ({
                        ...f1,
                        name: f1.src.split("/").at(-1),
                        src: res2.files.find((f2: TypeFileSource) => f2._id === f1._id).src || "",
                        type: f1.src.split("/").at(-1)?.split(".").at(-1)
                    }))
                );
                return;
            }
        }
        setData([]);
    }

    function selectModalRow(row: any) {
        setFileData([row.type, row.src]);
    }

    async function openModal() {
        dispatch(loading("backdrop"));
        await getData();
        dispatch(loaded());
        setModalTableFiles(true);
    }

    useEffect(() => {
        if (FileData) setModalFile(true);
    }, [FileData]);

    useEffect(() => {
        if (!modalFile) setFileData(null);
    }, [modalFile]);

    return (
        <>
            <Button color="cyan" onClick={() => openModal()}>
                <FontAwesomeIcon icon={faEye} className="me-1" />
                Visor
            </Button>

            <ModalDatatable state={[modalTableFiles, setModalTableFiles]} tableProps={propstabla} title="Documentos" />

            <ModalFileViewer state={[modalFile, setModalFile]} file={FileData} />
        </>
    );
}

function LocalSelect({
    label,
    name,
    data,
    value,
    onChange,
    handleBlur,
    error,
    touched,
    keys,
    disabled,
    defaultValue,
}: {
    label: string;
    name: string;
    data: any[];
    value?: string | null;
    onChange?: any;
    handleBlur?: any;
    error?: string;
    touched?: boolean;
    keys?: string[];
    disabled?: boolean;
    defaultValue?: string | null;
}) {
    const [formatData, setFormatData] = useState<Array<any>>([]);
    useEffect(() => {
        const k = keys || ["value", "label"];
        setFormatData(data.map((d) => ({ value: d[k[0]], label: d[k[1]] })));
    }, [data]);

    return (
        <Grid gutter={{ base: 1, md: "xl" }} className="mb-2">
            <Grid.Col span={{ base: 12, md: 2 }}>
                <Input.Label htmlFor={name} required>
                    {label}
                </Input.Label>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 10 }}>
                <Select
                    name={name}
                    data={formatData}
                    onChange={(value) => onChange({ target: { value, name } })}
                    onOptionSubmit={() => handleBlur(name, true)}
                    value={value !== "" ? value : null}
                    error={!!error && touched}
                    searchable
                    clearable
                    allowDeselect={false}
                    disabled={disabled}
                    defaultValue={defaultValue || null}
                />
                {touched && <Input.Error>{error}</Input.Error>}
            </Grid.Col>
        </Grid>
    );
}

function LocalInput({ label, name, value, onChange, handleBlur, error, touched, type, required, disabled }: { label: string; name: string; value?: string | number; onChange: any; handleBlur: any; error?: string; touched?: boolean; type?: string; required?: boolean; disabled?: boolean }) {
    return (
        <Grid gutter={{ base: 1, md: "xl" }} className="mb-2">
            <Grid.Col span={{ base: 12, md: 2 }}>
                <Input.Label htmlFor={name} required={required !== undefined ? required : true}>
                    {label}
                </Input.Label>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 10 }}>
                {type === "area" ? (
                    <Textarea id={name} name={name} onChange={onChange} value={value} onBlur={handleBlur} error={error && touched} disabled={disabled} />
                ) : type === "number" ? (
                    <NumberInput id={name} name={name} onChange={(value) => onChange({ target: { value, name } })} value={value} onBlur={handleBlur} error={error && touched} min={0} disabled={disabled} />
                ) : (
                    <Input id={name} name={name} onChange={onChange} value={value} onBlur={handleBlur} error={error && touched} disabled={disabled} />
                )}
                {touched && <Input.Error>{error}</Input.Error>}
            </Grid.Col>
        </Grid>
    );
}

interface FormPreregistro {
    _id?: string;
    tipo_carpetas_id: string;
    tipo_demanda: string;
    juicio_materia_id: string;
    numero_fojas: number;
    sintesis: string;
    con_documentacion: boolean;
    descripcionDocumentacion?: string;

    carpeta_judicial_id?: string;
    caso_juicio_id?: string;
    actuaciones_id?: string;
}

const defaultForm: FormPreregistro = {
    tipo_carpetas_id: "",
    tipo_demanda: "",
    juicio_materia_id: "",
    numero_fojas: 0,
    sintesis: "",
    con_documentacion: false,
    descripcionDocumentacion: "",
};

const formSchema = Yup.object().shape({
    tipo_carpetas_id: Yup.string().required("Seleccione un tipo de carpeta"),
    tipo_demanda: Yup.string().required("Seleccione un tipo de demanda").oneOf(Object.keys(Tipos_actuacion), "La opcion no corresponde a 'INDIVIDUAL' o 'COLECTIVA'"),
    juicio_materia_id: Yup.string().required("Seleccione un tipo de juicio"),
    // idModalidad: Yup.string().required("Seleccione una modalidad"),
    // materia: Yup.string().required("Seleccione una materia"),
    numero_fojas: Yup.number().required("Ingrese un número de hojas").min(0, "Valor invalido"),
    sintesis: Yup.string().required("Ingrese una nota"),
});
