import { faHomeAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Inicio() {
  return (
    <div className="text-center w-100">
      <div className="my-2">
        <p className="display-1 fw-bold">BIENVENIDO</p>
        <p>Sistema electrónico</p>
      </div>
      <div className="text-center">
        <FontAwesomeIcon icon={faHomeAlt} className="pe-2" />
      </div>
    </div>
  );
}
