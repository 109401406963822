import { faHomeAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export default function NotFound(){
    return(
        <div className="text-center align-self-center w-100">
            <div className="my-2">
                  <div className="stage">
                  <div className="box bounce-6">
                        <p className="display-1 fw-bold text-danger">
                            404
                        </p>
                    </div>

                  </div>
                <p>Página no encontrada</p>
            </div>
            <div className="text-center">
                <Link to="/" relative="path" className="btn btn-primary">
                    <FontAwesomeIcon icon={faHomeAlt} className="pe-2"/>
                    Inicio
                </Link>
            </div>
        </div>
    )
}