import { Grid } from "@mantine/core";
import InputSearch from "../../../../components/inputs/InputSelectSearch";

export default function SelectedRepresentante({
  representantes,
  selectedRepresentante,
  setSelectedRepresentante,
}: {
  representantes: any;
  selectedRepresentante: any;
  setSelectedRepresentante: any;
}) {
  return (
    <>
      <section>
        <Grid justify="space-around" grow>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <InputSearch
              label="Representante legal"
              name="representantes_legales_id"
              values={representantes}
              value={selectedRepresentante}
              labelVal="nombre_busqueda"
              valueVal="_id"
              onChangeMethod={({ target }: any) =>
                setSelectedRepresentante(target.value)
              }
              required
            />
          </Grid.Col>
        </Grid>
      </section>
    </>
  );
}
