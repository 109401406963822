/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Form from "./Form";
import createURL from "../../../libs/url";
import peticionBack from "../../../libs/peticiones";
import { swalAction } from "../../../components/modals/ModalConfirm";
import CardMan from "../../../components/card/Card";
import { Button } from "@mantine/core";
import Datatable from "../../../components/datatable/Datatable";
import { colorRed } from "../../../core/constants";
import { JuiciosDTO } from "./dto/juicios";
import { Adscripcion } from "../../estructura-judicial/adscripciones/dto/adscripciones.dto";
import { useDispatch } from "react-redux";
import { loaded ,loading} from "../../../core/slicers/loading/LoadingSlicer";

export default function Juicios() {
  const [refresh, setRefresh] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<JuiciosDTO | undefined>();

  const [materias, setMaterias] = useState<Adscripcion | undefined>();
  const [modalidadJuicio, setModalidadJucio] = useState<any[]>([]);

  const dispatch = useDispatch();

  useEffect(()=>{
    getJucisosModalidad();
    getAdscripciones();
  },[])
  
  async function getData(
    order: string,
    records: number,
    page: number,
    search: string
  ) {
    const params: any = {
      pathname: `/juicios`,
      searchParams: [
        { name: "records", value: records },
        { name: "page", value: page },
        { name: "search", value: search },
        { name: "populate", value: "modalidad_id" },
        { name: "rows", value: "_id,name,modalidad_id" },
      ],
    };
    const url = createURL(params);
    const res = await peticionBack({}, url, "GET", false);
    if(res)
    res.data.forEach((a: JuiciosDTO) => {
      a.modalidad = modalidadJuicio.find((e) => e._id === a.modalidad_id) || {
        name: "Sin asignar",
      };
    });
    return res;
  }

  async function getAdscripciones() {
    dispatch(loading());
    const res = await peticionBack({}, "/materias", "GET", false); //pedir que pongan 
    dispatch(loaded());
    if (res) setMaterias(res.data);
  }

  async function getJucisosModalidad() {
    dispatch(loading());
    const res = await peticionBack({}, "/modalidad-juicio", "GET", false);
    dispatch(loaded());
    if (res) setModalidadJucio(res.data);
  }

  /* TABLA */
  const columns = [
    { data: "name" },
    { data: "modalidadJuicio.name" },
    {
      data: null,
      render: (row: JuiciosDTO) => (
        <div className="d-flex justify-content-evenly">
          <FontAwesomeIcon
            icon={faPencil}
            className="text-warning cursor-pointer"
            onClick={() => openToEdit(row)}
          />
          <FontAwesomeIcon
            icon={faTrashCan}
            className="text-danger cursor-pointer"
            onClick={() =>
              swalAction(
                deleteRow,
                "Eliminar Secretario",
                `¿Desea eliminar el secretario ${row}?`,
                "question",
                row._id,
                "Eliminar"
              )
            }
          />
        </div>
      ),
    },
  ];

  const columnDefs = [
    { orderable: false, targets: [2] },
    { className: "text-center", targets: [0, 1] },
  ];

  async function deleteRow(id: number) {
    const res = await peticionBack({}, `/juicios/${id}`, "DELETE", true);
    if (res) setRefresh(true); //
  }

  function openToEdit(row: JuiciosDTO) {
    setSelected(row);
  }

  useEffect(() => {
    if (selected) setOpen(true);
  }, [selected]);

  useEffect(() => {
    if (!open) {
      if (selected) setSelected(undefined);
    }
  }, [open]);

  return (
    <CardMan title="Juicios" showMenu={false}>
      <div className="d-flex flex-column flex-md-row justify-content-md-between mb-2">
        <h4 className="">{open && (selected ? "Editar" : "Agregar")}</h4>
        {!open && (
          <Button
            color={colorRed}
            onClick={() => setOpen(true)}
            className="ml-2"
            style={{ marginRight: "13px", marginLeft: "13px" }}
          >
            Agregar
          </Button>
        )}
      </div>
      {open ? (
        <Form modalidadJuicios={modalidadJuicio} materias={materias} setOpen={setOpen} selected={selected} />
      ) : (
        <Datatable
          columns={columns}
          headers={["Nombre", "modalidad", "Acciones"]}
          columnDefs={columnDefs}
          petition={getData}
          control="back"
          stateRefresh={[refresh, setRefresh]}
          className="p-0"
        />
      )}
    </CardMan>
  );
}
