import { Input, rem } from "@mantine/core";
import { IconPencilExclamation } from "@tabler/icons-react";
import { FocusEventHandler } from "react";

export default function InputTextMan({
  value,
  label,
  placeholder,
  name,
  type,
  error,
  touched,
  onChangeMethod,
  onBlurMethod,
  maxLength,
  disabled,
  readonly,
  className,
  required,
  autocomplete,
  autosave,
  max,
  autoFocus,
  handleFocus,
  min,
  rightSection,
}: {
  value: string | string[] | number;
  label?: string;
  placeholder?: string;
  name: string;
  type: string;
  error?: string;
  touched?: boolean;
  onChangeMethod?: any;
  onBlurMethod?: any;
  maxLength?: number;
  disabled?: boolean;
  readonly?: boolean;
  className?: string;
  required?: boolean;
  autocomplete?: string;
  autosave?: string;
  max?: string | number;
  autoFocus?: boolean;
  handleFocus?: FocusEventHandler;
  min?: string | number;
  rightSection: any;
}) {
  return (
    <>
      <div className="mb-3">
        <Input.Label htmlFor={name}>
          {label} {required && <span className="text-danger">*</span>}
        </Input.Label>
        <>
          <Input
            type={type}
            id={name}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChangeMethod}
            onBlur={onBlurMethod}
            className={className}
            disabled={disabled}
            readOnly={readonly}
            maxLength={maxLength}
            autoComplete={autocomplete}
            autoSave={autosave}
            max={max}
            autoFocus={autoFocus}
            onFocus={handleFocus}
            min={min}
            error={touched && !!error}
            rightSectionPointerEvents="none"
            rightSection={
              touched && !!error ? (
                <IconPencilExclamation
                  style={{ width: rem(20), height: rem(20) }}
                  color="var(--mantine-color-error)"
                />
              ) : null
            }
          />{" "}
          {touched && !!error && <Input.Error>{error}</Input.Error>}
        </>
      </div>
    </>
  );
}
