/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Table, Checkbox, Loader, Button } from "@mantine/core";
import peticionBack from "../../../libs/peticiones";
import { Modulo } from "../modulos/dto/modulos.dto";
import { checkPermission } from "../../../libs/permisosModulo";
import { useSelector } from "react-redux";
import { moduleData } from "../../../core/slicers/permisos/permissionsSlicer";

export default function TablaModulos({
  id,
  modulos,
  permisos,
}: {
  id: string;
  modulos: any[];
  permisos: any[];
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [objetoPermisos, setObjetoPermisos] = useState<any>({});
  const modulosI = useSelector(moduleData);

  useEffect(() => {
    if (permisos.length > 0) {
      const ob: any = {};
      permisos.forEach((p: any) => {
        ob[p.module_id] = {
          id: p._id,
          permission: { c: p.c, r: p.r, u: p.u, d: p.d },
        };
      });
      setObjetoPermisos(ob);
    } else setObjetoPermisos({});
  }, [permisos]);

  function savePermiso(idMod: string, permiso: any) {
    const cpObPr = { ...objetoPermisos };
    cpObPr[idMod] = permiso;
    setObjetoPermisos(cpObPr);
  }

  return (
    <div>
      <Table striped>
        <thead>
          <tr>
            <th>Modulo</th>
            <th>Todos</th>
            <th>Crear</th>
            <th>Leer</th>
            <th>Editar</th>
            <th>Eliminar</th>
            <th>Accciones</th>
          </tr>
        </thead>
        <Table.Tbody>
          {modulos.map((rowData) => (
            <TableRow
              key={rowData._id}
              rowData={rowData}
              id={id}
              objetoPermisos={objetoPermisos}
              savePermiso={savePermiso}
              setLoading={setLoading}
              permisos={permisos}
              modulosI={modulosI}
            />
          ))}
        </Table.Tbody>
      </Table>
      {loading && <Loader />}
    </div>
  );
}

function TableRow({
  rowData,
  id,
  objetoPermisos,
  savePermiso,
  setLoading,
  permisos,
  modulosI,
}: {
  rowData: any;
  id: string;
  objetoPermisos: any;
  savePermiso: Function;
  setLoading: Function;
  permisos: any;
  modulosI: any;
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Table.Tr key={rowData._id}>
        <Table.Td>{rowData.name}</Table.Td>
        <Table.Td>
          <CheckCell
            id={id}
            stload={setLoading}
            modulo={rowData}
            permiso={objetoPermisos[rowData._id]}
            savePermiso={savePermiso}
            action="all"
            disabled={checkPermission(modulosI, "u")}
          />
        </Table.Td>
        <Table.Td>
          <CheckCell
            id={id}
            stload={setLoading}
            modulo={rowData}
            permiso={objetoPermisos[rowData._id]}
            savePermiso={savePermiso}
            action="c"
            disabled={checkPermission(modulosI, "u")}
          />
        </Table.Td>
        <Table.Td>
          <CheckCell
            id={id}
            stload={setLoading}
            modulo={rowData}
            permiso={objetoPermisos[rowData._id]}
            savePermiso={savePermiso}
            action="r"
            disabled={checkPermission(modulosI, "u")}
          />
        </Table.Td>
        <Table.Td>
          <CheckCell
            id={id}
            stload={setLoading}
            modulo={rowData}
            permiso={objetoPermisos[rowData._id]}
            savePermiso={savePermiso}
            action="u"
            disabled={checkPermission(modulosI, "u")}
          />
        </Table.Td>
        <Table.Td>
          <CheckCell
            id={id}
            stload={setLoading}
            modulo={rowData}
            permiso={objetoPermisos[rowData._id]}
            savePermiso={savePermiso}
            action="d"
            disabled={checkPermission(modulosI, "u")}
          />
        </Table.Td>
        <Table.Td>
          {rowData.children && (
            <Button onClick={() => setIsOpen(!isOpen)} variant="outline">
              {isOpen ? "Ocultar submodulos" : "Mostrar submodulos"}
            </Button>
          )}
        </Table.Td>
      </Table.Tr>
      {isOpen && rowData.children && (
        <tr>
          <td colSpan={7}>
            <TablaModulos
              id={id} // id del perfil
              modulos={rowData.children}
              permisos={permisos}
            />
          </td>
        </tr>
      )}
    </>
  );
}

function CheckCell({
  id,
  stload,
  modulo,
  permiso,
  savePermiso,
  action,
  disabled = false,
}: {
  id: string;
  stload: any;
  modulo: any | Modulo;
  permiso: any;
  savePermiso: Function;
  action: "all" | "r" | "c" | "u" | "d";
  disabled?: boolean;
}) {
  function getState() {
    if (modulo && permiso) {
      if (action === "all")
        return (
          permiso.permission.c &&
          permiso.permission.r &&
          permiso.permission.u &&
          permiso.permission.d
        );
      else return permiso.permission[action];
    }
    return false;
  }

  async function save({ checked }: any) {
    stload(true);
    const params = permiso
      ? { ...permiso.permission }
      : {
          c: false,
          r: false,
          u: false,
          d: false,
        };

    if (action === "all") {
      params.c = checked;
      params.r = checked;
      params.u = checked;
      params.d = checked;
    } else {
      params[action] = checked;
    }

    if (!permiso) {
      params.profile_id = id;
      params.module_id = modulo._id;
    }

    const route =
      permiso && permiso._id
        ? `/perfiles-modulos/${permiso._id}`
        : `/perfiles-modulos`;
    const res = await peticionBack(params, route, "PUT", true);
    if (res) {
      savePermiso(modulo._id, {
        id: res._id,
        permission: { c: res.c, r: res.r, u: res.u, d: res.d },
      });
    }
    stload(false);
  }

  if (modulo.children && action !== "all") return null;

  return (
    <Checkbox
      id={`${modulo._id}-${action}`}
      label=""
      onChange={({ target }: any) => save(target)}
      //onChange={save}
      checked={getState()}
      color="red"
      style={{ width: "20px", height: "20px" }}
      disabled={!disabled}
    />
  );
}
