import { Flex, Image, Modal, Text } from "@mantine/core";

interface ModalProps { 
    state: Array<any>, 
    title?: string, 
    file: string[]|null, 
    backdrop?: boolean
}

export default function ModalFileViewer({ state, title, file, backdrop = true }: ModalProps){

    return(
        <Modal opened={state[0]} onClose={() =>state[1](false)} title={title || "Visualizar Documento"} size="90vw" padding="xs" centered closeOnEscape={backdrop} closeOnClickOutside={backdrop}>
            <Flex bg="gray" direction="row" justify="center" >
                {
                    !!file && (
                        file[0] === "pdf" ? (
                            <iframe src={file[1]} width="100%" height="auto" style={{overflow: "auto"}} allowFullScreen title="pdf">
                                <p>Dispositivo no compatible</p>
                            </iframe>
                        ) : ["png", "gif", "jpg", "jpeg", "svg", "webp", "avif"].includes(file[0]) ? (
                            <Image src={file[1]} h="100%"/>
                        ) : ["mp4", "avi", "ogg"].includes(file[0]) ? (
                            <video width="320" height="240" controls>
                                <source src={file[1]} type="video/mp4" />
                                <source src={file[1]} type="video/ogg" />
                                <source src={file[1]} type="video/avi" />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            <div className="my-5 py-5 text-center">
                                <Text>Archivo no compatible: {file[0]}</Text>
                            </div>
                        )
                    )
                }
            </Flex>
        </Modal>
    )
}