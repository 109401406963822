/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "@mantine/core";
import { HeaderSimple } from "./TopBar";
import { useDispatch, useSelector } from "react-redux";
import { clear, loginData } from "../../core/slicers/auth/LoginSlicer";
import { AuthStates } from "../../core/slicers/auth/states";
import {
  loadProfiles,
  profileData,
} from "../../core/slicers/perfil/profileSlicer";
import { ProfileStates } from "../../core/slicers/perfil/states";
import peticionBack from "../../libs/peticiones";
import Swal from "sweetalert2";
import {
  loadModules,
  moduleData,
} from "../../core/slicers/permisos/permissionsSlicer";
import { ModulesStates } from "../../core/slicers/permisos/states";

export default function Layout({ children }: { children: React.ReactNode }) {
  //const { state, dispatch } = useStore();
  let location = useLocation();
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const login = useSelector(loginData);
  const profile = useSelector(profileData);
  const modulos = useSelector(moduleData);

  useEffect(() => {
    if (login.state !== AuthStates.LOGIN_SUCCESS) {
      navigate("/auth", { replace: true });
    }
    if (
      login.state === AuthStates.LOGIN_SUCCESS &&
      location.pathname === "/auth"
    ) {
      navigate("/", { replace: true });
    }
    if (
      login.state === AuthStates.LOGIN_SUCCESS &&
      location.pathname !== "/" &&
      modulos.state === ModulesStates.MODULES_LOADED &&
      modulos.modules &&
      modulos.modules.length > 0
    ) {
      //SI ES DIFERENTE DEL MAIN Y YA ESTA CARGADO TODO
      const find = modulos.modules.find(
        (modulo) => modulo.route === location.pathname
      );
      if (!find) {
        navigate("/", { replace: true });
      } else if (!find.c && !find.r && !find.u && !find.d) {
        navigate("/", { replace: true });
      }
    }
  }, [login, modulos, location.pathname]);

  useEffect(() => {
    if (login.state === AuthStates.LOGIN_SUCCESS && !profile.state) {
      getProfiles();
    }
  }, [login, profile]);

  useEffect(() => {
    if (
      login.state === AuthStates.LOGIN_SUCCESS &&
      profile.state === ProfileStates.PROFILE_LOADED &&
      profile.selected &&
      login.token
    ) {
      getPermissions(profile.selected, login.token);
    }
  }, [login, profile]);

  async function getProfiles() {
    const res = await peticionBack({}, "/auth/getProfiles", "POST", false);
    if (res) {
      dispatch(loadProfiles({ profiles: res }));
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La sesión a expirado",
        showConfirmButton: false,
        timer: 3000,
      });
      // dispatch(expiration())
    }
  }

  async function getPermissions(profile_id: string, token: string) {
    const headers = { Authorization: `bearer ${token}` };
    const res = await peticionBack(
      { profile_id },
      `/auth/getPermissions`,
      "POST",
      false,
      headers
    );
    if (res) {
      dispatch(
        loadModules({
          modules: res.permisos.map((permiso: any) => {
            return {
              _id: permiso._id,
              parent_id: permiso.parent_id,
              name: permiso.name,
              route: permiso.route,
              level: permiso.level,
              c: permiso.permisssions_modules.c,
              r: permiso.permisssions_modules.r,
              u: permiso.permisssions_modules.u,
              d: permiso.permisssions_modules.d,
            };
          }),
        })
      );
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La sesión a expirado y/o no tienes permiso para hacer esto",
        showConfirmButton: false,
        timer: 3000,
      });
      dispatch(clear());
    }
  }

  return (
    <Container fluid className="m-0 p-0">
      {login.state === AuthStates.LOGIN_SUCCESS && <HeaderSimple />}
      <div className="p-2 p-md-3 p-lg-4 my-1">{children}</div>
    </Container>
  );
}
