import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AuthStates } from "./states";
import { userAuth } from "./interfaces/userInterface";
import { RootState } from "../../../components/redux/store";
import Swal from "sweetalert2";

const initialState: userAuth = {}

export const authSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<userAuth>) => {
      state.state = AuthStates.LOGIN_SUCCESS
      state.fullName = action.payload.fullName
      state.token = action.payload.token
      state.username = action.payload.username
      state.email = action.payload.email
      //SHOW MESSAGE WELCOME
    },
    loginFailure: (state) => {
      state.state = AuthStates.LOGIN_FAILURE
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Usuario y/o contraseña incorrectos',
        showConfirmButton: false,
        timer: 3000
      })
    },
    expiration: (state, action: PayloadAction<{ iat: number, exp: number }>) => {
      state.exp = action.payload.exp;
      state.iat = action.payload.iat;
    },
    clear: (state) => {
      delete state.state
      delete state.fullName
      delete state.token
      delete state.username
      delete state.email
      delete state.exp
      delete state.iat
    },
  },

});
export const { loginSuccess, loginFailure, clear, expiration } = authSlice.actions
export default authSlice.reducer

export const loginData = (state: RootState) => state.login