/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect } from "react";
import { swalAction } from "../../../components/modals/ModalConfirm";
import peticionBack from "../../../libs/peticiones";
import InputTextMan from "../../../components/inputs/inputTextMan";
import { Button, Grid } from "@mantine/core";
import { ExpedientePersonal } from "./dto/expedienete-personal.dto";
import InputDateMan from "../../../components/inputs/inputDate";
import InputSearch from "../../../components/inputs/InputSelectSearch";

const defaultForm: ExpedientePersonal = {
  nombre: "",
  aPaterno: "",
  aMaterno: "",
  fechaNacimiento: "",
  sexo: "",
  curp: "",
  rfc: "",
  claveIdentificacion: "",
};

const formSchema = Yup.object().shape({
  nombre: Yup.string().required("Ingrese el nombre del empleado"),
  aPaterno: Yup.string().required("Ingrese el apellido paterno del empleado"),
  aMaterno: Yup.string().required("Ingrese el apellido materno del empleado"),
  fechaNacimiento: Yup.string().required("Seleccione una fecha de nacimiento"),
  sexo: Yup.string().required("Seleccione una opción"),
  curp: Yup.string().required("Ingrese una curp").max(18, "La longitud máxima es 18 caracteres"),
  rfc: Yup.string().required("Ingrese un rfc").max(13, "La longitud máxima es 13 caracteres"),
  claveIdentificacion: Yup.string().required(
    "Ingrese una clave de identificación"
  ),
});

export default function Form({
  setOpen,
  selected,
}: {
  setOpen: any;
  selected?: any;
}) {
  const {
    handleSubmit,
    handleReset,
    handleBlur,
    setFieldValue,
    setValues,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: formSchema,
    onSubmit: (values) => {
      swalAction(
        save,
        `${selected ? "Editar" : "Guardar"} Empleadp`,
        `¿Desea ${selected ? "editar" : "guardar"} este Empleado?`,
        "question"
      );
    },
    onReset: () => {
      setOpen(false);
    },
  });

  function onChange({ target }: { target: any }) {
    setFieldValue(target.name, target.value);
  }

  useEffect(() => {
    if (selected)
      setValues(
        {
          nombre: selected.nombre,
          aPaterno: selected.aPaterno,
          aMaterno: selected.aMaterno,
          fechaNacimiento: selected.fechaNacimiento,
          sexo: selected.sexo,
          curp: selected.curp,
          rfc: selected.rfc,
          claveIdentificacion: selected.claveIdentificacion,
        },
        true
      );
  }, []);

  async function save() {
    const method = selected ? "PUT" : "POST";
    const rute = selected ? `/${selected._id}` : "";
    const res = await peticionBack(
      { ...values },
      "/personal" + rute,
      method,
      true
    );
    if (res) {
      // handleReset(null)
    }
  }
  const generos = [{name:"Masculino"},{ name:"Femenino"}, {name:"LGBT"},{ name:"No binario"}, {name:"Otro"}];

  return (
    <form onSubmit={handleSubmit} onReset={handleReset}>
      <Grid justify="space-between" grow>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <InputTextMan
            label="Nombre"
            name="nombre"
            type="text"
            value={values.nombre}
            touched={touched.nombre}
            error={errors.nombre}
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            required
            rightSection
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <InputTextMan
            label="Apellido Paterno"
            name="aPaterno"
            type="text"
            value={values.aPaterno}
            touched={touched.aPaterno}
            error={errors.aPaterno}
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            required
            rightSection
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <InputTextMan
            label="Apellido Materno"
            name="aMaterno"
            type="text"
            value={values.aMaterno}
            touched={touched.aMaterno}
            error={errors.aMaterno}
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            required
            rightSection
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <InputDateMan
            label="Fecha de nacimiento"
            name="fechaNacimiento"
            value={values.fechaNacimiento}
            touched={touched.fechaNacimiento as any}
            error={errors.fechaNacimiento as any}
            onChangeMethod={({ target }: any) =>
            setFieldValue("fechaNacimiento", target.value)
          }
            onBlurMethod={handleBlur}
            required
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                      <InputSearch
              label="Sexo"
              name="sexo"
              value={values.sexo}
              touched={touched.sexo}
              error={errors.sexo}
              values={generos}
              labelVal="name"
              valueVal="name"
              onChangeMethod={({ target }: any) =>
                setFieldValue("sexo", target.value)
              }
              onBlurMethod={handleBlur}
              required
            />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <InputTextMan
            label="CURP"
            name="curp"
            type="text"
            value={values.curp}
            touched={touched.curp}
            error={errors.curp}
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            required
            rightSection
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <InputTextMan
            label="RFC"
            name="rfc"
            type="text"
            max={"13"}
            value={values.rfc}
            touched={touched.rfc}
            error={errors.rfc}
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            required
            rightSection
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <InputTextMan
            label="Clave de Identificación"
            name="claveIdentificacion"
            type="text"
            value={values.claveIdentificacion}
            touched={touched.claveIdentificacion}
            error={errors.claveIdentificacion}
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            required
            rightSection
          />
        </Grid.Col>
      </Grid>
      <div className="d-grid gap-2 d-md-flex justify-content-md-around mt-2">
        <Button variant="filled" color="redColor" type="reset" className="mt-3">
          Cancelar
        </Button>
        <Button
          variant="filled"
          color="greenColor"
          type="submit"
          className="mt-2 mt-sm-3"
        >
          Guardar
        </Button>
      </div>
    </form>
  );
}
