import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import InputSearch from "../../../components/inputs/InputSelectSearch";
import { Perfil } from "./dto/perfiles.dto";
import { Sistemas } from "../sistemas/dto/sistemas.dto";
import { swalAction } from "../../../components/modals/ModalConfirm";
import peticionBack from "../../../libs/peticiones";
import InputTextMan from "../../../components/inputs/inputTextMan";
import { Button, Grid } from "@mantine/core";
import { useDispatch } from "react-redux";
import { loaded, loading } from "../../../core/slicers/loading/LoadingSlicer";

const defaultForm: Perfil = {
  name: "",
  system_id: "",
};

const formSchema = Yup.object().shape({
  name: Yup.string().required("Ingrese un nombre para el perfil"),
  system_id: Yup.string().required("Seleccione un sistema"),
});

export default function Form({
  setOpen,
  selected,
}: {
  setOpen: any;
  selected?: any;
}) {
  const [sistemas, setSistemas] = useState<Sistemas[]>([]);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    handleReset,
    handleBlur,
    setFieldValue,
    setValues,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: formSchema,
    onSubmit: (values) => {
      swalAction(
        save,
        `${selected ? "Editar" : "Guardar"} Perfil`,
        `¿Desea ${selected ? "editar" : "guardar"} este perfil?`,
        "question"
      );
    },
    onReset: () => {
      setOpen(false);
    },
  });

  function onChange({ target }: { target: any }) {
    setFieldValue(target.name, target.value);
  }

  useEffect(() => {
    getSistemas();
    if (selected)
      setValues(
        {
          name: selected.name,
          system_id: selected.system_id,
        },
        true
      );
    // eslint-disable-next-line
  }, []);

  async function getSistemas() {
    dispatch(loading());
    const res = await peticionBack({}, "/sistemas", "GET", false);
    dispatch(loaded());
    if (res) setSistemas(res.data);
  }

  async function save() {
    const method = selected ? "PUT" : "POST";
    const rute = selected ? `/${selected._id}` : "";
    const res = await peticionBack(
      { ...values },
      "/perfiles" + rute,
      method,
      true
    );
    if (res) handleReset(null);
  }

  return (
    <form onSubmit={handleSubmit} onReset={handleReset}>
      <Grid justify="space-between" grow>
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <InputTextMan
            label="Nombre"
            name="name"
            type="text"
            value={values.name}
            touched={touched.name}
            error={errors.name}
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            required
            rightSection
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <InputSearch
            label="Sistema"
            name="system_id"
            value={values.system_id}
            touched={touched.system_id}
            error={errors.system_id}
            values={sistemas}
            labelVal="name"
            valueVal="_id"
            onChangeMethod={({ target }: any) =>
              setFieldValue("system_id", target.value)
            }
            onBlurMethod={handleBlur}
            required
          />
        </Grid.Col>
      </Grid>
      <div className="d-grid gap-2 d-md-flex justify-content-md-around mt-2">
        <Button variant="filled" color="redColor" type="reset" className="mt-3">
          Cancelar
        </Button>
        <Button
          variant="filled"
          color="greenColor"
          type="submit"
          className="mt-2 mt-sm-3"
        >
          Guardar
        </Button>
      </div>
    </form>
  );
}
