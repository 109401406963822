import Swal from "sweetalert2";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success ms-4 fs-6',
    cancelButton: 'btn btn-danger me-4 fs-6'
  },
  buttonsStyling: false
})

export function swalAction(
  handleAction: Function,
  modalTitle: string,
  content: string,
  icon: "error" | "info" | "question" | "success" | "warning" = "question",
  value?: any,
  buttonOkMsg: string = "Aceptar",
  buttonCancelMsg: string = "Cancelar",
  hiddenCancel: boolean = false,
  hiddenOk: boolean = false,
){
  swalWithBootstrapButtons.fire({
    title: modalTitle,
    icon: icon,
    text: content,
    showCancelButton: !hiddenCancel,
    showConfirmButton: !hiddenOk,
    confirmButtonText: buttonOkMsg,
    cancelButtonText: buttonCancelMsg,
    reverseButtons:  true
  }).then((result) => {
    if(result.isConfirmed) {
      handleAction(value);
    }
  });
}

