import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Sistemas } from "../sistemas/dto/sistemas.dto";
import { Usuario } from "../usuarios/dto/usuario.dt";
import { Perfil } from "../perfiles/dto/perfiles.dto";
import peticionBack from "../../../libs/peticiones";
import Alert from "../../../components/notification/Alert";
import createURL from "../../../libs/url";
import { swalAction } from "../../../components/modals/ModalConfirm";
import Datatable from "../../../components/datatable/Datatable";
import CardMan from "../../../components/card/Card";
import { Button, Collapse, Divider, Grid } from "@mantine/core";
import InputSearch from "../../../components/inputs/InputSelectSearch";
import { checkPermission } from "../../../libs/permisosModulo";
import { useDispatch, useSelector } from "react-redux";
import { moduleData } from "../../../core/slicers/permisos/permissionsSlicer";
import { loaded, loading } from "../../../core/slicers/loading/LoadingSlicer";

export default function UsuarioPerfiles() {
  const [usuario, setUsuario] = useState<Usuario[]>([]);
  const [systems, setSystems] = useState<Sistemas[]>([]);

  const [selectedUsuario, setSelectedUsuario] = useState<
    string | undefined | Usuario
  >();
  const [selectedSystem, setSelectedSystem] = useState<string>();

  const [perfiles, setPerfiles] = useState<any>({});

  const [usuarioPerfiles, setUsuarioPerfiles] = useState<Perfil[]>([]); // tabla
  const [cleanPerfiles, setCleanPerfiles] = useState<Perfil[]>([]); // Selector
  const modulos = useSelector(moduleData);

  const [open, setOpen] = useState<boolean>(false);

  const [refresh, setRefresh] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getUsuarios();
    getSystems();
    // eslint-disable-next-line
  }, []);

  async function getUsuarios() {
    const params: any = {
      pathname: `/usuarios`,
      searchParams: [
        {
          name: "rows",
          value: "_id,name,username,email,phone,pwd,system_id,deletedAt",
        },
      ],
    };
    const url = createURL(params);
    dispatch(loading());
    const res = await peticionBack({}, url, "GET", false);
    dispatch(loaded());
    if (res) setUsuario(res.data);
  }

  async function getSystems() {
    dispatch(loading());
    const res = await peticionBack({}, `/sistemas`, "GET", false);
    dispatch(loaded());
    if (res) setSystems(res.data);
  }

  async function getPerfilesUsuario() {
    const perfilesSistema = await getPerfilesSistema();
    if (!perfilesSistema) {
      Alert("info", "El sistema no tiene perfiles asignados");
      return;
    }
    dispatch(loading());
    const res = await peticionBack(
      {},
      `/usuarios/${selectedUsuario}`,
      "GET",
      false
    );
    dispatch(loaded());
    if (res) {
      const filteredPA = res.perfiles.filter(
        (p: any) => p.profile.system_id === selectedSystem
      );
      const filteredPAIds = filteredPA.map((p: any) => p.profile._id);
      setUsuarioPerfiles(filteredPA);
      setCleanPerfiles(
        perfilesSistema.filter((ps: Perfil) => !filteredPAIds.includes(ps._id))
      );
      setRefresh(true);
      setOpen(true);
    }
  }

  async function getPerfilesSistema(): Promise<Perfil[] | null> {
    if (perfiles[selectedSystem as string])
      return perfiles[selectedSystem as string];
    const params: any = {
      pathname: `/perfiles`,
      searchParams: [
        {
          name: "system_id",
          value: decodeURIComponent(selectedSystem as string),
        },
      ],
    };
    const url = createURL(params);
    const res = await peticionBack({}, url, "GET", false);
    if (res && res.data.length > 0) {
      const cpyPerfiles = { ...perfiles };
      cpyPerfiles[selectedSystem as string] = res.data;
      setPerfiles(cpyPerfiles);
      return res.data;
    }
    return null;
  }

  useEffect(() => {
    setOpen(false);
  }, [selectedUsuario, selectedSystem]);

  /* TABLA */
  const columns = [
    { data: "profile.name" },
    {
      data: null,
      render: (row: any) => (
        <div className="d-flex justify-content-evenly">
          {checkPermission(modulos, "d") && (
            <FontAwesomeIcon
              icon={faTrashCan}
              className="text-danger cursor-pointer"
              onClick={() =>
                swalAction(
                  deleteRow,
                  "Remover Perfil",
                  `¿Desea remover el perfil ${row.name} al usuario?`,
                  "question",
                  row._id,
                  "Eliminar"
                )
              }
            />
          )}
        </div>
      ),
    },
  ];

  const columnDefs = [
    { orderable: false, targets: [1] },
    { className: "text-center", targets: [0] },
  ];

  async function deleteRow(a: string) {
    const params = { profile: a };
    const res = await peticionBack(
      params,
      `/usuarios/removeProfile/${a}`,
      "DELETE",
      true
    );
    if (res) getPerfilesUsuario();
  }

  return (
    <CardMan title="Materias Adscripciones" showMenu={false}>
      <Grid justify="space-between" grow>
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <InputSearch
            label="Usuario"
            name="usuario"
            value={selectedUsuario as string}
            values={usuario}
            labelVal="name"
            valueVal="_id"
            onChangeMethod={({ target }: any) =>
              setSelectedUsuario(target.value)
            }
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <InputSearch
            label="Sistema"
            name="sistema"
            value={selectedSystem as string}
            values={systems}
            labelVal="name"
            valueVal="_id"
            onChangeMethod={({ target }: any) =>
              setSelectedSystem(target.value)
            }
            disabled={!selectedUsuario}
          />
        </Grid.Col>
      </Grid>
      <div className="d-grid gap-2 d-md-flex justify-content-md-around mt-2">
        <Button
          variant="filled"
          color="greenColor"
          onClick={() => getPerfilesUsuario()}
        >
          Abrir
        </Button>
      </div>
      <Divider my="md" size="sm" />
      <Collapse in={open}>
        <div>
          <div className="text-end">
            <Button
              variant="filled"
              color="redColor"
              onClick={() => setOpen(false)}
            >
              Cerrar
            </Button>
          </div>
          <MiniForm
            selected={selectedUsuario as string}
            perfiles={cleanPerfiles}
            getPerfilesAgente={getPerfilesUsuario}
            open={open}
            usuarios={usuario}
            modulos={modulos}
          />
          {checkPermission(modulos, "r") && (
            <Datatable
              columns={columns}
              headers={["Perfil", "Eliminar"]}
              columnDefs={columnDefs}
              data={usuarioPerfiles}
              control="front"
              stateRefresh={[refresh, setRefresh]}
              className="p-0"
            />
          )}
        </div>
      </Collapse>
    </CardMan>
  );
}

function MiniForm({
  selected,
  perfiles,
  getPerfilesAgente,
  open,
  usuarios,
  state,
  modulos,
}: {
  selected?: string;
  perfiles: Perfil[];
  getPerfilesAgente: Function;
  open: boolean;
  usuarios: any[];
  state?: any;
  modulos: any;
}) {
  const formSchema = Yup.object().shape({
    profile_id: Yup.string().required("Seleccione un perfil"),
  });

  const { handleSubmit, handleReset, setFieldValue, values, errors, touched } =
    useFormik({
      initialValues: { profile_id: "" },
      validationSchema: formSchema,
      onSubmit: (values) => {
        swalAction(
          save,
          "Agregar Perfil",
          `¿Desea asignar este perfil a este usuario?`,
          "question"
        );
      },
      onReset: () => {},
    });

  async function save() {
    const res = await peticionBack(
      values,
      `/usuarios/addProfile/${selected}`,
      "PUT",
      true
    );
    if (res) {
      handleReset(null);
      getPerfilesAgente();
    }
  }

  useEffect(() => {
    if (!open) handleReset(null);
    // eslint-disable-next-line
  }, [open]);

  return (
    <form onSubmit={handleSubmit} onReset={handleReset}>
      <Grid justify="space-between" grow>
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <InputSearch
            label="Perfil"
            name="profile_id"
            value={values.profile_id}
            error={errors.profile_id}
            touched={touched.profile_id}
            values={perfiles}
            labelVal="name"
            valueVal="_id"
            onChangeMethod={({ target }: any) =>
              setFieldValue("profile_id", target.value)
            }
          />
        </Grid.Col>
        <Grid.Col
          style={{ marginTop: "21px" }}
          span={{ base: 12, md: 3, lg: 3 }}
        >
          {checkPermission(modulos, "c") && (
            <Button variant="light" color="teal" type="submit">
              Añadir
            </Button>
          )}
        </Grid.Col>
      </Grid>
    </form>
  );
}
