import { Sexo, Tipo_Persona, Tipos_actuacion } from "../core/enums/enums"

export const generosArray = [
    { value: Sexo.FEMENINO, label: Sexo.FEMENINO },
    { value: Sexo.MASCULINO, label: Sexo.MASCULINO },
    { value: Sexo.LGBT, label: Sexo.LGBT },
]
export const tiposActuacionArray = [
    { value: Tipos_actuacion.COLECTIVA, label: Tipos_actuacion.COLECTIVA },
    { value: Tipos_actuacion.INDIVIDUAL, label: Tipos_actuacion.INDIVIDUAL },
]

export const tiposPersonaArray = [
    { value: Tipo_Persona.FISICA, label: Tipo_Persona.FISICA },
    { value: Tipo_Persona.MORAL, label: Tipo_Persona.MORAL },
]

// export const tiposDemanda = [
//     { value: TiposDemanda.PRIVADA, label: TiposDemanda.PRIVADA },
//     { value: TiposDemanda.PUBLICA, label: TiposDemanda.PUBLICA },
// ]