import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Flex, LoadingOverlay, Modal, Text } from "@mantine/core";
import { useDisclosure, useMediaQuery  } from "@mantine/hooks";
import { ButtonProps } from "../Forms/ButtonProps";
import { faFileAlt, faFileLines, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { Dropzone, FileWithPath } from "@mantine/dropzone";
import { useEffect, useState } from "react";
import { fBytes } from "../../libs/formats";
import { uploadFiles } from "../../libs/peticiones";
import { FileTypeIcon } from "../files/FileTypeIcon";

interface ModalFileProps { 
    title?: string, 
    button?: ButtonProps, 
    accept: string[] | any, 
    size?: any, 
    multiple?: boolean, 
    onLoad?: Function, 
    onUpload?: Function, 
    endpoint?: string,
    fileProps: {
        ref: string,
        type: string,
        notes: string,
    }
}
export default function ModalFileUpload(
    { title, button, accept, size, multiple, onLoad, onUpload, endpoint, fileProps }: ModalFileProps
){
    const [opened, { open, close }] = useDisclosure(false);
    const [files, setFiles] = useState<FileWithPath[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const isMobile = useMediaQuery('(max-width: 50em)');
    const defaultSize = isMobile ? "90vw" : "65vw";

    function onDrop(files: FileWithPath[]) {
        let fls = files;
        if(onLoad) fls = onLoad(files);
        setFiles(fls);
    }

    useEffect(()=>{
        if(!opened) setFiles([]);
    }, [opened]);

    function removeFile(ix: number){
        setFiles(files.filter((f, li) => li !== ix));
    }

    async function saveFiles(){
        if(files.length < 1) return;
        setLoading(true);
        let data = [ ...files ];
        if(onUpload) data = onUpload(data);
        const res = await uploadFiles({ endpoint: endpoint, files: { ...fileProps, files: data }, show: true });
        if(res) close();
        setLoading(false);
    }

    function handleClose(){
        if(!loading) close();
    }

    return(
        <>
            <Modal opened={opened} onClose={handleClose} title={title || "Subir documento"} centered size={size || defaultSize} pos="relative" >
                <Dropzone accept={accept} onDrop={onDrop} multiple={multiple || false}>
                    <Flex direction="row" justify="center" style={{minHeight: "150px"}} className="align-items-center">
                        <FontAwesomeIcon icon={faFileAlt} size="2x" className="text-black-50 me-2" />
                        <Text size="lg" c="dimmed">
                            Arrastre los archivos aquí o haga clic para seleccionar
                        </Text>
                    </Flex>
                </Dropzone>
                <section className="my-2">
                    {
                        files.map((f, ix) => (
                            <div className="border px-2 py-1 my-1 rounded-1" key={f.name}>
                                <Flex direction="row" justify="space-between">
                                    <Flex direction="row">
                                        <FileTypeIcon name={f.name} />
                                        <Text size="lg">{f.name}</Text>
                                    </Flex>
                                    <Flex direction="row" className="pt-1">
                                        <Text>{fBytes(f.size)}</Text>
                                        <FontAwesomeIcon icon={faTrashAlt} className="text-danger cursor-pointer fs-5 ms-1" onClick={() => removeFile(ix)} />
                                    </Flex>
                                </Flex>
                            </div>
                        ))
                    }
                </section>
                <div className="text-center mt-3">
                    <Button type="button" color="primary" onClick={() => saveFiles()}>{ button?.msg || "Subir documneto"}</Button>
                </div>
                <LoadingOverlay visible={loading} loaderProps={{ size: 35, type: "oval" }} />
            </Modal>

            <Button color={button?.color} onClick={() => button?.click ? button.click(open) : open()} disabled={button?.disabled}>
                <FontAwesomeIcon icon={button?.icon || faFileLines} className="me-1" />
                { button?.msg || "Subir documento" }
            </Button>
        </>
    )
}

