/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { swalAction } from "../../../components/modals/ModalConfirm";
import { Button, Grid, Group, Text } from "@mantine/core";
import peticionBack from "../../../libs/peticiones";
import { Edificios } from "./dto/edificios.dto";
import InputSearch from "../../../components/inputs/InputSelectSearch";
import { setDefaults, fromAddress } from "react-geocode";
import MapWithMarker from "../../../components/maps/Maps";
import { useDispatch } from "react-redux";
import { loading, loaded } from "../../../core/slicers/loading/LoadingSlicer";
import InputTextMan from "../../../components/inputs/inputTextMan";

const defaultForm: Edificios = {
  calle: "",
  colonia: "",
  num_int: "",
  num_ext: "",
  cp: "",
  latitude: 0,
  longitude: 0,
  name: "",
  municipio_id: "",
};

export default function Form({
  setOpen,
  selected,
}: {
  setOpen: any;
  selected?: any;
}) {
  const [municipios, setMunicipios] = useState<Edificios[]>([]);
  const dispatch = useDispatch();
  const gmap = process.env.REACT_APP_GMAP || "";

  const [mapCenter, setMapCenter] = useState({
    lat: 20,
    lng: -100,
  });
  const [markerPosition, setMarkerPosition] = useState({
    lat: 20,
    lng: -100,
  });

  const formSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "El nombre debe contener al menos 2 letras")
      .max(250, "El nombre ingresado es muy largo")
      .required("Ingrese el Edificio"),
    municipio_id: Yup.string().required("Seleccione un municipio"),
    calle: Yup.string()
      .min(5, "La calle debe tener al menos 5 caracteres")
      .max(100, "El nombre de la calle es muy largo")
      .required("Ingrese una calle"),
    colonia: Yup.string()
      .min(3, "El nombre de la colonia debe tener al menos 3 caracteres")
      .max(100, "El nombre de la colonia es muy largo")
      .required("Ingrese una colonia"),
    num_ext: Yup.string()
      .max(45, "Número exterior demasiado largo")
      .required("Ingrese un número exterior"),
    cp: Yup.string()
      .min(3, "Código postal inválido")
      .max(10, "Código postal inválido")
      .required("Ingrese un código postal"),
    latitude: Yup.number().required(
      "No se ha establecido una localización en el mapa"
    ),
    longitude: Yup.number().required("Seleccione una localización en el mapa"),
  });

  const {
    handleSubmit,
    handleReset,
    handleBlur,
    setFieldValue,
    setValues,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: formSchema,
    onSubmit: (values) =>
      swalAction(
        save,
        `${selected ? "Editar" : "Guardar"} Edificio`,
        `¿Desea ${selected ? "editar" : "guardar"} este Edificio?`,
        "question"
      ),
    onReset: () => {
      setOpen(false);
    },
  });

  function onChange({ target }: { target: any }) {
    setFieldValue(target.name, target.value);
  }

  useEffect(() => {
    getMunicipios();
    if (selected) {
      setValues(
        {
          name: selected.name,
          calle: selected.calle,
          colonia: selected.colonia,
          num_int: selected.num_int,
          num_ext: selected.num_ext,
          cp: selected.cp,
          latitude: selected.latitude,
          municipio_id: selected.municipio_id,
          longitude: selected.longitude,
        },
        true
      );
    }
    // Geocode.setKey(gmap);
    // Geocode.setLocationType("ROOFTOP");
    // Geocode.setRegion("mx");
    setDefaults({
      key: gmap, // Your API key here.
      language: "es", // Default language for responses.
      region: "mx", // Default region for responses.
    } as any);
  }, []);

  async function getMunicipios() {
    dispatch(loading());
    const res = await peticionBack(
      {},
      "/municipios",
      "GET",
      false
    );
    if (res) setMunicipios(res.data);
    dispatch(loaded());
  }

  const handleFormSubmit = async () => {
    const fullAddress = `${values.calle} ${values.num_ext}, ${
      values.colonia
    }, ${municipios.find(({ _id }) => _id === values.municipio_id)?.name}, ${
      values.cp
    }`;
    const handleLocationChange = (lat: any, lng: any) => {
      setValues((prevValues) => ({
        ...prevValues,
        latitude: lat,
        longitude: lng,
      }));
    };
    fromAddress(fullAddress)
      .then(({ results }) => {
        const { lat, lng } = results[0].geometry.location;
        setMapCenter({ lat, lng });
        setMarkerPosition({ lat, lng });
        handleLocationChange(lat, lng);
      })
      .catch(console.error);
  };

  async function save() {
    const method = selected ? "PUT" : "POST";
    const rute = selected ? `/${selected._id}` : "";

    if (values.num_int === "") {
      delete values.num_int;
    }
    dispatch(loading());
    const res = await peticionBack(
      { ...values },
      "/edificios" + rute,
      method,
      true
    );
    if (res) {
      handleReset(null);
    }
    dispatch(loaded());
  }

  return (
    <form onSubmit={handleSubmit} onReset={handleReset}>
      <Grid justify="space-between" grow>
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <InputTextMan
            label="Nombre del Edificio"
            name="name"
            type="text"
            value={values.name}
            touched={touched.name}
            error={errors.name}
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            required
            rightSection
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <InputSearch
            label="Municipio"
            name="municipio_id"
            value={values.municipio_id}
            error={errors.municipio_id}
            touched={touched.municipio_id}
            values={municipios}
            labelVal="name"
            valueVal="_id"
            onChangeMethod={({ target }: any) =>
              setFieldValue("municipio_id", target.value)
            }
            required
          />
        </Grid.Col>
      </Grid>
      <Grid justify="space-around" grow>
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <InputTextMan
            label="Calle"
            name="calle"
            type="text"
            value={values.calle}
            touched={touched.calle}
            error={errors.calle}
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            required
            rightSection
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <InputTextMan
            label="Colonia"
            name="colonia"
            type="text"
            value={values.colonia}
            touched={touched.colonia}
            error={errors.colonia}
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            required
            rightSection
          />
        </Grid.Col>
      </Grid>
      <Grid justify="space-around" grow>
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <InputTextMan
            label="Número exterior"
            name="num_ext"
            type="text"
            value={values.num_ext}
            touched={touched.num_ext}
            error={errors.num_ext}
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            required
            rightSection
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <InputTextMan
            label="Número interior"
            name="num_int"
            type="text"
            value={values.num_int || ""}
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            rightSection
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <InputTextMan
            label="Código postal"
            name="cp"
            type="text"
            value={values.cp}
            touched={touched.cp}
            error={errors.cp}
            onChangeMethod={onChange}
            onBlurMethod={handleBlur}
            required
            rightSection
          />
        </Grid.Col>
        <InputTextMan
          name="latitude"
          type="hidden"
          value={values.latitude}
          rightSection
          disabled
        />

        <InputTextMan
          name="longitude"
          type="hidden"
          value={values.longitude}
          rightSection
          disabled
        />
      </Grid>
      <Grid justify="center">
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <Text fw={700} ta="center">
            Identifique la ubicación en el mapa
          </Text>
        </Grid.Col>
      </Grid>

      <Grid justify="space-around" grow>
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <Group justify="center">
            <Button
              variant="filled"
              color="redColor"
              className="mt-3"
              onClick={handleFormSubmit}
            >
              Buscar ubicación
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
      <Grid justify="space-around" grow>
        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
          <MapWithMarker markerPosition={markerPosition} center={mapCenter} />
        </Grid.Col>
      </Grid>

      <div className="d-grid gap-2 d-md-flex justify-content-md-around mt-2">
        <Button variant="filled" color="redColor" type="reset" className="mt-3">
          Cancelar
        </Button>
        <Button
          variant="filled"
          color="greenColor"
          type="submit"
          className="mt-2 mt-sm-3"
        >
          Guardar
        </Button>
      </div>
    </form>
  );
}
