/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Form from "./Form";
import createURL from "../../../libs/url";
import peticionBack from "../../../libs/peticiones";
import { swalAction } from "../../../components/modals/ModalConfirm";
import CardMan from "../../../components/card/Card";
import { Button } from "@mantine/core";
import Datatable from "../../../components/datatable/Datatable";
import { colorRed } from "../../../core/constants";
import { SecretariosDTO } from "./dto/secretarios.dto";
import { Adscripcion } from "../../estructura-judicial/adscripciones/dto/adscripciones.dto";
import { useDispatch } from "react-redux";
import { loaded ,loading} from "../../../core/slicers/loading/LoadingSlicer";

export default function Secretarios() {
  const [refresh, setRefresh] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<SecretariosDTO | undefined>();
  const [adscripciones, setAdscripciones] = useState<Adscripcion | undefined>();

  const [setup, setSetup] = useState<any[]>([]);
  const [personal, setPersonal] = useState<any[]>([]);

  const dispatch = useDispatch();

  useEffect(()=>{
    getPersonal();
    getSetup();
    getAdscripciones();
  },[])

  useEffect(() => {
    if (personal.length > 0 && setup.length > 0) {
      setRefresh(true);
    }
  }, [personal, setup]);

  async function getData(
    order: string,
    records: number,
    page: number,
    search: string
  ) {
    if (personal.length < 1 && setup.length < 1) return null;
    const params: any = {
      pathname: `/secretarios`,
      searchParams: [
        { name: "records", value: records },
        { name: "page", value: page },
        { name: "search", value: search },
        { name: "rows", value: "_id,personal_id,setup_id" },
      ],
    };
    const url = createURL(params);
    const res = await peticionBack({}, url, "GET", false);
    if(res)
    res.data.forEach((a: SecretariosDTO) => {
      a.personalName = personal.find((e) => e._id === a.personal_id) || {
        name: "Sin Resultados",
      };
      a.setupName = setup.find((e) => e._id === a.setup_id) || {
        name: "Sin Resultados",
      };
    });
    return res;
  }

  async function getAdscripciones() {

    const res = await peticionBack({}, "/adscripciones", "GET", false);
    
    if (res) setAdscripciones(res.data);
  }

  async function getPersonal() {
    dispatch(loading());
    const res = await peticionBack({}, "/personal", "GET", false);
    dispatch(loaded());
    if (res) setPersonal(res.data);
  }

  async function getSetup() {
    dispatch(loading());
    const res = await peticionBack({}, "/setup-control-carga", "GET", false);
    dispatch(loaded());
    if (res) setSetup(res.data);
  }

  /* TABLA */
  const columns = [
    { data: "personalName.nombre" },
    { data: "setupName.name" },
    {
      data: null,
      render: (row: SecretariosDTO) => (
        <div className="d-flex justify-content-evenly">
          <FontAwesomeIcon
            icon={faPencil}
            className="text-warning cursor-pointer"
            onClick={() => openToEdit(row)}
          />
          <FontAwesomeIcon
            icon={faTrashCan}
            className="text-danger cursor-pointer"
            onClick={() =>
              swalAction(
                deleteRow,
                "Eliminar Secretario",
                `¿Desea eliminar el secretario ${row}?`,
                "question",
                row._id,
                "Eliminar"
              )
            }
          />
        </div>
      ),
    },
  ];

  const columnDefs = [
    { orderable: false, targets: [2] },
    { className: "text-center", targets: [0, 1] },
  ];

  async function deleteRow(id: number) {
    const res = await peticionBack({}, `/secretarios/${id}`, "DELETE", true);
    if (res) setRefresh(true); //
  }

  function openToEdit(row: SecretariosDTO) {
    setSelected(row);
  }

  useEffect(() => {
    if (selected) setOpen(true);
  }, [selected]);

  useEffect(() => {
    if (!open) {
      if (selected) setSelected(undefined);
    }
  }, [open]);

  return (
    <CardMan title="Secretarios" showMenu={false}>
      <div className="d-flex flex-column flex-md-row justify-content-md-between mb-2">
        <h4 className="">{open && (selected ? "Editar" : "Agregar")}</h4>
        {!open && (
          <Button
            color={colorRed}
            onClick={() => setOpen(true)}
            className="ml-2"
            style={{ marginRight: "13px", marginLeft: "13px" }}
          >
            Agregar
          </Button>
        )}
      </div>
      {open ? (
        <Form adscripciones={adscripciones} personal={personal} setOpen={setOpen} selected={selected} setup={setup} />
      ) : (
        <Datatable
          columns={columns}
          headers={["Nombre", "Setup", "Acciones"]}
          columnDefs={columnDefs}
          petition={getData}
          control="back"
          stateRefresh={[refresh, setRefresh]}
          className="p-0"
        />
      )}
    </CardMan>
  );
}
