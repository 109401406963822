import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Notifications } from '@mantine/notifications';
import { MantineProvider } from '@mantine/core';
import { Provider } from 'react-redux';
import { persistor, store } from './components/redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { theme } from './core/constants';
import App from './App';
import './index.css';
import '@mantine/dates/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/notifications/styles.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
   <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MantineProvider theme={theme}>
          <Notifications position='top-right' />
          <App />
        </MantineProvider>
      </PersistGate>
    </Provider>
   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
