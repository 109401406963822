import { pdf } from "@react-pdf/renderer";
import { getType } from "../../../libs/peticiones";
import Alert from "../../notification/Alert";

export async function generateReport(reportFormat: JSX.Element){
    try {
        const blob = await pdf(reportFormat).toBlob();
        const url = window.URL.createObjectURL(blob);
        return { blob, url }
    } catch (error: any) {
        const tipo = error.cause || 500;
        Alert(getType(tipo), error.message || "Ocurrio un problema al generar el reporte");
    }
    return null;
}