/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Col, Collapse, Row } from "react-bootstrap";
import InputSearch from "../../../components/inputs/InputSelectSearch";
import TablaModulos from "./TablaModulos";
import { Perfil } from "../perfiles/dto/perfiles.dto";
import { Sistemas } from "../sistemas/dto/sistemas.dto";
import { PerfilModulo } from "./dto/perfil-modulo.dto";
import { Modulo } from "../modulos/dto/modulos.dto";
import peticionBack from "../../../libs/peticiones";
import createURL from "../../../libs/url";
import CardMan from "../../../components/card/Card";
import toRecursive from "../../../libs/permissions";
import { Divider } from "@mantine/core";

export default function PerfilesModulos() {
  const [perfiles, setPerfiles] = useState<Perfil[]>([]);
  const [sistemas, setSistemas] = useState<Sistemas[]>([]);
  const [modulos, setModulos] = useState<any[]>([]);
  const [filteredModulos, setFilteredModulos] = useState<any[]>([]);
  const [permisosModulos, setPermisosModulos] = useState<PerfilModulo[] | any>(
    []
  );
  const [system, setSystem] = useState<string | undefined>();
  const [selected, setSelected] = useState<string | undefined>();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    getSistemas();
    getModulos();
    getPerfiles();
  }, []);

  async function getSistemas() {
    const res = await peticionBack({}, `/sistemas`, "GET", false);
    if (res) setSistemas(res.data);
  }

  async function getPerfiles() {
    const res = await peticionBack({}, `/perfiles`, "GET", false);
    if (res) setPerfiles(res.data);
  }

  async function getModulos() {
    const res = await peticionBack({}, "/modulos", "GET", false);
    if (res) {
      setModulos(toRecursive(res.data));
    }
  }

  async function getPermissions() {
    const params = {
      pathname: `/perfiles-modulos`,
      searchParams: [
        { name: "profile_id", value: selected as string },
        { name: "populate", value: "module_id" },
      ],
    };
    const route = createURL(params);
    const res = await peticionBack({}, route, "GET", false);
    if (res) {
      const actives = res.data.filter((d: any) => !!d.module_id);
      setPermisosModulos(actives);
      setFilteredModulos(modulos.filter((m: Modulo) => m.system_id === system));
      setOpen(true);
    }
  }

  useEffect(() => {
    setOpen(false); 
  }, [selected, system]);

  return (
    <CardMan title="Perfiles - Módulos" showMenu={false}>
      <Row>
        <Col xs={12} md={6}>
          <InputSearch
            label="Sistema"
            name="system"
            value={system || ""}
            values={sistemas}
            labelVal="name"
            valueVal="_id"
            onChangeMethod={({ target }: any) => {
              setSystem(target.value);
              setSelected(undefined);
            }}
          />
        </Col>
        <Col xs={12} md={6}>
          <InputSearch
            label="Perfil"
            name="perfil"
            value={selected || ""}
            values={perfiles.filter((p: Perfil) => p.system_id === system)}
            labelVal="name"
            valueVal="_id"
            onChangeMethod={({ target }: any) => setSelected(target.value)}
            disabled={!system}
          />
        </Col>
        <Col xs={12} className="pt-2 d-grid d-md-block text-center">
          <Button
            onClick={() => {
              getPermissions();
              setOpen(true);
            }}
          >
            Abrir
          </Button>
        </Col>
      </Row>
      <Divider my="md" size="sm" />
      <Collapse
        in={open}
        onExited={() => {
          setPermisosModulos([]);
        }}
      >
        <div>
          <div className="text-end">
            <Button variant="danger" onClick={() => setOpen(false)}>
              Cerrar
            </Button>
          </div>
          <TablaModulos
            id={selected as string}
            modulos={filteredModulos}
            permisos={permisosModulos}
          />
        </div>
      </Collapse>
    </CardMan>
  );
}
