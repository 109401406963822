/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Grid } from "@mantine/core";
import InputSearch from "../../../../components/inputs/InputSelectSearch";

export default function SelectedActorDemandado({
  actores,
  setSelectedActorDemandado,
  selectedActorDemandado,
  tipo,
}: {
  actores?: any;
  setSelectedActorDemandado: any;
  selectedActorDemandado: any;
  tipo: string;
}) {
  useEffect(() => {}, [actores]);
  return (
    <>
      <section>
        <Grid justify="space-around" grow>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <InputSearch
              label={tipo}
              name="partes_id"
              values={actores}
              value={selectedActorDemandado}
              labelVal="partes.nombre_busqueda"
              valueVal="partes_id"
              onChangeMethod={({ target }: any) => {
                setSelectedActorDemandado(target.value);
              }}
              required
            />
          </Grid.Col>
        </Grid>
      </section>
    </>
  );
}
