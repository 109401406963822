/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Tabs, rem } from '@mantine/core';
import {IconUserEdit, IconDisabled } from '@tabler/icons-react';
import Form from './Form';
import UnoAMuchos from '../../../components/Forms/Uno-a-muchos';
import { Divider } from 'rsuite';
import peticionBack from '../../../libs/peticiones';
import { useDispatch } from 'react-redux';
import { loaded, loading } from '../../../core/slicers/loading/LoadingSlicer';

export default function NavTop({
  setOpen,
  selected,
}: {
  setOpen: any;
  selected?: any;
}) {
  const iconStyle = { width: rem(12), height: rem(12) };

  const [selectedTab, setSelectedTab] = useState<string>('datosGenerales');
  const [showForm, setShowForm] = useState<boolean>(true);

  const [discapaciades, setDiscapacidades] = useState<any[]>([]);
  //const [alergias, setAlergias] = useState<any[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setShowForm(true);
    getDiscapacidades();
  }, [selected]);

  const handleTabChange = (value: string | null) => {
    if (value) {
      setSelectedTab(value);
    }
  };

  async function getDiscapacidades() {
    dispatch(loading());
    const res = await peticionBack({}, "/discapacidades", "GET", false);
    dispatch(loaded());
    if (res) setDiscapacidades(res.data);
  }

  const endpointValues ={
    ruta:"/discapacidades-personal",
    filtro:"personal_id",
    populate:"discapacidad_id",
    rows:"_id,discapacidad_id,personal_id",
    mapAditivos_id:"discapacidad_id",
    dataTabla:"discapacidad_id" //hay que pupular primero 
  }

  
  const objBase = {
    base_id: "personal_id",
    aditivo_id: "discapacidad_id",
    title:"Discapacidades"
  };

  const objAditivos = {
    label: "Discapacidades",
    name: "discapacidades",
    base:"personal"
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case 'datosGenerales':
        return showForm ? <Form setOpen={setOpen} selected={selected} /> : null;
      case 'discapacidades':
        return (<>
          <UnoAMuchos
            id={selected._id}
            endpointValues={endpointValues}
            objForm={objBase}
            objAditivos={objAditivos}
            selectValores={discapaciades}
          />
          <Divider/>
          </>
        );
      case 'settings':
        return <div>Settings tab content</div>;
      default:
        return null;
    }
  };

  return (
    <Tabs defaultValue="datosGenerales" onChange={handleTabChange}>
      <Tabs.List>
        <Tabs.Tab value="datosGenerales" leftSection={<IconUserEdit style={iconStyle} />}>
          Datos generales
        </Tabs.Tab>
        <Tabs.Tab
          value="discapacidades"
          leftSection={<IconDisabled style={iconStyle} />}
          disabled={!selected}
        >
          Discapacidades
        </Tabs.Tab>
      </Tabs.List>

      <Tabs>{renderTabContent()}</Tabs>
    </Tabs>
  );
}
