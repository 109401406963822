/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Pagination, NativeSelect, rem, Flex } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { Dispatch, SetStateAction, useEffect } from "react";

export default function Paging({
  currentPageItems = [],
  itemsPerPage = 0,
  currentPage = 0,
  setCurrentPage,
  items = 0,
  setItemsPerPage,
  lengthMenu,
  paging,
  offset = 0,
  setOffset,
  info,
}: {
  currentPageItems: Array<object>;
  itemsPerPage: number;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  items: number;
  setItemsPerPage: Dispatch<SetStateAction<number>>;
  lengthMenu: Array<{ l: number; v: number }>;
  paging: boolean;
  offset: number;
  setOffset: Dispatch<SetStateAction<number>>;
  info: boolean;
}) {
  // const [pagination, setPagination] = useState<Array<number>>([]);

  useEffect(() => {
    const newOffset = currentPage * itemsPerPage;
    if (newOffset !== offset) {
      setOffset(newOffset);
    }
    // const pages = Math.ceil(items / itemsPerPage);
    // const maxPages = Math.min(pages, 5);
    // const startPage = Math.max(
    //   Math.min(pages - maxPages, currentPage - Math.floor(maxPages / 2)),
    //   0
    // );
    // const endPage = Math.min(startPage + maxPages, pages);
    // const tempPages = Array.from(
    //   { length: endPage - startPage },
    //   (_, i) => i + startPage
    // );
  }, [currentPage, itemsPerPage, offset, items]);

  return (
    <Grid columns={12} className="mt-2">
      <Grid.Col span={{ base: 12, md: 6 }} >
        <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'xl' }} justify={{ base: "center", sm: 'left' }} >
          {info && (
            <div className="pt-1 text-center">
              Mostrando{" "}
              <b>
                {itemsPerPage * currentPage === 0
                  ? items > 0
                    ? 1
                    : 0
                  : itemsPerPage * currentPage + 1}
              </b>{" "}
              a{" "}
              <b>
                {paging
                  ? itemsPerPage * (currentPage + 1) <= items
                    ? itemsPerPage * (currentPage + 1)
                    : items
                  : items}
              </b>{" "}
              de <b>{items}</b> registros
            </div>
          )}
          {paging && (
            <Flex justify={{ base: "center", sm: 'left' }}>
              <NativeSelect
                rightSection={
                  <IconChevronDown style={{ width: rem(16), height: rem(16) }} />
                }
                size="xs"
                style={{ width: "78px" }}
                value={itemsPerPage}
                onChange={(e) => {
                  setItemsPerPage(Number(e.currentTarget.value));
                  setCurrentPage(0);
                }}
              >
                {lengthMenu.map((value, index) => (
                  <option value={value.v} key={`op${index}pg`}>
                    {value.l}
                  </option>
                ))}
              </NativeSelect>
            </Flex>
          )}
        </Flex>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 6 }}>
        <Flex direction="row" justify={{ base: "center", md: 'right' }}>
          <Pagination
            onPreviousPage={() => setCurrentPage(currentPage - 1)}
            onNextPage={() => setCurrentPage(currentPage + 1)}
            siblings={2}
            value={currentPage + 1}
            total={Math.ceil(items / itemsPerPage)}
            onChange={(e) => setCurrentPage(e - 1)}
          />
        </Flex>
      </Grid.Col>
    </Grid>
  );
}
