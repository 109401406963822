/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from "yup"; 
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import InputSearch from "../../../components/inputs/InputSelectSearch";

import { swalAction } from "../../../components/modals/ModalConfirm";
import peticionBack from "../../../libs/peticiones";
import { Button, Collapse, Divider, Grid } from "@mantine/core";
import UnoAMuchos from "../../../components/Forms/Uno-a-muchos";
import { JuzgadoresDTO } from "./dto/juzgadores.dto";

const defaultForm: JuzgadoresDTO = {
  personal_id: "",
  setup_id: "",
};

const formSchema = Yup.object().shape({
  personal_id: Yup.string().required("Seleccione un empleado"),
  setup_id: Yup.string().required("Seleccione un setup"),
});

export default function Form({
  setOpen,
  selected,
  adscripciones,
  personal,
  setup
}: {
  setOpen: any;
  selected?: any;
  adscripciones:any;
  personal:any;
  setup:any;
}) {


  const [formRendered, setFormRendered] = useState<any>(false);
  const [openAd, setOpenAd] = useState<boolean>(false);
  const [id, setId] = useState<any>();

  const endpointValues ={
    ruta:"/juzgadores-adcripciones",
    filtro:"juzgador_id",
    //populate:"materia_adscripcion_id",
    rows:"_id,juzgador_id,materia_adscripcion_id", 
    mapAditivos_id: "materia_adcripcion_idJ", // cambia si esta populado
    dataTabla:"nameAdscripcion.name", //hay que popular la adscripcion por el emomento se muestra su id dela adscripcion
    enabled:"si"
  }

  const objBase = {
    base_id: "juzgador_id",
    aditivo_id: "materia_adscripcion_id",
    title:"Juzgadores - Adscripciones"
  };


  const objAditivos = {
    label: "Adscripciones",
    name: "adscripciones",
    base:"juzgador"
  };


  const {
    handleSubmit,
    handleReset,
    handleBlur,
    setFieldValue,
    setValues,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: formSchema,
    onSubmit: (values) => {
      swalAction(
        save,
        `${selected ? "Editar" : "Guardar"} Secretario`,
        `¿Desea ${selected ? "editar" : "guardar"} este Secretario?`,
        "question"
      );
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (selected){
      setId(selected._id);
      setOpenAd(true);
      setValues(
        {
          personal_id: selected.personal_id,
          setup_id: selected.setup_id,
        },
        true
      );
      setTimeout(() => {
        setFormRendered(true);
      }, 0);
    }
  }, []);

  async function save() {
    const method = selected ? "PUT" : "POST";
    const rute = selected ? `/${selected._id}` : "";
    const res = await peticionBack(
      { ...values },
      "/juzgadores" + rute,
      method,
      true
    );
    if (res){
      setId(res._id);
      setOpenAd(true);
      setTimeout(() => {
        setFormRendered(true);
      }, 0);
    }
  }

  return (
    <>
    <form onSubmit={handleSubmit} onReset={handleReset}>
      <Grid justify="space-between" grow>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <InputSearch
            label="Personal"
            name="personal_id"
            value={values.personal_id}
            touched={touched.personal_id}
            error={errors.personal_id}
            values={personal}
            labelVal="nombre"
            valueVal="_id"
            onChangeMethod={({ target }: any) =>
              setFieldValue("personal_id", target.value)
            }
            onBlurMethod={handleBlur}
            required
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <InputSearch
            label="Setup"
            name="setup_id"
            value={values.setup_id}
            touched={touched.setup_id}
            error={errors.setup_id}
            values={setup}
            labelVal="name"
            valueVal="_id"
            onChangeMethod={({ target }: any) =>
              setFieldValue("setup_id", target.value)
            }
            onBlurMethod={handleBlur}
            required
          />
        </Grid.Col>
      </Grid>
      <div className="d-grid gap-2 d-md-flex justify-content-md-around mt-2">
        <Button variant="filled" color="redColor" type="reset" className="mt-3">
          Cancelar
        </Button>
        <Button
          variant="filled"
          color="greenColor"
          type="submit"
          className="mt-2 mt-sm-3"
        >
          Guardar
        </Button>
      </div>
    </form>
    <Divider size="md" my="md" />
      {formRendered && (
      <Collapse
        in={openAd}
        transitionDuration={400}
        transitionTimingFunction="linear"
      >
        <UnoAMuchos
          id={id}
          endpointValues={endpointValues}
          objForm={objBase}
          objAditivos={objAditivos}
          selectValores={adscripciones}
        />
      </Collapse>
      )}
    </>
  );
}
