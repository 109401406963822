import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

interface MapWithMarkerProps {
  center: google.maps.LatLngLiteral;
  markerPosition: google.maps.LatLngLiteral;
}
const gmap = process.env.REACT_APP_GMAP || "";

const MapWithMarker = ({ center, markerPosition }: MapWithMarkerProps) => {
  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  useEffect(() => {}, [markerPosition]);

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: gmap,
  });


  if (loadError) {
    return <div>Error al cargar el mapa: {loadError.message}</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <GoogleMap
      //onClick={handleMapClick}
      mapContainerStyle={mapContainerStyle}
      zoom={14}
      center={center}
    >
      <Marker key={uuidv4()} position={markerPosition} />
    </GoogleMap>
  );
};

export default MapWithMarker;
