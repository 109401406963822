/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Adscripcion } from "./dto/adscripciones.dto";
import peticionBack from "../../../libs/peticiones";
import { swalAction } from "../../../components/modals/ModalConfirm";
import InputTextMan from "../../../components/inputs/inputTextMan";
import InputSearch from "../../../components/inputs/InputSelectSearch";
import { InputNumber } from "../../../components/inputs/InputNumber";
import { Button, Collapse, Divider, Grid } from "@mantine/core";
import UnoAMuchos from "../../../components/Forms/Uno-a-muchos";

const defaultForm: Adscripcion = {
  name: "",
  tipo_id: "",
  edificio_id: "",
  prefix: "",
  shortname: "",
  telefono: "",
  correo: "",
  instancia:""
};

export default function Form({
  setOpen,
  selected,
  edificios,
  tipos,
  materias,
}: {
  setOpen: any;
  selected?: any;
  edificios: any[];
  tipos: any[];
  materias: any[];
}) {
  const [id, setId] = useState<any>();
  const [openAd, setOpenAd] = useState<boolean>(false);

  const [formRendered, setFormRendered] = useState<any>(false);

  const endpointValues = {
    ruta: "/materias-adscripciones",
    filtro: "adscripcion_id",
    populate: "materia_id",
    rows: "_id",
    mapAditivos_id: "materia",
    dataTabla: "materia.name",
    enabled: "si",
  };

  const objBase = {
    base_id: "adscripcion_id",
    aditivo_id: "materia_id",
    title: "Materias - Adscripciones",
  };

  const objAditivos = {
    label: "Materia",
    name: "materia",
    base: "adscripción",
  };

  const formSchema = Yup.object().shape({
    name: Yup.string().required("Ingrese el nombre"),
    shortname: Yup.string().required("Ingrese el nombre corto"),
    prefix: Yup.string().required("Ingrese un prefijo"),
    telefono: Yup.string().required("Ingrese un número telefónico"),
    correo: selected
      ? Yup.string().email("Correo electrónico invalido")
      : Yup.string()
          .required("Ingrese un correo electrónico")
          .email("Correo electrónico invalido"),
    tipo_id: Yup.string().required("Seleccione un tipo"),
    edificio_id: Yup.string().required("Seleccione un edificio"),
    // instancia: Yup.mixed<Instancia>()
    // .oneOf(Object.values(Instancia))
    // .required("Seleccione una instancia"),
    instancia: Yup.string().required("Seleccione una instancia"),
  });

  const {
    handleSubmit,
    handleReset,
    handleBlur,
    setFieldValue,
    setValues,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: formSchema,
    onSubmit: (values) =>
      swalAction(
        save,
        `${selected ? "Editar" : "Guardar"} Adscripción`,
        `¿Desea ${selected ? "editar" : "guardar"} esta Adscripción?`,
        "question"
      ),
    onReset: () => {
      setOpen(false);
    },
  });

  function onChange({ target }: { target: any }) {
    setFieldValue(target.name, target.value);
  }

  function handleTelefonoBlur() {
    handleBlur({ target: { name: "telefono" } });
  }

  useEffect(() => {
    if (selected) {
      setValues(
        {
          name: selected.name,
          tipo_id: selected.tipo_id,
          edificio_id: selected.edificio_id,
          prefix: selected.prefix,
          shortname: selected.shortname,
          telefono: selected.telefono,
          correo: selected.correo,
          instancia: selected.instancia
        },
        true
      );
      setId(selected._id);
      setOpenAd(true);
      setTimeout(() => {
        setFormRendered(true);
      }, 0);
    }
  }, []);

  async function save() {
    const method = selected ? "PUT" : "POST";
    const rute = selected ? `/${selected._id}` : "";

    const params = {
      name: values.name,
      tipo_id: values.tipo_id,
      edificio_id: values.edificio_id,
      prefix: values.prefix,
      shortname: values.shortname,
      telefono: `+52${values.telefono.toString()}`,
      correo: values.correo,
      instancia: values.instancia,
    };
    const res = await peticionBack(
      params,
      "/adscripciones" + rute,
      method,
      true
    );
    if (res) {
      setId(res._id);
      setOpenAd(true);
      setTimeout(() => {
        setFormRendered(true);
      }, 0);
    }
  }

  const Instancias = [{name:"PRIMERA INSTANCIA"},{name:"SEGUNDA INSTANCIA"},{name:"CENTROS DE CONVIVENCIA"},{name:"CENTROS DE MEDIACIÓN"},{name:"JUNTAS DE CONCILIACIÓN"}]

  return (
    <div>
      <form onSubmit={handleSubmit} onReset={handleReset}>
        <Grid justify="space-between" grow>
          <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
            <InputTextMan
              label="Nombre adscripción"
              name="name"
              type="text"
              value={values.name}
              touched={touched.name}
              error={errors.name}
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              required
              rightSection
            />
          </Grid.Col>

          <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
            <InputTextMan
              label="Nombre corto"
              name="shortname"
              type="text"
              value={values.shortname}
              touched={touched.shortname}
              error={errors.shortname}
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              required
              rightSection
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
            <InputTextMan
              label="Prefijo adscripción"
              name="prefix"
              type="text"
              value={values.prefix}
              touched={touched.prefix}
              error={errors.prefix}
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              required
              rightSection
            />
          </Grid.Col>

          <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
            <InputNumber
              label="Teléfono"
              name="telefono"
              value={values.telefono}
              touched={touched.telefono}
              error={errors.telefono}
              onChangeMethod={({ target }: any) =>
                setFieldValue("telefono", target.value)
              }
              onBlurMethod={handleTelefonoBlur}
              maxLength={10}
              required
              rightSection
              type="text"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
            <InputTextMan
              label="Correo electrónico"
              name="correo"
              type="text"
              value={values.correo}
              touched={touched.correo}
              error={errors.correo}
              onChangeMethod={onChange}
              onBlurMethod={handleBlur}
              required
              rightSection
            />
          </Grid.Col>

          <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
            <InputSearch
              label="Tipo"
              name="tipo_id"
              value={values.tipo_id}
              error={errors.tipo_id}
              touched={touched.tipo_id}
              values={tipos}
              labelVal="name"
              valueVal="_id"
              onChangeMethod={({ target }: any) =>
                setFieldValue("tipo_id", target.value)
              }
              onBlurMethod={handleBlur}
              required
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
            <InputSearch
              label="Edificio"
              name="edificio_id"
              value={values.edificio_id}
              error={errors.edificio_id}
              touched={touched.edificio_id}
              values={edificios}
              labelVal="name"
              valueVal="_id"
              onChangeMethod={({ target }: any) =>
                setFieldValue("edificio_id", target.value)
              }
              onBlurMethod={handleBlur}
              required
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
            <InputSearch
              label="Instancia"
              name="instancia"
              value={values.instancia}
              touched={touched.instancia}
              error={errors.instancia}
              // values={Object.entries(Instancia).map((value) => {
              //   return { value: value[1], key: value[0] };
              // })}
              // labelVal="value"
              // valueVal="value"
              values={Instancias}
              labelVal="name"
              valueVal="name"
              onChangeMethod={({ target }: any) =>
                setFieldValue("instancia", target.value)
              }
              onBlurMethod={handleBlur}
              required
            />
          </Grid.Col>
        </Grid>
        <div className="d-grid gap-2 d-md-flex justify-content-md-around mt-2">
          <Button
            variant="filled"
            color="redColor"
            type="reset"
            className="mt-3"
          >
            Salir
          </Button>
          <Button
            variant="filled"
            color="greenColor"
            type="submit"
            className="mt-2 mt-sm-3"
          >
            Guardar
          </Button>
        </div>
        <div className="d-grid gap-2 d-md-flex justify-content-md-around mt-2"></div>
      </form>
      <Divider size="md" my="md" />
      {formRendered && (
        <Collapse
          in={openAd}
          transitionDuration={400}
          transitionTimingFunction="linear"
        >
          <UnoAMuchos
            id={id}
            endpointValues={endpointValues}
            objForm={objBase}
            objAditivos={objAditivos}
            selectValores={materias}
          />
        </Collapse>
      )}
    </div>
  );
}
