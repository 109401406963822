import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Divider, Grid } from "@mantine/core";
import InputSearch from "../../../../../components/inputs/InputSelectSearch";
import { useDispatch } from "react-redux";
import {
    loaded,
    loading,
} from "../../../../../core/slicers/loading/LoadingSlicer";
import { swalAction } from "../../../../../components/modals/ModalConfirm";
import { RepresentantesLegales } from "./dto/representantesL.dto";
import InputTextMan from "../../../../../components/inputs/inputTextMan";
import { faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Datatable from "../../../../../components/datatable/Datatable";
import createURL from "../../../../../libs/url";
import peticionBack from "../../../../../libs/peticiones";
import { Sexo, Tipos_Defensa } from "../../../../../core/enums/enums";
import FormButtons from "../../../../../components/Forms/FormButtons";
import Alert from "../../../../../components/notification/Alert";
import { validarCurp, validarRFC } from "../../../../../libs/personalData";
import { IconEye } from "@tabler/icons-react";

const defaultForm: RepresentantesLegales = {
    partes_id: "",
    tipo_defensa: "",
    nombre: "",
    materno: "",
    paterno: "",
    curp: "",
    genero: "",
    rfc: "",
    cedula: "",
    email: "",
};

export default function RepresentantesLeg({
    canEdit,
    actorSeleccionado,
    setSelectedRep,
    tipo,
}: {
    canEdit: boolean;
    actorSeleccionado?: any;
    setSelectedRep?: any;
    tipo?: string;
}) {
    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState<boolean>(false);
    const formSchema = Yup.object().shape({
        nombre: Yup.string().required("Ingrese un nombre"),
        paterno: Yup.string().required("Ingrese un apellido paterno"),
        materno: Yup.string().required("Ingrese un apellido materno"),
        curp: Yup.string()
            .required("Ingrese el CURP")
            .test({
                name: "valid_curp",
                message: "CURP incorrecta",
                test: (value) => validarCurp(value),
            }),
        rfc: Yup.string()
            .required("Ingrese el RFC")
            .test({
                name: "valid_rfc",
                message: "RFC incorrecto",
                test: (value) => validarRFC(value),
            }),
        cedula: Yup.string().required("Ingrese una cédula"),
        email: Yup.string().required("Ingrese un correo"),
        tipo_defensa: Yup.mixed<Tipos_Defensa>()
            .oneOf(Object.values(Tipos_Defensa))
            .required("Seleccione un tipo de defensa"),
        genero: Yup.mixed<Sexo>()
            .oneOf(Object.values(Sexo))
            .required("Seleccione un tipo de número"),
    });

    useEffect(() => {
        setRefresh(true);
        if (actorSeleccionado === null) {
            handleReset(null);
        }
    }, [actorSeleccionado]);

    const {
        handleSubmit,
        handleReset,
        handleBlur,
        setFieldValue,
        setValues,
        values,
        errors,
        touched,
    } = useFormik({
        initialValues: {
            ...defaultForm,
            partes_id: actorSeleccionado as string,
        },
        validationSchema: formSchema,
        onSubmit: (values) => {
            if (actorSeleccionado === null) {
                Alert("info", `Seleccione un ${tipo}`);
                return;
            }
            swalAction(
                save,
                `${values._id ? "Editar" : "Guardar"} Representante`,
                `¿Desea ${
                    values._id ? "editar" : "guardar"
                } este Representante?`,
                "question"
            );
        },
        onReset: () => {},
    });

    function onChange({ target }: { target: any }) {
        setFieldValue(target.name, target.value);
    }

    function openToEdit(row: RepresentantesLegales) {
        if (
            JSON.stringify({ ...values, partes_id: "" }) !==
            JSON.stringify(defaultForm)
        ) {
            Alert("info", "Guarde sus cambios o limpie el formulario");
            return;
        }
        setValues(row);
    }

    async function deleteRow(id: string) {
        const res = await peticionBack(
            {},
            `/preregistro-partes/representantes/${id}`,
            "DELETE",
            true
        );
        if (res) {
            if (values._id) {
                handleReset(null);
                setRefresh(true);
            } else setRefresh(true);
        }
    }

    async function getData(
        order: string,
        records: number,
        page: number,
        search: string
    ) {
        if (!actorSeleccionado) return null;
        const params: any = {
            pathname: `/preregistro-partes/representantes`,
            searchParams: [
                { name: "order", value: order },
                { name: "records", value: records },
                { name: "page", value: page },
                { name: "search", value: search },
                { name: "partes_id", value: actorSeleccionado },
                {
                    name: "rows",
                    value: "nombre,paterno,materno,curp,genero,partes_id,tipo_defensa,rfc,cedula,email,nombre_busqueda,_id",
                },
            ],
        };
        const url = createURL(params);
        const res = await peticionBack({}, url, "GET", false);
        if (res.data && res.data.length > 0) {
            res.data.forEach((d: RepresentantesLegales) => {
                if (!d.nombre_busqueda)
                    d.nombre_busqueda = `${d.nombre} ${d.paterno} ${d.materno}`;
            });
        }
        setSelectedRep(res.data);
        if (res) return res;
        setRefresh(true);
    }

    async function save() {
        dispatch(loading("backdrop"));
        const method = values._id ? "PUT" : "POST";
        const rute = values._id ? `/${values._id}` : "";
        const params = {
            ...values,
            id: undefined,
            partes_id: actorSeleccionado,
        };
        const res = await peticionBack(
            params,
            "/preregistro-partes/representantes" + rute,
            method,
            true
        );
        if (res) {
            handleReset(null);
            setRefresh(true);
        }
        dispatch(loaded());
    }

    /* TABLA */
    const columns = [
        { data: "nombre" },
        { data: "paterno" },
        { data: "materno" },
        {
            data: null,
            render: (row: any) => (
                <div className="d-flex justify-content-evenly">
                    {canEdit ? (
                        <IconEye
                            className="text-info cursor-pointer"
                            onClick={() => openToEdit(row)}
                        />
                    ) : (
                        <>
                            <FontAwesomeIcon
                                icon={faPencil}
                                className="text-warning cursor-pointer"
                                onClick={() => openToEdit(row)}
                            />
                            <FontAwesomeIcon
                                icon={faTrashCan}
                                className="text-danger cursor-pointer"
                                onClick={() =>
                                    swalAction(
                                        deleteRow,
                                        "Eliminar Representante",
                                        `¿Desea eliminar el representante ${row.nombre_busqueda}?`,
                                        "question",
                                        row._id,
                                        "Eliminar"
                                    )
                                }
                            />
                        </>
                    )}
                </div>
            ),
        },
    ];

    const headers = ["Nombre", "Paterno", "Materno", "Acciones"];
    const columnsDef = [
        { orderable: false, targets: [3] },
        { className: "text-center", targets: [0, 1, 2] },
    ];

    return (
        <section>
            <Grid justify="space-around" grow>
                <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                    <InputSearch
                        label="Tipo Defensa"
                        name="tipo_defensa"
                        value={values.tipo_defensa}
                        error={errors.tipo_defensa}
                        touched={touched.tipo_defensa}
                        values={Object.entries(Tipos_Defensa).map((value) => {
                            return { value: value[0], key: value[1] };
                        })}
                        labelVal="value"
                        valueVal="key"
                        onChangeMethod={({ target }: any) =>
                            setFieldValue("tipo_defensa", target.value)
                        }
                        required
                        disabled={canEdit}
                    />
                </Grid.Col>
            </Grid>
            <Grid justify="space-around" grow>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                    <InputTextMan
                        label="Apellido Paterno"
                        name="paterno"
                        type="text"
                        value={values.paterno}
                        touched={touched.paterno}
                        error={errors.paterno}
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        required
                        rightSection
                        disabled={canEdit}
                    />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                    <InputTextMan
                        label="Apellido Materno"
                        name="materno"
                        type="text"
                        value={values.materno}
                        touched={touched.materno}
                        error={errors.materno}
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        required
                        rightSection
                        disabled={canEdit}
                    />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                    <InputTextMan
                        label="Nombre"
                        name="nombre"
                        type="text"
                        value={values.nombre}
                        touched={touched.nombre}
                        error={errors.nombre}
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        required
                        rightSection
                        disabled={canEdit}
                    />
                </Grid.Col>
            </Grid>
            <Grid justify="space-around" grow>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                    <InputSearch
                        label="Género"
                        name="genero"
                        value={values.genero}
                        error={errors.genero}
                        touched={touched.genero}
                        values={Object.entries(Sexo).map((value) => {
                            return { value: value[0], key: value[1] };
                        })}
                        labelVal="value"
                        valueVal="key"
                        onChangeMethod={({ target }: any) =>
                            setFieldValue("genero", target.value)
                        }
                        required
                        disabled={canEdit}
                    />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                    <InputTextMan
                        label="CURP"
                        name="curp"
                        type="text"
                        value={values.curp}
                        touched={touched.curp}
                        error={errors.curp}
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        maxLength={18}
                        required
                        rightSection
                        disabled={canEdit}
                    />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                    <InputTextMan
                        label="RFC"
                        name="rfc"
                        type="text"
                        value={values.rfc}
                        touched={touched.rfc}
                        error={errors.rfc}
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        maxLength={13}
                        required
                        rightSection
                        disabled={canEdit}
                    />
                </Grid.Col>
            </Grid>
            <Grid justify="space-around" grow>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                    <InputTextMan
                        label="Cédula"
                        name="cedula"
                        type="text"
                        value={values.cedula}
                        touched={touched.cedula}
                        error={errors.cedula}
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        required
                        rightSection
                        disabled={canEdit}
                    />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                    <InputTextMan
                        label="E-mail notificación"
                        name="email"
                        type="text"
                        value={values.email}
                        touched={touched.email}
                        error={errors.email}
                        onChangeMethod={onChange}
                        onBlurMethod={handleBlur}
                        required
                        rightSection
                        disabled={canEdit}
                    />
                </Grid.Col>
            </Grid>

            <FormButtons
                cancel={{
                    msg: "Limpiar",
                    type: "button",
                    color: "red",
                    variant: "light",
                    click: handleReset,
                }}
                save={{
                    msg: "Guardar representante",
                    type: "button",
                    color: "teal",
                    variant: "light",
                    click: handleSubmit,
                    hidden: canEdit,
                }}
            />

            <Divider my="md" />

            <Datatable
                columns={columns}
                columnDefs={columnsDef}
                headers={headers}
                control="back"
                petition={getData}
                stateRefresh={[refresh, setRefresh]}
            />
        </section>
    );
}