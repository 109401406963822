import AcusePreregistro from "./formats/AcusePreregistro";
import { generateReport } from "./generateReport";

export default async function getReportPDF(report: number | string, data: any, extra?: object){
    const reportFormat = findReport(report, data, extra);
    return await generateReport(reportFormat);
}

function findReport(report: number | string, data: any, extra?: object) {
    switch(report){
        /* PREREGISTRO */
        case "acuse demanda": return AcusePreregistro(data);
        default: throw new Error("El reporte seleccionado no existe", { cause: 404 });
    }
}