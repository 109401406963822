import { Modulo } from "../core/slicers/permisos/interfaces/permisosInterface";

export default function toRecursive(modules: Modulo[]) {
  const copy = JSON.parse(JSON.stringify(modules)) as Modulo[];
  copy.forEach((modulo) => {
    if (Object.hasOwn(modulo, "parent_id")) {
      const finded = copy.find((module) => {
        return module._id === modulo.parent_id;
      });
      if (finded) {
        if (finded.children === undefined) {
          finded.children = [];
        }
        finded.children.push(modulo);
      }
    }
  });
  return copy.filter((modulo) => !modulo.parent_id);
}
