/* eslint-disable react-hooks/exhaustive-deps */
import { Select, Tabs, Text, rem } from "@mantine/core";
import CardMan from "../../components/card/Card";
import { useEffect, useState } from "react";
import { IconFileReport } from "@tabler/icons-react";
import Demanda from "./tabs/demanda/Index";
import ActorDemandado from "./tabs/actores-demandados/Index";
import Envio from "./tabs/envio/Index";
import { OficialiaSelected, Preregistro as PreRegistro } from "./dto/preregistro";
import peticionBack from "../../libs/peticiones";
import { useDispatch } from "react-redux";
import { loaded, loading } from "../../core/slicers/loading/LoadingSlicer";
import FormButtons from "../../components/Forms/FormButtons";
import { Tipos_partes } from "../../core/enums/enums";
import Alert from "../../components/notification/Alert";

export default function Preregistro() {
    const [activeTab, setActiveTab] = useState<string | null>("Demanda");
    const [selected, setSelected] = useState<PreRegistro | null>(null);
    const [oficialia, setOficialia] = useState< OficialiaSelected | null>(null);
    const [oficialias, setOficialias] = useState<any[]>([]);
    const [open, setOpen] = useState<boolean>(true);
    const [complete, setComplete] = useState<boolean>(false);
    const [typDoc, setTypDoc] = useState<string>("");
    const [acuse, setAcuse] = useState<string>("");
    const dispatch = useDispatch();

    useEffect(() => {
        getOficialiasAdscripcion();
        getTipoDoc("DOCUMENTACION%20DEMANDA", setTypDoc);
        getTipoDoc("ACUSE%20DEMANDA", setAcuse);
    }, []);

    async function getOficialiasAdscripcion() {
        dispatch(loading("backdrop"));
        const res = await peticionBack(
            {},
            "/oficialia-adscripcion?populate=oficialia_id%2Cmateria_id",
            "GET",
            false
        );
        if (res) {
            const oficialiaMaterias: any = {};
            res.data.forEach((oa: any) => {
                if (!Object.keys(oficialiaMaterias).includes(oa.oficialia_id)) {
                    oficialiaMaterias[oa.oficialia_id] = {
                        value: oa._id,
                        label: oa.oficialia.name,
                        materias: [oa.materia._id],
                    };
                } else {
                    oficialiaMaterias[oa.oficialia_id].materias.push(
                        oa.materia._id
                    );
                }
            });
            setOficialias(Object.values(oficialiaMaterias));
        }
        dispatch(loaded());
    }

    function showForm() {
        if (oficialia) setOpen(true);
    }

    useEffect(() => {
        setOpen(false);
        if (!!selected) setSelected(null);
        if (!oficialia) setActiveTab("Demanda");
    }, [oficialia]);

    function changeTab(tab: string | null){
        if(tab === null) setActiveTab("Demanda");
        switch(tab){
            case "Demandados":
                getDomiciliosPertes("ACTOR", true);
                setActiveTab(tab);
                break;
            case "Envio":
                checkPreregistro();
                break;
            default: 
                setActiveTab(tab);
        }
    }


    async function getDomiciliosPertes(type: keyof typeof Tipos_partes, redirect?: boolean){
        if(selected?.enviado) return true;
        dispatch(loading("backdrop"));
        const route = `/preregistro-partes/domicilios/carpeta_judicial?carpetas_judiciales_id=${selected?._id}&tipos_parte=${type}`;
        const res = await peticionBack({}, route, "GET", false);
        dispatch(loaded());
        if (res && res.data && res.data.length > 0) {
            const itsOk = (res.data as Array<any>).some((d: any) => d.partes.domicilios && d.partes.domicilios.length > 0);
            if(itsOk){
                if(redirect && type === "ACTOR") setActiveTab("Demandados");
                else return true;
            }else Alert("warning", "No se han registrado todos los domicilios de los actores");
        }else{
            Alert("warning", "No se han agregado actores a la demanda");
        }
        return false;
    }

    async function checkPreregistro(){
        if(complete){
            setActiveTab("Envio");
            return;
        }
        const [actores, demandados, demanda] = await Promise.all([
            getDomiciliosPertes("ACTOR"), 
            getDomiciliosPertes("DEMANDADO"), 
            getPreregistro()
        ]);
        if(actores && demandados && !!demanda){
            setComplete(true);
            setActiveTab("Envio");
            setSelected((s) => ({
                ...s,
                anio: demanda.anio,
                numero: demanda.numero,
                enviado: true
            }) as PreRegistro);
        }else{
            setActiveTab("Envio");
            Alert("info", `Nose han comletado toda la informacion necesaria`);
        }
    }

    async function getPreregistro(): Promise<false | PreRegistro>{
        const res = await peticionBack({}, "/preregistro-inicial/" + selected?._id, "GET", false);
        if(res && res.enviado) return res as PreRegistro;
        return false;
    }

    async function getTipoDoc(doc: string, hook: Function) {
        dispatch(loading());
        const res: any = await peticionBack({}, "/tipo-archivos?name="+doc, "GET", false);
        if (res && res.data && res.data.length > 0) {
            hook(res.data[0]._id);
        }
        dispatch(loaded());
    }

    return (
        <CardMan title="Pre-registro" icon={<Icon />}>
            <div className="text-center">
                <Select
                    label="Oficialía"
                    name="oficia"
                    data={oficialias}
                    onChange={(_value, option) => setOficialia(option as OficialiaSelected)}
                    value={oficialia ? oficialia.value : null}
                    clearable
                    allowDeselect={false}
                />
                <FormButtons
                    save={{
                        color: "cyan",
                        click: () => {
                            showForm();
                            if (!oficialia)
                                Alert("info", `Seleccione una oficialía`);
                        },
                        msg: "Seleccionar",
                    }}
                    cancel={{
                        color: "red",
                        click: () => setOficialia(null),
                        msg: "Regresar",
                        hidden: !open,
                    }}
                    reverse
                />
            </div>
            {!!oficialia && open && (
                <Tabs value={activeTab} onChange={(val) => changeTab(val)} color="primary">
                    {!selected ? (
                        <Tabs.List grow justify="space-between">
                            <Tabs.Tab value="Demanda">
                                <Text fw="bold">
                                    Registro de demandas iniciales
                                </Text>
                            </Tabs.Tab>
                        </Tabs.List>
                    ) : (
                        <Tabs.List grow justify="space-between">
                            <Tabs.Tab value="Demanda">
                                <Text fw="bold" pb={0}>
                                    Demanda
                                </Text>
                                <Text p={0}>Demanda Inicial</Text>
                            </Tabs.Tab>
                            <Tabs.Tab value="Actores">
                                <Text fw="bold" pb={0}>
                                    Paso 1
                                </Text>
                                <Text p={0}>Captura de Actor(es)</Text>
                            </Tabs.Tab>
                            <Tabs.Tab value="Demandados">
                                <Text fw="bold" pb={0}>
                                    Paso 2
                                </Text>
                                <Text p={0}>Captura de Demandado(s)</Text>
                            </Tabs.Tab>
                            <Tabs.Tab value="Envio">
                                <Text fw="bold" pb={0}>
                                    Paso 3
                                </Text>
                                <Text p={0}>Envio de Demanda inicial</Text>
                            </Tabs.Tab>
                        </Tabs.List>
                    )}

                    <Tabs.Panel value="Demanda">
                        <Demanda
                            selected={selected}
                            setSelected={setSelected}
                            oficialia={oficialia}
                            setComplete={setComplete}
                            typDoc={typDoc}
                        />
                    </Tabs.Panel>
                    <Tabs.Panel value="Actores">
                        <ActorDemandado
                            selectedFull={selected}
                            tipo={Tipos_partes.ACTOR}
                        />
                    </Tabs.Panel>
                    <Tabs.Panel value="Demandados">
                        <ActorDemandado
                            selectedFull={selected}
                            tipo={Tipos_partes.DEMANDADO}
                        />
                    </Tabs.Panel>
                    <Tabs.Panel value="Envio">
                        <Envio selected={selected} setSelected={setSelected} typDoc={typDoc} oficialia={oficialia} activeTab={activeTab} acuse={acuse} />
                    </Tabs.Panel>
                </Tabs>
            )}
        </CardMan>
    );
}

function Icon() {
    return (
        <IconFileReport
            style={{ width: rem(40), height: rem(40) }}
            stroke={1.5}
            color="var(--mantine-color-blue-filled)"
        />
    );
}
