import { Input, PasswordInput, rem } from "@mantine/core";
import { IconPencilExclamation } from "@tabler/icons-react";

export function InputPassword({
  label,
  name,
  onChangeMethod,
  onBlurMethod,
  value,
  error,
  touched,
  disabled,
  prefix,
  maxLength,
  required,
}: {
  label: string;
  name: string;
  onChangeMethod: any;
  onBlurMethod: any;
  value: string;
  error?: string;
  touched?: boolean;
  disabled?: boolean;
  prefix?: string;
  maxLength?: number;
  required?: boolean;
}) {
  return (
    <>
      {label && (
        <Input.Label htmlFor={name}>
          {label} {required && <span className="text-danger">*</span>}
        </Input.Label>
      )}
      <PasswordInput
        onBlur={onBlurMethod}
        value={value}
        prefix={prefix ? prefix : undefined}
        disabled={disabled ? true : false}
        error={touched && !!error}
        onChange={onChangeMethod}
        maxLength={maxLength}
        rightSection={
          touched && !!error ? (
            <IconPencilExclamation
              style={{ width: rem(20), height: rem(20) }}
              color="var(--mantine-color-error)"
            />
          ) : null
        }
      />
      {touched && !!error && <Input.Error>{error}</Input.Error>}
    </>
  );
}
