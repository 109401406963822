/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { v4 as uuidv4 } from "uuid";

interface MapWithMarkerProps {
  center: google.maps.LatLngLiteral;
  markerPosition?: google.maps.LatLngLiteral;
  onMarkerClick?: (position: google.maps.LatLngLiteral) => void;
  setValues?: any;
  values: any;
  showMarker:boolean;
  setShowMarker:any
  canEdit?:boolean
}

const gmap = process.env.REACT_APP_GMAP || "";

const MapWithMarker2: React.FC<MapWithMarkerProps> = ({ center, markerPosition, onMarkerClick, setValues, values,showMarker, setShowMarker, canEdit}) => {
  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  const [internalMarkerPosition, setInternalMarkerPosition] = useState({
    lat: 0,
    lng: 0,
  });

  useEffect(() => {
    setInternalMarkerPosition({
      lat: values?.latitud || 0,
      lng: values?.longitud || 0,
    });
    setShowMarker(!!markerPosition);
  }, [markerPosition]);

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
   if(!canEdit){
     if (onMarkerClick) {
      const newPosition = {
        lat: event.latLng?.lat() || 0,
        lng: event.latLng?.lng() || 0,
      };

      setValues((values:any) => ({
        ...values,
        latitud: newPosition.lat,
        longitud: newPosition.lng,
      }));

      setInternalMarkerPosition(newPosition);
      onMarkerClick(newPosition);
    }
   }
  };

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: gmap,
  });

  if (loadError) {
    return <div>Error al cargar el mapa: {loadError.message}</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <GoogleMap
      onClick={handleMapClick}
      mapContainerStyle={mapContainerStyle}
      zoom={14}
      center={center}
    >
      {showMarker && <Marker key={uuidv4()} position={internalMarkerPosition} />}
    </GoogleMap>
  );
};

export default MapWithMarker2;
