import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound from "./pages/error/404";
import global from "./core/routes/global";
import Login from "./pages/auth/Login";
import Inicio from "./pages/inicio/inicio";
import Layout from "./components/layout/Layout";

export default function Router() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/auth" element={<Login />} />
          <Route path="/" element={<Inicio />} />
          {global.map((elm) => (
            <Route key={elm.route} path={elm.route} element={<elm.component />} />
          ))}
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}
