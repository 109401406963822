import { createTheme, MantineColorsTuple } from "@mantine/core";

export const colorRed = "#a42f2f";

const primary: MantineColorsTuple = [
  "#ffeded",
  "#f4dada",
  "#e6b3b3",
  "#da8988",
  "#ce6564",
  "#c84e4d",
  "#c54342",
  "#ae3534",
  "#9d2d2d",
  "#8a2325"
];

const greenColor: MantineColorsTuple = [
  "#f0f8f9",
  "#e4edef",
  "#c2dadf",
  "#9dc6cf",
  "#7fb6c2",
  "#6cabb9",
  "#61a6b6",
  "#5090a0",
  "#438190",
  "#2f707e",
];

const redColor: MantineColorsTuple = [
  "#feeef4",
  "#f2dde4",
  "#e1b8c7",
  "#d191a8",
  "#c3718d",
  "#bb5b7c",
  "#b85074",
  "#a24163",
  "#913857",
  "#812d4b",
];

const grayColor: MantineColorsTuple = [
  "#f5f5f5",
  "#e7e7e7",
  "#cdcdcd",
  "#b2b1b1",
  "#9a9a9a",
  "#8b8b8b",
  "#848484",
  "#717171",
  "#656565",
  "#585657",
];

const azulVerde: MantineColorsTuple = [
  "#eff8fb",
  "#e1eff2",
  "#bbdde5",
  "#95cbd9",
  "#76bcce",
  "#63b3c8",
  "#57aec5",
  "#4898ae",
  "#3a889c",
  "#247689",
];

export const theme = createTheme({
  colors: {
    primary,
    greenColor,
    redColor,
    grayColor,
    azulVerde
  },
});
