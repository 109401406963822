/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Checkbox, Divider, Grid, Group, Text } from "@mantine/core";
import InputSearch from "../../../../../components/inputs/InputSelectSearch";
import { useDispatch } from "react-redux";
import { swalAction } from "../../../../../components/modals/ModalConfirm";
import InputTextMan from "../../../../../components/inputs/inputTextMan";
import { InputNumber } from "../../../../../components/inputs/InputNumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Datatable from "../../../../../components/datatable/Datatable";
import createURL from "../../../../../libs/url";
import { DomiciliosDTO } from "./dto/domicilios.dto";
import InputAreaForm from "../../../../../components/inputs/inputArea";
import {
    loaded,
    loading,
} from "../../../../../core/slicers/loading/LoadingSlicer";
import peticionBack from "../../../../../libs/peticiones";
import { fromAddress, setDefaults } from "react-geocode";
import MapWithMarker2 from "../../../../../components/maps/MapsG";
import Alert from "../../../../../components/notification/Alert";
import { IconEye } from "@tabler/icons-react";

const defaultForm: DomiciliosDTO = {
    partes_id: "",
    municipio_id: "",
    notificacion: false,
    calle: "",
    num_exterior: "",
    num_interior: "",
    colonia: "",
    manzana: "",
    cp: "",
    longitud: 0,
    latitud: 0,
    observaciones: "",
};

export default function Domicilios({
    tipo,
    actorSeleccionado,
    canEdit,
}: {
    tipo: string;
    actorSeleccionado: any;
    canEdit: boolean;
}) {
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [municipios, setMunicipios] = useState<any[]>([]);
    const [showMarker, setShowMarker] = useState(false);
    const gmap = process.env.REACT_APP_GMAP || "";
    // const [actorSeleccionado, setActoreSeleccionado] = useState<string | null>(
    //   null
    // );
    const [coordenada, setCoordenada] = useState<any>({
        lat: 0,
        lng: 0,
        rowExist: false,
    });

    const formSchema = Yup.object().shape({
        municipio_id: Yup.string().required("Seleccione un municipio"),
        //partes_id: Yup.string().required("Seleccione un actor"),
        calle: Yup.string().required("Ingrese una calle"),
        colonia: Yup.string().required("Ingrese una una colonia"),
        num_exterior: Yup.string().required("Ingrese el numero exterior"),
        cp: Yup.string()
            .max(5, "La longitud máxima es 5 digitos")
            .required("Ingrese un código postal"),
    });

    useEffect(() => {
        setRefresh(true);
        if (actorSeleccionado === null) {
            handleReset(null);
        }
    }, [actorSeleccionado]);

    useEffect(() => {
        getMunicipios();
        setDefaults({
            key: gmap,
            language: "es",
            region: "mx",
        } as any);
    }, []);

    const [mapCenter, setMapCenter] = useState({
        lat: 20,
        lng: -100,
    });
    const [markerPosition, setMarkerPosition] = useState({
        lat: 20,
        lng: -100,
    });

    const {
        handleSubmit,
        handleReset,
        handleBlur,
        setFieldValue,
        setValues,
        values,
        errors,
        touched,
    } = useFormik({
        initialValues: {
            ...defaultForm,
            partes_id: actorSeleccionado as string,
        },
        validationSchema: formSchema,
        onSubmit: (values) => {
            if (actorSeleccionado === null) {
                Alert("info", `Seleccione un ${tipo}`);
                return;
            }
            swalAction(
                save,
                `${values._id ? "Editar" : "Guardar"} Domicilio`,
                `¿Desea ${values._id ? "editar" : "guardar"} este Domicilio?`,
                "question"
            );
        },
        onReset: () => {
            setChecked(false);
            setCoordenada({ lat: 0, lng: 0, rowExist: false });
            setShowMarker(false);
            setMapCenter({
                lat: 20,
                lng: -100,
            });
        },
    });

    function onChange({ target }: { target: any }) {
        setFieldValue(target.name, target.value);
    }

    useEffect(() => {
        setChecked(values?.notificacion || false);
        handleFormSubmit();
    }, [values.cp]);

    async function save() {
        const method = values._id ? "PUT" : "POST";
        const rute = values._id ? `/${values._id}` : "";
        dispatch(loading("backdrop"));

        const params = {
            ...values,
            partes_id: actorSeleccionado,
            notificacion: checked,
            cp: values.cp.toString(),
            num_exterior: values.num_exterior.toString(),
            num_interior: values.num_interior.toString(),
            id: undefined,
        };
        const res = await peticionBack(
            params,
            "/preregistro-partes/domicilio" + rute,
            method,
            true
        );
        if (res) {
            handleReset(null);
            setCoordenada({ lat: 0, lng: 0, rowExist: false });
            setShowMarker(false);
            setMapCenter({
                lat: 20,
                lng: -100,
            });
            setRefresh(true);
        }
        dispatch(loaded());
    }

    function handleNeBlur() {
        handleBlur({ target: { name: "num_extertor" } });
    }

    function handleNiBlur() {
        handleBlur({ target: { name: "num_interior" } });
    }
    function handleCPBlur() {
        handleBlur({ target: { name: "cp" } });
    }

    /* TABLA */

    async function getData(
        order: string,
        records: number,
        page: number,
        search: string
    ) {
        if (!actorSeleccionado) return null;
        const params: any = {
            pathname: `preregistro-partes/domicilio`,
            searchParams: [
                { name: "order", value: order },
                { name: "records", value: records },
                { name: "page", value: page },
                { name: "search", value: search },
                { name: "partes_id", value: actorSeleccionado },
                {
                    name: "rows",
                    value: "partes_id,municipio_id,notificacion,calle,num_exterior,num_interior,colonia,manzana,cp,observaciones,_id,latitud,longitud",
                },
            ],
        };
        const url = createURL(params);
        const res = await peticionBack({}, url, "GET", false);
        res.data.forEach((a: any) => {
            a.municipio = municipios.find((e) => e._id === a.municipio_id) || {
                name: "Sin asignar",
            };
        });
        return res;
    }

    const columns = [
        { data: "municipio.name" },
        { data: "colonia" },
        { data: "calle" },
        { data: "num_exterior" },
        { data: "observaciones" },
        {
            data: null,
            render: (row: any) => (
                <div className="d-flex justify-content-evenly">
                    {canEdit ? (
                        <IconEye
                            className="text-info cursor-pointer"
                            onClick={() => openToEdit(row)}
                        />
                    ) : (
                        <>
                            <FontAwesomeIcon
                                icon={faPencil}
                                className="text-warning cursor-pointer"
                                onClick={() => openToEdit(row)}
                            />

                            <FontAwesomeIcon
                                icon={faTrashCan}
                                className="text-danger cursor-pointer"
                                onClick={() =>
                                    swalAction(
                                        deleteRow,
                                        "Eliminar Domicilio",
                                        `¿Desea eliminar el domicilio?`,
                                        "question",
                                        row._id,
                                        "Eliminar"
                                    )
                                }
                            />
                        </>
                    )}
                </div>
            ),
        },
    ];

    function openToEdit(row: DomiciliosDTO) {
        if (coordenada.rowExist) {
            Alert("info", "Guarde sus cambios o limpie el formulario");
            return;
        }
        if (
            JSON.stringify({ ...values, partes_id: "" }) !==
            JSON.stringify(defaultForm)
        ) {
            Alert("info", "Guarde sus cambios o limpie el formulario");
            return;
        }
        setValues(row);
        setCoordenada({ lat: row.latitud, lng: row.longitud, rowExist: true });
    }

    async function deleteRow(id: any) {
        dispatch(loading("backdrop"));
        const res = await peticionBack(
            {},
            `/preregistro-partes/domicilio/${id}`,
            "DELETE",
            true
        );
        if (res) {
            dispatch(loaded());

            if (coordenada.rowExist) handleReset(null);
            setRefresh(true);
        }
    }

    const columnDefs = [
        { orderable: false, targets: [0, 5] },
        { className: "text-center", targets: [5] },
    ];

    async function getMunicipios() {
        dispatch(loading());
        const res = await peticionBack(
            {},
            "/municipios",
            "GET",
            false
        );
        if (res) setMunicipios(res.data);

        dispatch(loaded());
    }

    const handleFormSubmit = async () => {
        const fullAddress = `${
            values.calle
        } ${values.num_exterior.toString()}, ${values.colonia}, ${
            municipios.find(({ id }) => id === values.municipio_id)?.name
        }, ${values.cp.toString()}`;
        const handleLocationChange = (lat: any, lng: any) => {
            setValues((prevValues) => ({
                ...prevValues,
                latitud: lat,
                longitud: lng,
            }));
        };
        fromAddress(fullAddress)
            .then(({ results }) => {
                const { lat, lng } = results[0].geometry.location;
                setMapCenter({ lat, lng });
                setMarkerPosition({ lat, lng });
                handleLocationChange(
                    coordenada.rowExist ? coordenada.lat : lat,
                    coordenada.rowExist ? coordenada.lng : lng
                );
            })
            .catch(console.error);
    };

    const handleMarkerClick = (position: any) => {};

    return (
        <>
            <section>
                <form onSubmit={handleSubmit} onReset={handleReset}>
                    <Grid justify="space-around" grow>
                        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                            <InputSearch
                                label="Municipio Domicilio"
                                name="municipio_id"
                                value={
                                    values.municipio_id !== ""
                                        ? values.municipio_id
                                        : null
                                }
                                touched={touched.municipio_id}
                                error={errors.municipio_id}
                                values={municipios}
                                labelVal="name"
                                valueVal="_id"
                                onChangeMethod={({ target }: any) =>
                                    setFieldValue("municipio_id", target.value)
                                }
                                onBlurMethod={handleBlur}
                                required
                                disabled={canEdit}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                            <InputTextMan
                                label="Calle"
                                name="calle"
                                type="text"
                                value={values.calle}
                                touched={touched.calle}
                                error={errors.calle}
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                required
                                rightSection
                                disabled={canEdit}
                            />
                        </Grid.Col>
                    </Grid>
                    <Grid justify="space-around" grow>
                        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                            <InputTextMan
                                label="Colonia"
                                name="colonia"
                                type="text"
                                value={values.colonia}
                                touched={touched.colonia}
                                error={errors.colonia}
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                required
                                rightSection
                                disabled={canEdit}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                            <InputTextMan
                                label="Manzana o Lote"
                                name="manzana"
                                type="text"
                                value={values.manzana}
                                touched={touched.manzana}
                                error={errors.manzana}
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                rightSection
                                disabled={canEdit}
                            />
                        </Grid.Col>
                    </Grid>
                    <Grid justify="space-around" grow>
                        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                            <InputNumber
                                label="Número Exterior"
                                name="num_exterior"
                                type="text"
                                value={values.num_exterior}
                                touched={touched.num_exterior}
                                error={errors.num_exterior}
                                onChangeMethod={({ target }: any) =>
                                    setFieldValue("num_exterior", target.value)
                                }
                                onBlurMethod={handleNeBlur}
                                required
                                rightSection
                                disabled={canEdit}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                            <InputNumber
                                label="Número Interior"
                                name="num_interior"
                                type="text"
                                value={values.num_interior}
                                touched={touched.num_interior}
                                error={errors.num_interior}
                                onChangeMethod={({ target }: any) =>
                                    setFieldValue("num_interior", target.value)
                                }
                                onBlurMethod={handleNiBlur}
                                rightSection
                                disabled={canEdit}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                            <InputNumber
                                label="Código Postal"
                                name="cp"
                                type="text"
                                value={values.cp}
                                touched={touched.cp}
                                error={errors.cp}
                                onChangeMethod={({ target }: any) =>
                                    setFieldValue("cp", target.value)
                                }
                                onBlurMethod={handleCPBlur}
                                rightSection
                                required
                                disabled={canEdit}
                            />
                        </Grid.Col>
                    </Grid>
                    <Grid justify="space-around" grow>
                        <Grid.Col span={{ base: 12, md: 6, lg: 7 }}>
                            <InputAreaForm
                                label="Observaciones"
                                name="observaciones"
                                type="text"
                                value={values.observaciones}
                                touched={touched.observaciones}
                                error={errors.observaciones}
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                minRows={2}
                                rightSection
                                disabled={canEdit}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                            <Checkbox
                                mt="lg"
                                color="red"
                                label="Notificación"
                                checked={checked}
                                onChange={() => setChecked((prev) => !prev)}
                                disabled={canEdit}
                            />
                        </Grid.Col>
                        <InputTextMan
                            name="latitud"
                            type="hidden"
                            value={values.latitud}
                            rightSection
                            disabled
                        />

                        <InputTextMan
                            name="longitud"
                            type="hidden"
                            value={values.longitud}
                            rightSection
                            disabled
                        />
                    </Grid>

                    <Grid justify="center">
                        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
                            <Text fw={700} ta="center">
                                Identifique la ubicación en el mapa
                            </Text>
                        </Grid.Col>
                    </Grid>

                    <Grid justify="space-around" grow>
                        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
                            <Group justify="center">
                                <Button
                                    variant="filled"
                                    color="redColor"
                                    className="mt-3"
                                    onClick={handleFormSubmit}
                                >
                                    Buscar ubicación
                                </Button>
                            </Group>
                        </Grid.Col>
                    </Grid>

                    <Grid justify="space-around" grow>
                        <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
                            <MapWithMarker2
                                markerPosition={markerPosition}
                                center={mapCenter}
                                onMarkerClick={handleMarkerClick}
                                setValues={setValues}
                                values={values}
                                setShowMarker={setShowMarker}
                                showMarker={showMarker}
                                canEdit={canEdit}
                            />
                        </Grid.Col>
                    </Grid>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-around mt-2">
                        <Button
                            variant="filled"
                            color="redColor"
                            type="reset"
                            className="mt-3"
                        >
                            Limpiar
                        </Button>
                        {!canEdit ? (
                            <Button
                                variant="filled"
                                color="greenColor"
                                type="submit"
                                className="mt-2 mt-sm-3"
                                //  title="guardar"
                            >
                                Guardar
                            </Button>
                        ) : (
                            <></>
                        )}
                    </div>
                </form>
                <Divider size="md" my="md" />
                <Datatable
                    columns={columns}
                    headers={[
                        "Municipio",
                        "Colonia",
                        "Calle",
                        "No. exterior",
                        "Observaciones",
                        "Acciones",
                    ]}
                    columnDefs={columnDefs}
                    order={{ col: 1, opt: "desc" }}
                    petition={getData}
                    control="back"
                    stateRefresh={[refresh, setRefresh]}
                    className="p-0"
                />
            </section>
        </>
    );
}
