import { useEffect, useState } from "react";
import peticionBack from "../../../libs/peticiones";
import Datatable from "../../../components/datatable/Datatable";
import createURL from "../../../libs/url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@mantine/core";
import { swalAction } from "../../../components/modals/ModalConfirm";
import Form from "./Form";
import { Alergias } from "./dto/alergias.dto";
import CardMan from "../../../components/card/Card";
import { colorRed } from "../../../core/constants";
import { useSelector } from "react-redux";
import { moduleData } from "../../../core/slicers/permisos/permissionsSlicer";
import { checkPermission } from "../../../libs/permisosModulo";

export default function Alergia() {
    const [refresh, setRefresh] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [selected, setSelected] = useState<Alergias | undefined>();
    const modulos = useSelector(moduleData);

    async function getData(
        order: string,
        records: number,
        page: number,
        search: string
    ) {
        const params: any = {
            pathname: `/alergias`,
            searchParams: [
                { name: "order", value: order },
                { name: "records", value: records },
                { name: "page", value: page },
                { name: "search", value: search },
                { name: "rows", value: "name,_id" },
            ],
        };
        const url = createURL(params);

        return await peticionBack({}, url, "GET", false);
    }

    useEffect(() => {
        if (!open) {
            setSelected(undefined);
        }
    }, [open]);

    function openToEdit(row: Alergias) {
        setSelected(row);
        setOpen(true);
    }

    async function deleteRow(id: string) {
        const res = await peticionBack({}, `/alergias/${id}`, "DELETE", true);
        if (res) setRefresh(true);
    }

    const columns = [
        { data: "name" },
        {
            data: null,
            render: (row: Alergias) => (
                <div className="d-flex justify-content-evenly">
                    {checkPermission(modulos, "u") && (
                        <FontAwesomeIcon
                            icon={faPencil}
                            className="text-warning cursor-pointer"
                            onClick={() => openToEdit(row)}
                        />
                    )}
                    {checkPermission(modulos, "d") && (
                        <FontAwesomeIcon
                            icon={faTrashCan}
                            className="text-danger cursor-pointer"
                            onClick={() =>
                                swalAction(
                                    deleteRow,
                                    "Eliminar Alergia",
                                    `¿Desea eliminar la alergia ${row.name}?`,
                                    "question",
                                    row._id,
                                    "Eliminar"
                                )
                            }
                        />
                    )}
                </div>
            ),
        },
    ];

    const headers = ["Nombre", "Acciones"];
    const columnsDef = [
        { orderable: false, targets: [1] },
        { className: "text-center", targets: [0] },
    ];

    return (
        <CardMan title="Alergias" showMenu={false}>
            <div className="d-flex flex-column flex-sm-row mb-2 d-flex justify-content-end">
                {!open && checkPermission(modulos, "c") && (
                    <Button
                        color={colorRed}
                        onClick={() => setOpen(true)}
                        className="ml-2"
                        style={{ marginRight: "13px", marginLeft: "13px" }}
                    >
                        Agregar
                    </Button>
                )}
            </div>
            {open ? (
                <Form setOpen={setOpen} selected={selected} />
            ) : (
                <>
                    {checkPermission(modulos, "c") && (
                        <Datatable
                            columns={columns}
                            columnDefs={columnsDef}
                            headers={headers}
                            control="back"
                            petition={getData}
                            stateRefresh={[refresh, setRefresh]}
                        />
                    )}
                </>
            )}
        </CardMan>
    );
}
