
import Juicios from "../../pages/materias/juicios"
import Alergia from "../../pages/personal/alergias/index"
import Discapacidad from "../../pages/personal/discapacidades"
import Personal from "../../pages/personal/expediente-personal"
import Juzgadores from "../../pages/personal/juzgadores"
import Secretarios from "../../pages/personal/secretarios"

const rutasPersonal = [
    { route: "/alergias", component: Alergia },
    { route: "/discapacidades", component: Discapacidad },
    { route: "/expediente-personal", component: Personal },
    { route: "/secretarios", component: Secretarios },
    { route: "/juzgadores", component: Juzgadores },
    { route: "/juicios", component: Juicios },
]

export default rutasPersonal
