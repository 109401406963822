import { Badge, Card, Tabs, rem } from "@mantine/core";
import RepresentantesLeg from "./representantes-legales/RepresentantesLegales";
import { IconAsterisk } from "@tabler/icons-react";
import DatosGenerales from "./datos-generales/datos-generales";
import Domicilios from "./domicilios/domicilios";
import { useEffect, useState } from "react";
import Telefono from "./telefonos/Telefonos";
import TelefonosRep from "./representantes-legales/TelefonosRep";
import SelectedActorDemandado from "./ActorDemandado";
import SelectedRepresentante from "./RepresentanteSeleccionado";

/**
 * Formulario de actores y demandados
 * @param { boolean | undefined } actor
 * @variation Actor - { true }
 * @variation Demandado - { undefined | false }
 * @returns
 */
export default function ActorDemandado({
    selectedFull,
    tipo,
}: {
    selectedFull: any;
    tipo: string;
}) {
    const [selected, setSelected] = useState<any[]>([]);
    const [representantesGeneral, setRepresentantesGeneral] = useState<any[]>(
        []
    );
    const [selectedActorDemandado, setSelectedActorDemandado] = useState<any>();
    const [selectedRepresentante, setSelectedRepresentante] = useState<any>();
    const [isSmallScreen, setIsSmallScreen] = useState(
        window.innerWidth <= 780
    );

    useEffect(() => {
        if (selectedActorDemandado || selectedActorDemandado === null) {
            setSelectedRepresentante(null);
            setRepresentantesGeneral([]);
        }
    }, [selectedActorDemandado]);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 780);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            <Tabs
                variant="pills"
                defaultValue="General"
                orientation={isSmallScreen ? "horizontal" : "vertical"}
                color="gray"
            >
                <Tabs.List grow={isSmallScreen}>
                    <Tabs.Tab
                        leftSection={
                            <IconAsterisk
                                style={{ width: rem(11), height: rem(11) }}
                                color="var(--mantine-color-error)"
                            />
                        }
                        value="General"
                    >
                        Datos Generales
                    </Tabs.Tab>
                    <Tabs.Tab
                        leftSection={
                            <IconAsterisk
                                style={{ width: rem(11), height: rem(11) }}
                                color="var(--mantine-color-error)"
                            />
                        }
                        value="Domicilios"
                    >
                        Domicilios
                    </Tabs.Tab>{" "}
                    <Tabs.Tab value="Telefonos">Teléfonos</Tabs.Tab>
                    <Tabs.Tab value="RepresentantesLegales">
                        Representantes Legales
                    </Tabs.Tab>
                    <Tabs.Tab value="TelefonosRep">
                        Teléfonos Representantes
                    </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="General">
                    <Card withBorder={false} shadow={"none"}>
                        <DatosGenerales
                            selectedFull={selectedFull}
                            setSelected={setSelected}
                            tipo={tipo}
                        />
                    </Card>
                </Tabs.Panel>
                <Tabs.Panel value="Domicilios">
                    <Card withBorder={false} shadow={"none"}>
                        {selected.length > 0 ? (
                            <>
                                <SelectedActorDemandado
                                    actores={selected}
                                    setSelectedActorDemandado={
                                        setSelectedActorDemandado
                                    }
                                    selectedActorDemandado={
                                        selectedActorDemandado
                                    }
                                    tipo={tipo}
                                />
                                <Domicilios
                                    canEdit={selectedFull.enviado}
                                    tipo={tipo}
                                    actorSeleccionado={selectedActorDemandado}
                                />
                            </>
                        ) : (
                            <Badge fullWidth autoContrast size="lg" color="red">
                                Llene los datos generales
                            </Badge>
                        )}
                    </Card>
                </Tabs.Panel>
                <Tabs.Panel value="Telefonos">
                    <Card withBorder={false} shadow={"none"}>
                        {selected.length > 0 ? (
                            <>
                                <SelectedActorDemandado
                                    actores={selected}
                                    setSelectedActorDemandado={
                                        setSelectedActorDemandado
                                    }
                                    selectedActorDemandado={
                                        selectedActorDemandado
                                    }
                                    tipo={tipo}
                                />
                                <Telefono
                                    canEdit={selectedFull.enviado}
                                    tipo={tipo}
                                    actorSeleccionado={selectedActorDemandado}
                                />
                            </>
                        ) : (
                            <Badge fullWidth autoContrast size="lg" color="red">
                                Llene los datos generales
                            </Badge>
                        )}
                    </Card>
                </Tabs.Panel>
                <Tabs.Panel value="RepresentantesLegales">
                    <Card withBorder={false} shadow={"none"}>
                        {selected.length > 0 ? (
                            <>
                                <SelectedActorDemandado
                                    actores={selected}
                                    setSelectedActorDemandado={
                                        setSelectedActorDemandado
                                    }
                                    selectedActorDemandado={
                                        selectedActorDemandado
                                    }
                                    tipo={tipo}
                                />
                                <RepresentantesLeg
                                    canEdit={selectedFull.enviado}
                                    tipo={tipo}
                                    actorSeleccionado={selectedActorDemandado}
                                    setSelectedRep={setRepresentantesGeneral}
                                />
                            </>
                        ) : (
                            <Badge fullWidth autoContrast size="lg" color="red">
                                Llene los datos generales
                            </Badge>
                        )}
                    </Card>
                </Tabs.Panel>
                <Tabs.Panel value="TelefonosRep">
                    <Card withBorder={false} shadow={"none"}>
                        {selected.length > 0 ? (
                            <>
                                <SelectedActorDemandado
                                    actores={selected}
                                    setSelectedActorDemandado={
                                        setSelectedActorDemandado
                                    }
                                    selectedActorDemandado={
                                        selectedActorDemandado
                                    }
                                    tipo={tipo}
                                />
                                {representantesGeneral.length > 0 ? (
                                    <>
                                        <SelectedRepresentante
                                            representantes={
                                                representantesGeneral
                                            }
                                            setSelectedRepresentante={
                                                setSelectedRepresentante
                                            }
                                            selectedRepresentante={
                                                selectedRepresentante
                                            }
                                        />
                                        <TelefonosRep
                                            canEdit={selectedFull.enviado}
                                            repSeleccionado={
                                                selectedRepresentante
                                            }
                                        />
                                    </>
                                ) : (
                                    <Badge
                                        fullWidth
                                        autoContrast
                                        size="lg"
                                        color="red"
                                    >
                                        Sleccione un representante del {tipo} o
                                        asigne representantes al {tipo}
                                    </Badge>
                                )}
                            </>
                        ) : (
                            <Badge fullWidth autoContrast size="lg" color="red">
                                Llene los datos generales
                            </Badge>
                        )}
                    </Card>
                </Tabs.Panel>
            </Tabs>
        </>
    );
}
