/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { swalAction } from "../../../components/modals/ModalConfirm";
import peticionBack from "../../../libs/peticiones";
import { OficialiasAdscripcion } from "./dto/oficialias-adscripcion.dto";
import { Button, Grid, Switch } from "@mantine/core";
import InputSearch from "../../../components/inputs/InputSelectSearch";
import { useDispatch } from "react-redux";
import { loaded, loading } from "../../../core/slicers/loading/LoadingSlicer";

const defaultForm: OficialiasAdscripcion = {
  oficialia_id: "",
  adscripcion_id: "",
  materia_id: "",
  ordenable: false,
};

export default function Form({
  setOpen,
  selected,
}: {
  setOpen: any;
  selected?: any;
}) {
  const [oficialias, setOficialias] = useState<OficialiasAdscripcion[]>([]);
  const [adscripciones, setAdscripciones] = useState<OficialiasAdscripcion[]>(
    []
  );
  const [materias, setMaterias] = useState<OficialiasAdscripcion[]>([]);
  const dispatch = useDispatch();

  const formSchema = Yup.object().shape({
    oficialia_id: Yup.string().required("Seleccione una Oficialía"),
    adscripcion_id: Yup.string().required("Seleccione una Adscripción"),
    materia_id: Yup.string().required("Seleccione una Materia"),
  });

  const {
    handleSubmit,
    handleReset,
    setFieldValue,
    setValues,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: defaultForm,
    validationSchema: formSchema,
    onSubmit: (values) =>
      swalAction(
        save,
        `${selected ? "Editar" : "Guardar"} Oficialía-Adscripción`,
        `¿Desea ${selected ? "editar" : "guardar"} esta Oficialía-Adscripción?`,
        "question"
      ),
    onReset: () => {
      setOpen(false);
    },
  });

  function onChange({ target }: { target: any }) {
    if (target.name === "ordenable") setFieldValue("ordenable", target.checked);
    else {
      setFieldValue(target.name, target.value);
    }
  }

  useEffect(() => {
    getOficialias();
    getAdscricpiones();
    getMaterias();
    if (selected) {
      setValues(
        {
          oficialia_id: selected.oficialia_id,
          adscripcion_id: selected.adscripcion_id,
          materia_id: selected.materia_id,
          ordenable: selected.ordenable,
        },
        true
      );
    }
  }, []);
  

  async function getOficialias() {
    dispatch(loading());
    const res = await peticionBack({}, "/oficialias", "GET", false);
    dispatch(loaded());
    if (res) setOficialias(res.data);
  }
  async function getAdscricpiones() {
    dispatch(loading());
    const res = await peticionBack({}, "/adscripciones", "GET", false);
    dispatch(loaded());
    if (res) setAdscripciones(res.data);
  }
  async function getMaterias() {
    dispatch(loading());
    const res = await peticionBack({}, "/materias", "GET", false);
    dispatch(loaded());
    if (res) setMaterias(res.data);
  }

  async function save() {
    const method = selected ? "PUT" : "POST";
    const rute = selected ? `/${selected._id}` : "";
    dispatch(loading());
    const res = await peticionBack(
      { ...values },
      "/oficialia-adscripcion" + rute,
      method,
      true
    );
    if (res) {
      handleReset(null);
    }
    dispatch(loaded());
  }

  return (
    <form onSubmit={handleSubmit} onReset={handleReset}>
      <Grid grow justify="space-around">
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <InputSearch
            label="Oficialías"
            name="oficialia_id"
            value={values.oficialia_id}
            error={errors.oficialia_id}
            touched={touched.oficialia_id}
            values={oficialias}
            labelVal="name"
            valueVal="_id"
            onChangeMethod={({ target }: any) =>
              setFieldValue("oficialia_id", target.value)
            }
            required
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <InputSearch
            label="Adscripciones"
            name="adscripcion_id"
            value={values.adscripcion_id}
            error={errors.adscripcion_id}
            touched={touched.adscripcion_id}
            values={adscripciones}
            labelVal="name"
            valueVal="_id"
            onChangeMethod={({ target }: any) =>
              setFieldValue("adscripcion_id", target.value)
            }
            required
          />
        </Grid.Col>
      </Grid>
      <Grid grow justify="space-around" align="center">
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <InputSearch
            label="Materias"
            name="materia_id"
            value={values.materia_id}
            error={errors.materia_id}
            touched={touched.materia_id}
            values={materias}
            labelVal="name"
            valueVal="_id"
            onChangeMethod={({ target }: any) =>
              setFieldValue("materia_id", target.value)
            }
            required
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
          <Switch
            label="Ordenable"
            name="ordenable"
            checked={values.ordenable}
            onChange={onChange}
          />
        </Grid.Col>
      </Grid>

      <div className="d-grid gap-2 d-md-flex justify-content-md-around mt-2">
        <Button variant="filled" color="redColor" type="reset" className="mt-3">
          Cancelar
        </Button>
        <Button
          variant="filled"
          color="greenColor"
          type="submit"
          className="mt-2 mt-sm-3"
        >
          Guardar
        </Button>
      </div>
    </form>
  );
}
