/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Divider, Grid } from "@mantine/core";
import InputSearch from "../../../../../components/inputs/InputSelectSearch";
import { swalAction } from "../../../../../components/modals/ModalConfirm";
import InputTextMan from "../../../../../components/inputs/inputTextMan";
import { datosGenerales } from "./dto/general.dto";
import InputDateMan from "../../../../../components/inputs/inputDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Datatable from "../../../../../components/datatable/Datatable";
import {
    DateToString,
    FechaEstandar,
    convertirFecha,
} from "../../../../../libs/formats";
import peticionBack from "../../../../../libs/peticiones";
import { useDispatch } from "react-redux";
import {
    loaded,
    loading,
} from "../../../../../core/slicers/loading/LoadingSlicer";
import {
    Sexo,
    Tipo_Persona,
    Tipo_identificacion,
} from "../../../../../core/enums/enums";
import createURL from "../../../../../libs/url";
import Alert from "../../../../../components/notification/Alert";
import { validarCurp, validarRFC } from "../../../../../libs/personalData";
import { IconEye } from "@tabler/icons-react";

const defaultForm: datosGenerales = {
    nombre: "",
    paterno: "",
    materno: "",
    genero: "",
    nombre_busqueda: "",
    curp: "",
    rfc: "",
    tipo_persona: "",
    email_notificacion: "",
    fecha_nacimiento: "",
    tipo_identificacion: "",
    num_identificacion: "",
    institucion: "",
    razon_social: "",
    carpetas_judiciales_id: "",
    tipos_parte: "",
};

export default function DatosGenerales({
    selectedFull,
    setSelected,
    tipo,
}: {
    selectedFull: any;
    setSelected?: any;
    tipo: string;
}) {
    const [refresh, setRefresh] = useState<boolean>(false);
    const [selectedLocal, setSelectedLocal] = useState<any | undefined>();
    const dispatch = useDispatch();

    const formSchema = Yup.object().shape({
        nombre: Yup.string().when("tipo_persona", {
            is: Tipo_Persona.FISICA,
            then: () => Yup.string().required("Ingrese un nombre"),
        }),

        paterno: Yup.string().when("tipo_persona", {
            is: Tipo_Persona.FISICA,
            then: () => Yup.string().required("Ingrese un apellido"),
        }),
        materno: Yup.string().when("tipo_persona", {
            is: Tipo_Persona.FISICA,
            then: () => Yup.string().required("Ingrese un apellido"),
        }),
        rfc: Yup.string().when("tipo_persona", {
            is: (tipo_persona: string) =>
                tipo_persona === Tipo_Persona.FISICA && tipo === "ACTOR",
            then: () =>
                Yup.string()
                    .required("Ingrese el RFC")
                    .test({
                        name: "valid_rfc",
                        message: "RFC incorrecto",
                        test: (value) => validarRFC(value),
                    }),
        }),
        curp: Yup.string().when("tipo_persona", {
            is: (tipo_persona: string) =>
                tipo_persona === Tipo_Persona.FISICA && tipo === "ACTOR",
            then: () =>
                Yup.string()
                    .required("Ingrese el CURP")
                    .test({
                        name: "valid_curp",
                        message: "curp incorrecto",
                        test: (value) => validarCurp(value),
                    }),
        }),
        fecha_nacimiento: Yup.string().when("tipo_persona", {
            is: (tipo_persona: string) =>
                tipo_persona === Tipo_Persona.FISICA && tipo === "ACTOR",
            then: () => Yup.string().required("Seleccione fecha de nacimiento"),
        }),
        tipo_identificacion:
            tipo === "ACTOR"
                ? Yup.mixed<Tipo_identificacion>()
                      .oneOf(Object.values(Tipo_identificacion))
                      .required("Seleccione un tipo de identificacion")
                : Yup.string(),
        genero: Yup.string().when("tipo_persona", {
            is: Tipo_Persona.FISICA,
            then: () =>
                Yup.mixed<Sexo>()
                    .oneOf(Object.values(Sexo))
                    .required("Seleccione un genero"),
        }),
        tipo_persona: Yup.mixed<Tipo_Persona>()
            .oneOf(Object.values(Tipo_Persona))
            .required("Seleccione el tipo de persona"),
        email_notificacion: Yup.string()
            .required("Ingrese un correo eléctronico")
            .email("Ingrese un correo valido"),
        num_identificacion:
            tipo === "ACTOR"
                ? Yup.string().required("Ingrese un número de identificación")
                : Yup.string(),
        razon_social: Yup.string().when("tipo_persona", {
            is: Tipo_Persona.MORAL,
            then: () => Yup.string().required("Ingrese una razón social"),
        }),
    });

    const {
        handleSubmit,
        handleReset,
        handleBlur,
        setFieldValue,
        setValues,
        values,
        errors,
        touched,
    } = useFormik({
        initialValues: defaultForm,
        validationSchema: formSchema,
        onSubmit: (values) =>
            swalAction(
                save,
                `${selectedLocal ? "Editar" : "Guardar"} ${tipo}`,
                `¿Desea ${selectedLocal ? "editar" : "guardar"} este ${tipo}?`,
                "question"
            ),
        onReset: () => {
            setSelectedLocal(undefined);
            // setTipoPersona(undefined);
        },
    });

    function onChange({ target }: { target: any }) {
        setFieldValue(target.name, target.value);
    }

    useEffect(() => {
        if (selectedLocal) {
            setValues(
                {
                    nombre: selectedLocal.partes.nombre,
                    paterno: selectedLocal.partes.paterno,
                    materno: selectedLocal.partes.materno,
                    genero: selectedLocal.partes.genero,
                    curp: selectedLocal.partes.curp,
                    rfc: selectedLocal.partes.rfc,
                    tipo_persona: selectedLocal.partes.tipo_persona,
                    email_notificacion: selectedLocal.partes.email_notificacion,
                    fecha_nacimiento: convertirFecha(
                        selectedLocal.partes.fecha_nacimiento as string
                    ),
                    tipo_identificacion:
                        selectedLocal.partes.tipo_identificacion,
                    num_identificacion: selectedLocal.partes.num_identificacion,
                    razon_social: selectedLocal.partes.razon_social, //

                    carpetas_judiciales_id:
                        selectedLocal.carpetas_judiciales_id,
                    tipos_parte: selectedLocal.tipos_parte,
                },
                true
            );
        }
    }, [selectedLocal]);

    useEffect(() => {
        setRefresh(true);
    }, [selectedFull]);

    async function save() {
        const method = selectedLocal ? "PUT" : "POST";
        const rute = selectedLocal ? `/${selectedLocal._id}` : "";
        if (values.nombre_busqueda === "") {
            delete values.nombre_busqueda;
        }
        if (values.institucion === "") {
            delete values.institucion;
        }
        if (values.tipo_persona === Tipo_Persona.FISICA) {
            delete values.razon_social;
        }
        const params: any =
            values.tipo_persona === Tipo_Persona.FISICA
                ? {
                      carpetas_judiciales_id: selectedFull.carpeta_judicial
                          ? selectedFull.carpeta_judicial._id
                          : selectedFull._id,
                      curp: values.curp,
                      email_notificacion: values.email_notificacion,
                      fecha_nacimiento: DateToString(values.fecha_nacimiento),
                      genero: values.genero,
                      materno: values.materno,
                      nombre: values.nombre,
                      num_identificacion: values.num_identificacion,
                      paterno: values.paterno,
                      rfc: values.rfc,
                      tipo_identificacion: values.tipo_identificacion,
                      tipo_persona: values.tipo_persona,
                      tipos_parte: tipo,
                  }
                : {
                      carpetas_judiciales_id: selectedFull.carpeta_judicial
                          ? selectedFull.carpeta_judicial._id
                          : selectedFull._id,
                      email_notificacion: values.email_notificacion,
                      num_identificacion: values.num_identificacion,
                      razon_social: values.razon_social,
                      rfc: values.rfc,
                      tipo_identificacion: values.tipo_identificacion,
                      tipo_persona: values.tipo_persona,
                      tipos_parte: tipo,
                  };

        dispatch(loading("backdrop"));
        const res = await peticionBack(
            params,
            "/preregistro-partes/general" + rute,
            method,
            true
        );
        if (res) {
            handleReset(null);
            setRefresh(true);
        }
        dispatch(loaded());
    }

    /* TABLA */

    async function getGenerales(
        order: string,
        records: number,
        page: number,
        search: string
    ) {
        if (!selectedFull) return null;
        const params: any = {
            pathname: `/preregistro-partes/general`,
            searchParams: [
                { name: "order", value: order },
                { name: "records", value: records },
                { name: "page", value: page },
                { name: "search", value: search },
                {
                    name: "carpetas_judiciales_id",
                    value: selectedFull.carpeta_judicial
                        ? selectedFull.carpeta_judicial._id
                        : selectedFull._id,
                },
                { name: "tipos_parte", value: tipo },
                {
                    name: "rows",
                    value: "_id,carpetas_judiciales_id,partes_id,partes.nombre,partes.nombre_busqueda,partes.paterno,partes.materno,partes.fecha_nacimiento,partes.genero,partes.curp,partes.rfc,tipos_parte",
                },
            ],
        };
        const url = createURL(params);
        const idExiste = selectedFull.carpeta_judicial
            ? selectedFull.carpeta_judicial._id
            : selectedFull._id;
        if (idExiste) {
            const res = await peticionBack({}, url, "GET", false);
            res.data.forEach((a: any) => {
                a.partes.nombre =
                    a.partes.tipo_persona === Tipo_Persona.MORAL
                        ? a.partes.razon_social
                        : a.partes.nombre;
                a.partes.nombre_busqueda =
                    a.partes.tipo_persona === Tipo_Persona.MORAL
                        ? a.partes.nombre
                        : a.partes.nombre_busqueda;
                a.partes.paterno = a.partes.paterno ? a.partes.paterno : "N/A";
                if (a.partes.tipo_persona === Tipo_Persona.FISICA) {
                    a.partes.nombre_busqueda = a.partes.nombre_busqueda
                        ? a.partes.nombre_busqueda
                        : a.partes.nombre_ +
                          " " +
                          a.partes.paterno +
                          " " +
                          a.partes.materno;
                }
                a.partes.materno = a.partes.materno ? a.partes.materno : "N/A";
                a.partes.genero = a.partes.genero ? a.partes.genero : "N/A";
                a.partes.curp = a.partes.curp ? a.partes.curp : "N/A";
                a.partes.fecha_nacimiento = a.partes.fecha_nacimiento
                    ? FechaEstandar(a.partes.fecha_nacimiento)
                    : "N/A";
            });
            setSelected(res.data);
            if (res) return res;
        }
    }
    const columns = [
        { data: "partes.nombre_busqueda" },
        { data: "partes.fecha_nacimiento" },
        { data: "partes.genero" },
        { data: "partes.curp" },
        { data: "partes.rfc" },
        {
            data: null,
            render: (row: any) => (
                <div className="d-flex justify-content-evenly">
                    {selectedFull.enviado ? (
                        <IconEye
                            className="text-info cursor-pointer"
                            onClick={() => openToEdit(row)}
                        />
                    ) : (
                        <>
                            <FontAwesomeIcon
                                icon={faPencil}
                                className="text-warning cursor-pointer"
                                onClick={() => openToEdit(row)}
                            />

                            <FontAwesomeIcon
                                icon={faTrashCan}
                                className="text-danger cursor-pointer"
                                onClick={() =>
                                    swalAction(
                                        deleteRow,
                                        "Eliminar Actor",
                                        `¿Desea eliminar el Actor ${row.partes.nombre}?`,
                                        "question",
                                        row._id,
                                        "Eliminar"
                                    )
                                }
                            />
                        </>
                    )}
                </div>
            ),
        },
    ];

    function openToEdit(row: any) {
        if (selectedLocal) {
            Alert("info", "Guarde sus cambios o limpie el formulario");
            return;
        }
        if (JSON.stringify(values) !== JSON.stringify(defaultForm)) {
            Alert("info", "Guarde sus cambios o limpie el formulario");
            return;
        }

        if (row.partes.tipo_persona === Tipo_Persona.MORAL) {
            row.partes.fecha_nacimiento = "01/01/2020";
        }
        if (row.partes.tipo_persona === Tipo_Persona.FISICA) {
            delete row.partes.razon_social;
        }
        setSelectedLocal(row);
    }

    async function deleteRow(id: number) {
        dispatch(loading("backdrop"));
        const res = await peticionBack(
            {},
            `/preregistro-partes/general/${id}`,
            "DELETE",
            true
        );
        if (res) dispatch(loaded());
        setRefresh(true);
        if (selectedLocal) handleReset(null);
    }

    const columnsDef = [
        { orderable: false, targets: [8] },
        { className: "text-center", targets: [8] },
    ];
    const headers = [
        "Nombre",
        "Fecha de Nacimiento",
        "Sexo",
        "Curp",
        "Rfc",
        "Acciones",
    ];
    return (
        <>
            <form onSubmit={handleSubmit} onReset={handleReset}>
                <Grid justify="space-around" grow>
                    <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                        <InputSearch
                            label="Tipo Persona"
                            name="tipo_persona"
                            value={values.tipo_persona}
                            touched={touched.tipo_persona}
                            error={errors.tipo_persona}
                            values={Object.entries(Tipo_Persona).map(
                                (value) => {
                                    return { value: value[0], key: value[1] };
                                }
                            )}
                            labelVal="value"
                            valueVal="key"
                            onChangeMethod={({ target }: any) => {
                                setFieldValue("tipo_persona", target.value);
                            }}
                            onBlurMethod={handleBlur}
                            required
                            disabled={selectedFull?.enviado}
                        />
                    </Grid.Col>
                </Grid>
                {values.tipo_persona !== Tipo_Persona.MORAL && (
                    <Grid justify="space-around" grow>
                        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                            <InputTextMan
                                label="Apellido Paterno"
                                name="paterno"
                                type="text"
                                value={values.paterno}
                                touched={touched.paterno}
                                error={errors.paterno}
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                required
                                rightSection
                                disabled={selectedFull?.enviado}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                            <InputTextMan
                                label="Apellido Materno"
                                name="materno"
                                type="text"
                                value={values.materno}
                                touched={touched.materno}
                                error={errors.materno}
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                required
                                rightSection
                                disabled={selectedFull?.enviado}
                            />
                        </Grid.Col>

                        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                            <InputTextMan
                                label="Nombre"
                                name="nombre"
                                type="text"
                                value={values.nombre}
                                touched={touched.nombre}
                                error={errors.nombre}
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                required
                                rightSection
                                disabled={selectedFull?.enviado}
                            />
                        </Grid.Col>
                    </Grid>
                )}
                {values.tipo_persona !== Tipo_Persona.MORAL && (
                    <Grid justify="space-around" grow>
                        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                            <InputSearch
                                label="Genero"
                                name="genero"
                                value={values.genero}
                                touched={touched.genero}
                                error={errors.genero}
                                values={Object.entries(Sexo).map((value) => {
                                    return { value: value[0], key: value[1] };
                                })}
                                labelVal="value"
                                valueVal="key"
                                onChangeMethod={({ target }: any) =>
                                    setFieldValue("genero", target.value)
                                }
                                onBlurMethod={handleBlur}
                                required
                                disabled={selectedFull?.enviado}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                            <InputTextMan
                                label="CURP"
                                name="curp"
                                type="text"
                                value={values.curp}
                                touched={touched.curp}
                                error={errors.curp}
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                required={Tipo_Persona.FISICA && tipo==="ACTOR"}
                                rightSection
                                disabled={selectedFull?.enviado}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                            <InputDateMan
                                label="Fecha de nacimiento"
                                name="fecha_nacimiento"
                                value={values.fecha_nacimiento}
                                touched={touched.fecha_nacimiento as any}
                                error={errors.fecha_nacimiento as any}
                                onChangeMethod={({ target }: any) =>
                                    setFieldValue(
                                        "fecha_nacimiento",
                                        target.value
                                    )
                                }
                                onBlurMethod={handleBlur}
                                required={Tipo_Persona.FISICA && tipo==="ACTOR"}
                                disabled={selectedFull?.enviado}
                                clearable={!selectedFull?.enviado}
                            />
                        </Grid.Col>
                    </Grid>
                )}
                <Grid justify="space-around" grow>
                    <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                        <InputTextMan
                            label="RFC"
                            name="rfc"
                            type="text"
                            value={values.rfc}
                            touched={touched.rfc}
                            error={errors.rfc}
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            required={Tipo_Persona.FISICA && tipo==="ACTOR"}
                            rightSection
                            disabled={selectedFull?.enviado}
                        />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                        <InputTextMan
                            label="E-mail notificación"
                            name="email_notificacion"
                            type="text"
                            value={values.email_notificacion}
                            touched={touched.email_notificacion}
                            error={errors.email_notificacion}
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            rightSection
                            required
                            disabled={selectedFull?.enviado}
                        />
                    </Grid.Col>
                </Grid>
                <Grid justify="space-around" grow>
                    <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                        <InputSearch
                            label="Tipo Identificación"
                            name="tipo_identificacion"
                            value={values.tipo_identificacion}
                            touched={touched.tipo_identificacion}
                            error={errors.tipo_identificacion}
                            values={Object.entries(Tipo_identificacion).map(
                                (value) => {
                                    return { value: value[0], key: value[1] };
                                }
                            )}
                            labelVal="value"
                            valueVal="key"
                            onChangeMethod={({ target }: any) =>
                                setFieldValue(
                                    "tipo_identificacion",
                                    target.value
                                )
                            }
                            onBlurMethod={handleBlur}
                            required={Tipo_Persona.FISICA && tipo==="ACTOR"}
                            disabled={selectedFull?.enviado}
                        />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                        <InputTextMan
                            label="No. Identificación"
                            name="num_identificacion"
                            type="text"
                            value={values.num_identificacion}
                            touched={touched.num_identificacion}
                            error={errors.num_identificacion}
                            onChangeMethod={onChange}
                            onBlurMethod={handleBlur}
                            required={Tipo_Persona.FISICA && tipo==="ACTOR"}
                            rightSection
                            disabled={selectedFull?.enviado}
                        />
                    </Grid.Col>
                    {values.tipo_persona === Tipo_Persona.MORAL && (
                        <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                            <InputTextMan
                                label="Razón social"
                                name="razon_social"
                                type="text"
                                value={values.razon_social as string}
                                touched={touched.razon_social}
                                error={errors.razon_social}
                                onChangeMethod={onChange}
                                onBlurMethod={handleBlur}
                                rightSection
                                required
                                disabled={selectedFull?.enviado}
                            />
                        </Grid.Col>
                    )}
                </Grid>
                <div className="d-grid gap-2 d-md-flex justify-content-md-around mt-2">
                    <Button
                        variant="filled"
                        color="redColor"
                        type="reset"
                        className="mt-3"
                        title="limpiar"
                    >
                        Limpiar
                    </Button>
                    {!selectedFull?.enviado ? (
                        <Button
                            variant="filled"
                            color="greenColor"
                            type="submit"
                            className="mt-2 mt-sm-3"
                            //  title="guardar"
                        >
                            Guardar
                        </Button>
                    ) : (
                        <></>
                    )}
                </div>
            </form>
            <Divider size="md" my="md" />
            <section>
                <Datatable
                    columns={columns}
                    columnDefs={columnsDef}
                    headers={headers}
                    control="back"
                    petition={getGenerales}
                    stateRefresh={[refresh, setRefresh]}
                />
            </section>
        </>
    );
}
