/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Grid, Input, Select, Text, TextInput } from "@mantine/core";
import FormButtons from "../../../../components/Forms/FormButtons";
import * as Yup from "yup";
import { useFormik } from "formik";
import Datatable from "../../../../components/datatable/Datatable";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { generosArray, tiposPersonaArray } from "../../../../libs/dataArrays";
import { useDispatch } from "react-redux";
import {
    loaded,
    loading,
} from "../../../../core/slicers/loading/LoadingSlicer";
import peticionBack from "../../../../libs/peticiones";
import { swalAction } from "../../../../components/modals/ModalConfirm";
import createURL from "../../../../libs/url";
import { faEye, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { PromoventeDto } from "./dto/promovente-dto";
import Alert from "../../../../components/notification/Alert";
import { Tipo_Persona } from "../../../../core/enums/enums";
import { validarCurp } from "../../../../libs/personalData";

export default function FPromoventes({ actuacion, canEdit }: { actuacion: string, canEdit: boolean }) {
    const [refresh, setRefresh] = useState<boolean>(false);
    const dispatch = useDispatch();

    /* FORM */
    const {
        handleSubmit,
        handleReset,
        handleBlur,
        setFieldValue,
        setFieldTouched,
        setValues,
        values,
        errors,
        touched,
    } = useFormik({
        initialValues: { ...defaultForm, actuaciones_id: actuacion },
        validationSchema: formSchema,
        onSubmit: (values) => {
            swalAction(
                save,
                `${values._id ? "Editar" : "Guardar"} promovente`,
                `¿Desea ${values._id ? "editar" : "guardar"} este promovente?`,
                "question"
            );
        },
    });

    function onChange({ target }: { target: any }) {
        if(!canEdit) return;
        if (target.name === "tipo_persona") {
            handleReset(null);
        }
        setFieldValue(target.name, target.value);
    }

    async function save() {
        dispatch(loading("backdrop"));
        let params: any = {};
        if(values.tipo_persona === Tipo_Persona.FISICA) params = { ...values }
        else params = { razon_social: values.razon_social, tipo_persona: values.tipo_persona }
        params.actuaciones_id = actuacion;
        let route = "/promoventes";
        let method = "POST";
        if (values._id) {
            method = "PUT";
            route += `/${values._id}`;
        }
        if (values.tipo_persona === Tipo_Persona.MORAL) delete params.genero;
        const res = await peticionBack(params, route, method, true);
        if (res) {
            setRefresh(true);
            handleReset(null);
        }
        dispatch(loaded());
    }
    /* END FORM */

    /* TABLE */
    const columns = [
        { data: "ix" },
        { data: "tipo_persona" },
        { data: "nombre_busqueda" },
        { data: "genero", format: (d: string) => (d === "null" ? "" : d) },
        {
            data: null,
            render: (row: PromoventeDto) => (
                <Flex gap="lg" justify="center">
                    {canEdit && <FontAwesomeIcon
                        icon={faPencilAlt}
                        className="text-warning cursor-pointer"
                        onClick={() => editRow(row)}
                    />}
                    {canEdit && <FontAwesomeIcon
                        icon={faTrashAlt}
                        className="text-danger cursor-pointer"
                        onClick={() =>
                            swalAction(
                                deleteRow,
                                "Eliminar Promovente",
                                `¿Desea eliminar el promovente ${row.paterno} ${row.materno} ${row.nombre}`,
                                "question",
                                row,
                                "Eliminar"
                            )
                        }
                    />}
                    {!canEdit && <FontAwesomeIcon
                        icon={faEye}
                        className="text-info cursor-pointer"
                        onClick={() => editRow(row)}
                    />}
                </Flex>
            ),
        },
    ];

    async function getData(
        order: string,
        records: number,
        page: number,
        search: string
    ) {
        if (!actuacion) return null;
        const params: any = {
            pathname: `/promoventes`,
            searchParams: [
                { name: "order", value: order },
                { name: "records", value: records },
                { name: "page", value: page },
                { name: "search", value: search },
                { name: "actuaciones_id", value: actuacion },
            ],
        };
        const url = createURL(params);
        const res = await peticionBack({}, url, "GET", false);
        if (res.data && res.data.length > 0) {
            res.data.forEach((d: PromoventeDto) => {
                if (!d.nombre_busqueda){
                    if(d.tipo_persona === Tipo_Persona.FISICA) d.nombre_busqueda = `${d.nombre} ${d.paterno} ${d.materno}`;
                    else d.nombre_busqueda = d.razon_social;
                }
            });
        }
        return res;
    }

    async function editRow(row: PromoventeDto) {
        if (Object.values(values).some((v) => !!v) && canEdit) {
            Alert(
                "warning",
                "Guarde la información del formulario antes de continuar"
            );
            return;
        }
        setValues({ ...row });
    }

    async function deleteRow(row: PromoventeDto) {
        dispatch(loading("backdrop"));
        const res = await peticionBack({}, `/promoventes/${row._id}`, "DELETE", true);
        if (res) {
            if (values._id === row._id) handleReset(null);
            setRefresh(true);
        }
        dispatch(loaded());
    }

    useEffect(() => {
        setRefresh(true);
        handleReset(null);
    }, [actuacion]);
    /* END TABLE */

    return (
        <section className="border rounded p-3 bg-light-subtle mt-3 position-relative">
            <Text fw={500}>Promoventes: </Text>
            <div className="mt-2">
                <Grid gutter={{ base: "md", md: "xl" }}>
                    <Grid.Col span={{ base: 12, md: 2 }}>
                        <Input.Label required>Tipo de persona:</Input.Label>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 10 }}>
                        <Grid gutter={{ base: "md", md: "xl" }}>
                            <Grid.Col span={{ base: 12, md: 6 }}>
                                <LocalSelect
                                    name="tipo_persona"
                                    data={tiposPersonaArray}
                                    onChange={onChange}
                                    handleBlur={setFieldTouched}
                                    value={values.tipo_persona}
                                    error={errors.tipo_persona}
                                    touched={touched.tipo_persona}
                                    placeholder="Tipo"
                                    disabled={!canEdit}
                                />
                            </Grid.Col>
                            {values.tipo_persona !== Tipo_Persona.MORAL && (
                                <Grid.Col span={{ base: 12, md: 6 }}>
                                    <LocalSelect
                                        name="genero"
                                        data={generosArray}
                                        onChange={onChange}
                                        handleBlur={setFieldTouched}
                                        value={values.genero}
                                        error={errors.genero}
                                        touched={touched.genero}
                                        placeholder="Género"
                                        disabled={
                                            !canEdit ||
                                            values.tipo_persona !==
                                            Tipo_Persona.FISICA
                                        }
                                    />
                                </Grid.Col>
                            )}
                        </Grid>
                    </Grid.Col>
                </Grid>
                <Grid gutter={{ base: "md", md: "xl" }}>
                    <Grid.Col span={{ base: 12, md: 2 }}>
                        <Input.Label required>Promovente:</Input.Label>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 10 }}>
                        <Grid gutter={{ base: "md", md: "xl" }}>
                            {values.tipo_persona !== Tipo_Persona.MORAL && (
                                <>
                                    <Grid.Col span={{ base: 12, md: 4 }}>
                                        <TextInput
                                            name="paterno"
                                            onChange={onChange}
                                            value={values.paterno}
                                            onBlur={handleBlur}
                                            error={
                                                touched.paterno
                                                    ? errors.paterno
                                                    : ""
                                            }
                                            placeholder="Primer apellido"
                                            disabled={
                                                !canEdit ||
                                                values.tipo_persona !==
                                                Tipo_Persona.FISICA
                                            }
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={{ base: 12, md: 4 }}>
                                        <TextInput
                                            name="materno"
                                            onChange={onChange}
                                            value={values.materno}
                                            onBlur={handleBlur}
                                            error={
                                                touched.materno
                                                    ? errors.materno
                                                    : ""
                                            }
                                            placeholder="Segundo apellido"
                                            disabled={
                                                !canEdit ||
                                                values.tipo_persona !==
                                                Tipo_Persona.FISICA
                                            }
                                        />
                                    </Grid.Col>
                                </>
                            )}
                            {
                                values.tipo_persona !== Tipo_Persona.MORAL && 
                                <Grid.Col
                                    span={{ base: 12, md: 4 }}
                                >
                                    <TextInput
                                        name="nombre"
                                        onChange={onChange}
                                        value={values.nombre}
                                        onBlur={handleBlur}
                                        error={touched.nombre ? errors.nombre : ""}
                                        placeholder="Nombres(s)"
                                        disabled={!canEdit}
                                    />
                                </Grid.Col>
                            }
                            {values.tipo_persona !== Tipo_Persona.MORAL && (
                                <Grid.Col span={{ base: 12, md: 6 }}>
                                    <TextInput
                                        name="curp"
                                        onChange={onChange}
                                        value={values.curp}
                                        onBlur={handleBlur}
                                        error={touched.curp ? errors.curp : ""}
                                        placeholder="CURP"
                                        maxLength={18}
                                        disabled={
                                            !canEdit ||
                                            values.tipo_persona !==
                                            Tipo_Persona.FISICA
                                        }
                                    />
                                </Grid.Col>
                            )}
                            {values.tipo_persona !== Tipo_Persona.FISICA && (
                                <Grid.Col span={{ base: 12, md: 8 }}>
                                    <TextInput
                                        name="razon_social"
                                        onChange={onChange}
                                        value={values.razon_social}
                                        onBlur={handleBlur}
                                        error={
                                            touched.razon_social
                                                ? errors.razon_social
                                                : ""
                                        }
                                        placeholder="razón social"
                                        disabled={!canEdit}
                                    />
                                </Grid.Col>
                            )}
                        </Grid>
                    </Grid.Col>
                </Grid>
            </div>
            <FormButtons
                cancel={{
                    msg: "Limpiar",
                    type: "button",
                    color: "red",
                    variant: "light",
                    click: handleReset,
                }}
                save={{
                    msg: "Guardar",
                    type: "button",
                    color: "teal",
                    variant: "light",
                    click: handleSubmit,
                    hidden: !canEdit
                }}
            />
            <Datatable
                columns={columns}
                headers={["#", "Tipo", "Promovente", "Género", "Acción"]}
                columnDefs={columnDefs}
                petition={getData}
                control="back"
                stateRefresh={[refresh, setRefresh]}
                order={{ col: 1, opt: "desc" }}
            />
        </section>
    );
}

const defaultForm: PromoventeDto = {
    actuaciones_id: "",
    genero: "",
    tipo_persona: "",
    nombre: "",
    paterno: "",
    materno: "",
    razon_social: "",
    curp: "",
};

const formSchema = Yup.object().shape({
    genero: Yup.string().when("tipo_persona", {
        is: Tipo_Persona.FISICA,
        then: () => Yup.string().required("Seleccione un género"),
    }),
    tipo_persona: Yup.string().required("Seleccione un tipo de persona"),
    nombre: Yup.string().when("tipo_persona", {
        is: Tipo_Persona.FISICA,
        then: () =>  Yup.string().required("Ingrese el nombre(s)")
    }),
    paterno: Yup.string().when("tipo_persona", {
        is: Tipo_Persona.FISICA,
        then: () => Yup.string().required("Ingrese el apellido"),
    }),
    materno: Yup.string().when("tipo_persona", {
        is: Tipo_Persona.FISICA,
        then: () => Yup.string().required("Ingrese el apellido"),
    }),
    razon_social: Yup.string().when("tipo_persona", {
        is: Tipo_Persona.MORAL,
        then: () => Yup.string().required("Ingrese una razón social"),
    }).nullable(),
    curp: Yup.string().when("tipo_persona", {
        is: Tipo_Persona.FISICA,
        then: () =>
            Yup.string()
                .required("Ingrese el CURP")
                .test({
                    name: "valid_curp",
                    message: "CURP incorrecto",
                    test: (value) => validarCurp(value),
                }),
    }),
});

function LocalSelect({
    label,
    name,
    data,
    value,
    onChange,
    handleBlur,
    error,
    touched,
    placeholder,
    disabled,
}: {
    label?: string;
    name: string;
    data: any[];
    value?: string;
    onChange: any;
    handleBlur: any;
    error?: string;
    touched?: boolean;
    placeholder?: string;
    disabled?: boolean;
}) {
    return (
        <div>
            <Select
                label={label}
                name={name}
                data={data}
                onChange={(value) => onChange({ target: { value, name } })}
                onOptionSubmit={() => handleBlur(name, true)}
                value={value ? value : null}
                error={!!error && touched}
                clearable
                allowDeselect={false}
                placeholder={placeholder}
                disabled={disabled}
            />
            {error && touched && <Input.Error>{error}</Input.Error>}
        </div>
    );
}

const columnDefs = [
    { orderable: false, targets: [0, 4] },
    { className: "text-center", targets: [0, 2, 3, 4] },
];
