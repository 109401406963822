/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import peticionBack from "../../../libs/peticiones";
import Datatable from "../../../components/datatable/Datatable";
import createURL from "../../../libs/url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import CardMan from "../../../components/card/Card";
import { Adscripcion } from "./dto/adscripciones.dto";
import Form from "./Form";
import { swalAction } from "../../../components/modals/ModalConfirm";
import { Button } from "@mantine/core";
import { colorRed } from "../../../core/constants";
import { useDispatch } from "react-redux";
import { loaded, loading } from "../../../core/slicers/loading/LoadingSlicer";

export default function Adscripciones() {
  const [refresh, setRefresh] = useState<boolean>(false);
  const [selected, setSelected] = useState<Adscripcion | undefined>();
  const [open, setOpen] = useState<boolean>(false);

  const [tipos, setTipos] = useState<any[]>([]);
  const [edificios, setEdificios] = useState<any[]>([]);
  const [materias, setMaterias] = useState<any[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    getTipo();
    getMaterias();
    getEdificios();
  }, []);

  useEffect(() => {
    if (edificios.length > 0 && tipos.length > 0) {
      setRefresh(true);
    }
  }, [edificios, tipos]);

  async function getData(
    order: string,
    records: number,
    page: number,
    search: string
  ) {
    if (edificios.length < 1 && tipos.length < 1) return null;
    const params: any = {
      pathname: `/adscripciones`,
      searchParams: [
        { name: "order", value: order },
        { name: "records", value: records },
        { name: "page", value: page },
        { name: "search", value: search },
        {
          name: "rows",
          value: "name,_id,prefix,shortname,tipo_id,edificio_id,telefono,correo,instancia",
        },
      ],
    };
    const url = createURL(params);
    const res = await peticionBack({}, url, "GET", false);
    if (res)
      res.data.forEach((a: Adscripcion) => {
        a.edificio = edificios.find((e) => e._id === a.edificio_id) || {
          name: "Sin asignar",
        };
        a.tipo = tipos.find((e) => e._id === a.tipo_id) || {
          name: "Sin asignar",
        };
      });
    return res;
  }

  async function getTipo() {
    dispatch(loading());
    const res = await peticionBack({}, "/tipos-adscripciones", "GET", false);
    dispatch(loaded());
    if (res) setTipos(res.data);
  }

  async function getMaterias() {
    dispatch(loading());
    const params: any = {
      pathname: `/materias`,
      searchParams: [
        { name: "rows", value: "name,_id" },
        { name: "populate", value: "materia_id" },
      ],
    };
    const url = createURL(params);
    const res = await peticionBack({}, url, "GET", false);
    dispatch(loaded());
    if (res) setMaterias(res.data);
  }

  async function getEdificios() {
    dispatch(loading());
    const res = await peticionBack({}, "/edificios", "GET", false);
    dispatch(loaded());
    if (res) setEdificios(res.data);
  }

  useEffect(() => {
    if (!open) {
      setSelected(undefined);
    }
  }, [open]);

  function openToEdit(row: Adscripcion) {
    if (row.telefono.startsWith("+52")) {
      row.telefono = row.telefono.substring(3);
    }
    setSelected(row);
    setOpen(true);
  }

  async function deleteRow(id: string) {
    const res = await peticionBack(
      {},
      `/adscripciones/${encodeURIComponent(id)}`,
      "DELETE",
      true
    );
    if (res) setRefresh(true);
  }

  /* TABLA */
  const columns = [
    { data: "name" },
    { data: "prefix" },
    { data: "shortname" },
    { data: "tipo.name" },
    { data: "edificio.name" },
    { data: "telefono" },
    { data: "correo" },
    { data: "instancia" },
    {
      data: null,
      render: (row: any) => (
        <div className="d-flex justify-content-evenly">
          <FontAwesomeIcon
            icon={faPencil}
            className="text-warning cursor-pointer"
            onClick={() => openToEdit(row)}
          />
          <FontAwesomeIcon
            icon={faTrashCan}
            className="text-danger cursor-pointer"
            onClick={() =>
              swalAction(
                deleteRow,
                "Eliminar Adscripción",
                `¿Desea eliminar la Adscripción ${row.name}?`,
                "question",
                row._id,
                "Eliminar"
              )
            }
          />
        </div>
      ),
    },
  ];

  const headers = [
    "Nombre",
    "Prefijo",
    "Nombre corto",
    "Tipo",
    "Edificio",
    "Teléfono",
    "Correo",
    "Instancia",
    "Acciones",
  ];
  const columnsDef = [
    { orderable: false, targets: [3, 4, 7] },
    { className: "text-center", targets: [0, 1, 2, 3, 4, 5, 6, 7] },
  ];

  return (
    <CardMan title="Adscripciones" showMenu={false}>
      <div className="d-flex flex-column flex-sm-row mb-2 d-flex justify-content-end">
        {!open && (
          <Button
            color={colorRed}
            onClick={() => setOpen(true)}
            className="ml-2"
            style={{ marginRight: "13px", marginLeft: "13px" }}
          >
            Agregar
          </Button>
        )}
      </div>
      {open ? (
        <Form
          setOpen={setOpen}
          selected={selected}
          edificios={edificios}
          tipos={tipos}
          materias={materias}
        />
      ) : (
        <Datatable
          columns={columns}
          columnDefs={columnsDef}
          headers={headers}
          control="back"
          petition={getData}
          stateRefresh={[refresh, setRefresh]}
        />
      )}
    </CardMan>
  );
}
