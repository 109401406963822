import { View, Text } from "@react-pdf/renderer";
import { stylePDF as sg } from "./styles";

export default function HeaderPDF({ title, fixed }: { title?: string, fixed?: boolean }) {
    return (
        <View>
            <View style={[sg.bTitle]} fixed={fixed !== false}>
                <View style={[sg.tCenter]}>
                    <Text style={[sg.title]}>
                        PAJE
                    </Text>

                </View>
            </View>
            {
                title &&
                <View style={[sg.tBold, sg.tCenter, sg.fs12, {marginBottom: "4px"}]}>
                    <Text>
                        {title}
                    </Text>
                </View>
            }
        </View>
    )
}